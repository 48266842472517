@use "scss/meta" as *;
@import "scss/global";

.login {
  display: flex;
  flex-direction: row-reverse;
  &.loginHideContentMobile {
    flex-direction: row;
    @include breakpoint(bp--md--major) {
      flex-direction: row-reverse;
    }
  }
  min-height: 100%;
  //height: 100%;

  background: $background;
  @include breakpointMax(bp--md--major) {
    .loginHideContentMobile & {
      min-height: auto;
    }
    flex-direction: column-reverse;
  }
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include breakpointMax(bp--md--major) {
    width: 100%;
    padding: 1em;
    padding-top: 5vw;
    .backLink {
      //display: none;
    }
    .loginHideContentMobile & {
      padding: 7vw;
      padding-top: calc(1vw + $safe-area-inset-top);
    }
    padding-bottom: 9vw; //$safe-area-inset-top;
  }

  .backLink {
    margin-bottom: 0.8em;
  }

  @include breakpoint(bp--md--major) {
    width: 45vw;
    padding: 5vw 7vw;
    .backLink {
      margin-bottom: 2em;
    }

    form {
      width: 100%;
    }
  }
  @include breakpoint(bp--lg--major) {
    width: 38vw;
  }

  [class="wfp--form-item"] {
    margin-bottom: $spacing-lg;
  }
}

.loginFormContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  //@include breakpoint-down(md) {
  width: 100%;
  //}

  @include breakpoint-up(md) {
    justify-content: center;
    padding-bottom: 6rem;
  }
  margin-top: 0.3rem;
}

.submitWrapper {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.loginLogo {
  margin-bottom: 2rem;
  @include breakpointMax(bp--md--major) {
    display: none;
  }
  svg {
    width: 8vmin;
    height: auto;
  }
}

.loginContent {
  color: var(--ui-01);
  background: $interactive;
  background-size: cover;

  @include breakpointMax(bp--md--major) {
    //display: none;
    flex-grow: 1;
    min-height: 12em;
    .backLink {
      position: absolute;
      top: $safe-area-inset-top;
      margin-top: 0.3em;
      left: 0.3em;
      z-index: 100;
    }
  }

  @include breakpoint(bp--md--major) {
    position: fixed;
    left: 0;
    width: 55vw;
    .backLink {
      display: none;
    }
  }

  @include breakpoint(bp--lg--major) {
    width: 62vw;
    height: 100%;
  }
  position: relative;
  overflow: hidden;
  //display: flex;
  display: grid;
  //justify-content: center;
  //align-items: center;
  //flex-direction: column;
  overflow: hidden;
  @include breakpointMax(bp--md--major) {
    clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%);
    .loginHideContentMobile & {
      display: none;
    }
  }
  > img {
    /* height: 100%;
    width: 100%;
    object-fit: cover;*/
  }
  h2 {
    width: 60%;
    font-size: 3rem;
    font-weight: 800;
    text-shadow: 0px 3px 12px rgba(#000, 0.3);
    display: none;
  }
  p {
    font-size: 1.2rem;
    margin-top: 1.4rem;
    padding-right: 5rem;
    line-height: 2rem;
    width: 60%;
  }
}

.loginWrapperTitle {
  font-weight: 700;
  font-size: 7vw;
  hyphens: auto;
  &.loginWrapperTitleSmall {
    @include breakpointMax(bp--md--major) {
      font-size: 7vw;
      margin-right: -0.5em;
    }
  }
  @include breakpoint(bp--md--major) {
    font-size: 2.5vw;
  }
  margin-bottom: 0.5em;
  line-height: 1.1;
  span {
    display: inline-block;
  }
}
