@import "scss/global";

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing-md;
}

.headerRight {
  display: flex;

  @media print {
    display: none;
  }
  > * {
    margin-left: $spacing-md;
  }
}
.saveIcon {
  color: var(--interactive-01);
}

.infoBlockquote {
  margin-top: 1.5em;
  margin-bottom: 2em;
}

.deleteTitle {
  @include breakpointMax(bp--md--major) {
    display: none;
  }
}
