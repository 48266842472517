@use "scss/meta" as *;

.image {
  align-self: flex-end;
  justify-self: flex-start;
  width: 80%;
  margin-top: -20%;
  margin-left: -10%;
  margin-bottom: -10%;
  transform: rotate(5deg);

  @include breakpoint-up("md") {
    margin-left: -5%;
    width: 50%;
    margin-bottom: -3%;
  }
}
