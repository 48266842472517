@use "scss/meta" as *;

.loginContent {
  position: absolute;
  img {
    align-self: flex-end;
    justify-self: flex-start;
    width: 150%;
    margin-top: -20%;
    margin-left: -20%;
    /*display: block;
    height: auto !important;
    width: 240% !important;
    margin-top: 48% !important;
    margin-left: -45%;
    @include breakpoint("md") {
      margin-top: 15% !important;
      width: 155% !important;
      margin-left: -18%;
    }*/

    transform: rotate(10deg);
    object-fit: cover;
  }
}

.icon {
  width: 1.5em !important;
  height: auto;
  color: var(--interactive-01);
}
