@use "scss/meta" as *;
@import "scss/global";

.infoWrapper {
  background: $layer;
  @include breakpointMax(bp--md--major) {
    margin: -1em;
    margin-bottom: 2em;
  }
  overflow: hidden;
  margin-bottom: 1em;
  min-height: 30vh;

  flex: 1;
  position: relative;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  /*&:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }*/

  // display: flex;

  p {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 1.3em 1em;
    font-size: 1.2em;
    line-height: 1.5;
    text-align: center;
    font-weight: 600;
    //white-space: nowrap;
  }
  small {
    display: block;
    color: $text-secondary;
    font-weight: 400;
    font-size: 0.9em;
  }
}

.infoWrapperImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centering y-axis */
  align-items: center;
  video {
    width: 100%;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
  img {
    width: 100%;
    /* @include breakpoint(bp--md--major) {
      width: 70%;
      margin-left: 30%;
      margin-top: -5%;
      margin-bottom: 5%;
    }*/
  }
}

.infoWrapperImageContainer {
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  min-width: 80%;
  @include breakpoint(bp--md--major) {
    max-width: 20em;
  }
  margin: auto;
  > * {
    flex-grow: 1;
    // height: 4em;
    margin-bottom: 0.7em;
    text-align: center;
  }
}
.cancelButton {
  text-align: center;
  > * {
    height: 4em;
  }
}
.manuallyWrapper {
  display: flex;
  > :first-child {
    margin-right: 0.5em;
  }
}

.analogButton {
  margin-top: 1.5em;
}

.deviceImage {
  display: block;
  width: 9em;
  height: 4em;
  object-fit: contain;
  margin: 0.3em auto;
}

.deviceDescription {
  display: block;
  margin-top: 0.7em;
}

.deviceIconDetails {
  //margin-left: -0.7em;
  //margin: 1em;
  img {
    margin: -0.3em 0;
    width: 6em;
    height: auto;
  }
}

.alarmWrapper {
  :global("wfp--input-group-inside") {
    flex-direction: column;
  }
}

.error {
  background: $support-error-light;
}

.success {
  background: $support-success-light;
}

.video {
  &::-webkit-media-controls {
    display: none;
  }
}
