@use "scss/meta" as *;

.notification {
  position: absolute;
  top: -0.3em;
  left: 3.4em;
  z-index: 1;
  font-size: 0.5em;
  font-weight: bold;
  padding: 0.3em 0.5em 0.3em 0.4em;
  border-radius: 100%;
  background: $support-error;
}
