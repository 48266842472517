@use "scss/meta" as *;
@import "../../scss/global";

.preview {
  width: 400px;
  height: auto;
}

.pictureButton {
  margin-bottom: $spacing-md;
}

.patientName {
  font-weight: 600;
}

.subTitle {
  margin-top: $spacing-xs;
  color: var(--text-02);
  @include typescale("caption");
}

.imeiWrapper {
  display: flex;
  flex-direction: row-reverse;
}

.buttonClassName {
  margin-left: $spacing-xs;
}

.battery {
  width: 2rem;
}

.content {
  flex: 1;
}

.thisYou {
  color: $support-success;
  background: $support-success-light;
}
.currentImage {
  :global(.wfp--user__icon) {
    width: 4rem !important;
    height: 4rem !important;
    margin-bottom: 1em;
  }
}

.inviteButtonWrapper {
  margin-top: $spacing-md;
}

.deviceIcon {
  > img {
    margin: -0.3em 0;
    max-width: 6em;
  }
}
