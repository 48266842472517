@use "scss/meta" as *;

.trayView {
  display: grid;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0.05em;
  grid-row-gap: 0.05em;
  margin: 0.5em -0.2em;
  /*> * {
    width: calc(100% / 7);
  }*/
}

.trayViewEntry {
  padding: $spacing-02;
  margin: 0;
  > * {
    padding: $spacing-03;
    background: $layer;
    //min-height: 8em;
  }
  label {
    height: 100%;
  }
  :global(.wfp--checkbox-wrapper) {
    margin: 0 !important;
  }
}

.trayViewEntryTitle {
  font-size: 1.5em;
}

.trayViewJson {
  @include breakpoint-down("md") {
    display: none;
  }
}

.trayViewList {
  margin-top: 0.7em;
  margin-bottom: 1.2em;
  font-weight: normal;
  font-size: 0.8em;
  @include breakpoint-down("md") {
    display: none;
  }
}
