@use "scss/meta" as *;

@import "scss/global";

.wrapper {
  margin: 0;
  margin-right: $spacing-2xs;
  &:last-child {
    margin-right: 0;
  }
  //flex-grow: 1;

  min-width: calc(33% - 1em);
  max-width: 12em;
  @include breakpoint(bp--lg--major) {
    min-width: 13%;
  }
  margin-bottom: $spacing-2xs;
  text-align: center;

  input {
    display: none;
  }
}

.vertical {
}

.fullWidth {
  min-width: 0;
  flex: 1;
}

.icon {
  display: block;
  font-size: 2em;
  margin-bottom: 0.3em;
}
.wrapper .label {
  background-color: $layer;
  color: $text-primary;
  padding: $spacing-md $spacing-xs;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column-reverse;
  transition: all 0.1s;
  font-weight: 600;
  border: 2px solid $layer;
}

.labelText {
  font-size: 0.85em;
}

.wrapper input:checked ~ .label {
  //color: $background;
  //background-color: $interactive;
  //color: $background;
  color: $interactive;
  background-color: $layer;
  border-color: $interactive;
}

@mixin verticalCheckbox {
  min-width: 100%;

  .selector {
    position: relative;
    width: 1rem;
    height: 1rem;
    background-color: $background;
    border: 2px solid $border-strong;
    margin-right: 1em;
    border-radius: 100%;
  }

  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: none;
  }
  .labelText {
    flex-grow: 1;
    text-align: left;
  }
  .icon {
    margin-left: 0.2rem;
    margin-right: 0.5rem;
    width: 2.7rem;
    margin-bottom: 0;
  }
  input:checked ~ .label {
    .selector {
      border-color: $interactive;
      &:after {
        position: absolute;
        top: 0.1em;
        left: 0.1em;
        width: 0.55em;
        height: 0.55em;
        border-radius: 1em;
        display: block;
        background: $interactive;
        content: "";
      }
    }
  }
}

.vertical {
  @include verticalCheckbox;
  min-width: inherit;
  @include breakpoint-up(md) {
    min-width: 30em;
    width: 30em;
  }
}

.mobileVertical {
  @include breakpoint-down(md) {
    @include verticalCheckbox;
  }
}

.description {
  display: block;
  font-weight: normal;
  color: $text-secondary;
  margin-top: 0.4em;
}
