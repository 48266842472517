@import "../../scss/global";
.row {
  margin-bottom: 0 !important;
  :global {
    .wfp--form-item {
      margin-bottom: 0;
    }
  }
  > * {
    margin-bottom: 1.5em;
    /*&:last-child {
      margin-bottom: 0;
    }*/
  }
}
