@use "scss/meta" as *;
@import "../../scss/global";

.trayTable {
  height: 100%;

  display: grid;
  grid-template-columns: 0.2fr repeat(7, 1fr);
  grid-template-rows: 0.1fr repeat(4, 1fr);
  margin-right: $spacing-md;
  margin-bottom: $spacing-md;
  @include breakpointMax(bp--md--major) {
    margin-right: 0;
    margin-bottom: 0;
    height: 40vh;
  }
  &.dot {
    //height: 55vh;
  }
}
.headings {
  display: flex;
  width: 100%;
  align-content: stretch;
}
.headingsEntry {
  width: 100%;
  margin: 0.3em;
  font-weight: 600;
  text-align: center;
}

.day {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  //width: calc(100% / 7);
  //max-width: calc(100% / 7);

  width: calc(100% / 7 - 2.3rem / 7);
  max-width: calc(100% / 7 - 2.3rem / 7);
  @include breakpoint(bp--lg--major) {
    width: calc(100% / 7 - 3rem / 7);
    max-width: calc(100% / 7 - 3rem / 7);
  }
  //overflow: hidden;
  min-height: 100%;
  /*@include breakpointMax(bp--lg--major) {
    height: 40em;
  }*/

  align-content: stretch;
  padding: 0;
}

.dayTitle {
  height: 2.3rem;
  min-height: 2.3rem;
  text-align: center;
}

.dayTitleText {
  font-weight: 600;
}

.dayTitleSub {
  color: var(--text-02);
  @media print {
    color: #000;
  }
  @include typescale("caption");
  margin-top: $spacing-2xs;
  @include breakpointMax(bp--md--major) {
    font-size: 0.8em;
  }
}
.dayTimeList {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 3rem;
  @include breakpointMax(bp--lg--major) {
    min-width: 2rem;
    max-width: 2rem;
    // display: none;
  }
  align-content: stretch;
  align-items: center;
  margin: 0 0 0 0.3em;
  @include breakpointMax(bp--lg--major) {
    margin: 0;
  }
  text-align: center;
}
.timeTitleText {
  font-weight: 600;
}

.timeTitleSub {
  margin-top: $spacing-2xs;
  font-weight: normal;
  color: var(--text-02);
  @media print {
    color: #000;
  }
  font-size: 0.7em;
  @include breakpoint(bp--lg--major) {
    font-size: 0.8em;
  }
}

.timeTitleIcon {
  color: var(--text-01);
  margin-bottom: $spacing-2xs;
  > svg {
    height: 1.3em;
    width: 1.3em !important;
    .dot & {
      height: 1em;
      width: 1em !important;
    }
  }
  @include breakpointMax(bp--lg--major) {
    margin-bottom: 0;
  }
}

.timeTitle {
  width: 100%;
  margin: 0.3em;
  padding: 1rem 0;
  @include breakpointMax(bp--lg--major) {
    margin: 0;
    padding: 0.4rem 0;
    background: $layer;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.isToday {
  @media screen {
    position: relative;
    .dayTitleText {
      position: relative;
      top: -1px;
      z-index: 1;
      font-size: 0.9em;
      color: #fff;
      display: inline-block;
      &:after {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: -3px;
        left: -7px;
        right: -7px;
        bottom: -4px;
        border-radius: 30px;
        background: #ff2c00;
      }
    }
    .dayTitleSub {
      padding-top: 1px;
      color: #ff2c00;
    }
  }
}

.trayTableFill {
  background: var(--ui-02);
  //border: 4px solid #fff;
  border-radius: 30px;
  //margin: 3rem;
  padding: 0;
  @include breakpoint(bp--md--major) {
    padding: 2rem 1.4rem 1.4rem 1rem;
    min-height: 30em;
  }

  //min-height: 80px;
  /*box-shadow: inset 0px 20px 30px -20px rgba(#fff, 0.7),
    inset -10px -20px 30px -10px rgba(#000, 0.15),
    5px 20px 30px -10px rgba(#000, 0.3);*/
  @include breakpointMax(bp--lg--major) {
    margin: 0 -1em 0 -1em;
    padding: 0.5rem 0rem 0rem 0.1rem;
    border-radius: 0px;
    box-shadow: none;
    background: $background;
  }
}

.notRelated {
  display: inline-block !important;
  margin-left: 0.4em;
  margin-right: -0.1em;
  @include breakpointMax(bp--lg--major) {
    margin-left: 0.1em;
    margin-right: -0.2em;
  }
  position: relative;
  z-index: 1;
  //background: #fff;
  svg {
    color: $support-warning;
  }
  &:after {
    content: "";
    border-radius: 5em;
    background: #fff;
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    z-index: -1;
  }
  font-size: 1em;
  line-height: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}

.isToday {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0em;
    left: -1px;
    right: -1px;

    bottom: 0;
    border: 0;
    pointer-events: none;
    border: 1px solid #ff2c00;
    @include breakpoint(bp--lg--major) {
      top: 1em;
      border-width: 2px;
    }
    border-style: dashed;
    z-index: 1;
    //border-radius: 0 0 10px 10px;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    //background: #ff2c00; //$border-strong;
  }
}
