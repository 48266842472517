@import "scss/global";

.errorInline {
  height: 3em;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--text-03);
  background: var(--support-01);
}
