.name {
  font-size: 1em;
  font-weight: 600;
  grid-area: name;
  margin-top: 1em;
}

.userDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 1em;
}
