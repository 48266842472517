@use "scss/meta" as *;

.battery {
  width: 100%;
  height: auto;
  overflow: visible;

  text {
    display: none;
  }

  .batteryUnknown {
    text {
      display: block;
      fill: var(--ui-05);
      font-size: 20em;
      font-weight: 700;
      stroke: red;
      stroke-width: 300px;
    }

    .battery__status {
      fill: transparent !important;
    }
  }

  path {
    fill: var(--ui-05);
  }

  &.red {
    .batteryStatus {
      fill: var(--support-01);
    }
  }

  &.orange {
    .batteryStatus {
      fill: var(--support-03);
    }
  }

  &.green {
    .batteryStatus {
      fill: var(--support-02);
    }
  }
}

.batteryStatus {
  fill: $color__green;
}
