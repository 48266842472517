@use "scss/meta" as *;

.deviceIcon {
  // width: 2rem;
  // margin-right: 1rem;
  display: flex;
  position: relative;
}
.deviceIconImage {
  height: auto !important;
  width: 1.9rem !important;
  color: var(--ui-07);
}

.status {
  color: $interactive;
  width: 0.5rem !important;
  margin-left: -0.2em !important;
  margin-right: 0.5em !important;
  opacity: 1;
  .read & {
    opacity: 0;
  }
}
