@use "scss/meta" as *;
@import "../../scss/global";

.medicationEntryTitle {
  font-weight: 600;
  padding: $spacing-2xs;
  word-break: break-all;
  /*.dot & {
    padding: 0;
    font-weight: normal;
  }*/
  padding-bottom: 0;
}

.medicationEntryAmount {
  //background: red;
  font-weight: bold;
  height: 100%;
  white-space: nowrap;
  //background: 1px solid rgba(#000, 0.2);
  padding: $spacing-2xs;
  padding-left: $spacing-xs;
  padding-right: $spacing-2xs;
  .dot & {
    /*padding: 0;*/
    padding-left: $spacing-2xs;
    padding-right: $spacing-3xs;
  }
  .isPlanned & {
  }
}

.medicationEntrySubTitle {
  font-size: 0.9em;
  max-height: 0;
  margin-top: $spacing-3xs;
  position: relative;
  top: 0.3em;
  transition: all 0.2s;
}

.medicationEntry {
  color: var(--ui-01);
  background: var(--interactive-01);
  cursor: pointer;
  font-size: 0.75em;
  border-radius: 5px;
  text-decoration: none;
  // padding-bottom: $spacing-3xs;
  margin: 0.05em;
  margin-bottom: $spacing-2xs;
  overflow: hidden;

  display: block;
  text-decoration: none;
  display: flex;
  align-items: center;
  border: 1.5px solid;

  &.dot {
    border-radius: 30px;
    align-items: flex-start;
  }
  /*@media print {
      background: none !important;
      color: #000;
      font-size: 0.8rem;
      border-radius: 0;
      border-bottom: 1px solid #000;
      &:last-child {
        border-bottom-width: 0px;
      }
    }*/
}

.medicationEntryActive {
  position: relative;
  box-shadow: 0px 7px 15px -6px rgba(#000, 0.5);
  //background: $support-03 !important;
  margin-bottom: 0.7em;
  z-index: 100;
  .medicationEntrySubTitle {
    margin-top: $spacing-3xs;
    padding-bottom: 0.3em;
    margin-bottom: 0.3em;
    max-height: 2em;
  }
}

.liveTrayActive {
  border-color: var(--support-03) !important;
}

.isPlanned {
  background: #f5f7f8;
  @include breakpoint-up(md) {
    border-style: dashed;
  }
}

.medicationEntryFill {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.medicationEntryCount {
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 0.3rem;
}

.trayOverview.dot {
  flex-direction: row;
}
.dot {
  padding: 0;
  font-size: 0.7em;
  white-space: nowrap;
  margin: 0.08em;
  width: 0.7em;
  height: 0.7em;
  //border-radius: 10px;
  > * {
    display: none;
  }
}

.details {
  background: #fff;
  font-size: 1em;
  margin: 0;
  padding: 0;
  border-radius: 0;
  border-right-width: 0;
}

.detailIcon {
  height: 3em;
  width: 0.3em;
  margin: -0.3em;
}

.debug {
  font-size: 0.5em;
}

.emptyStomach {
  display: inline-flex;
  border: 1px solid currentColor;
  position: relative;
  top: -0.5px;
  border-radius: 3px;
  font-size: 0.8em;
  padding: 0.1em 0.2em;
  margin-right: 0.2em;
}
