@import "../../scss/global";

.settingsTitle {
  //margin-bottom: $spacing-md;
  display: flex;
  font-weight: 600;
  flex: 1;
  font-size: 1.5em;
  justify-content: space-between;
  :global {
    .wfp--form-item {
      flex: inherit;
    }
  }
}

.narrow {
  font-size: 1em;
}

.subTitle {
  font-size: 1.3rem;
}
