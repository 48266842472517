@use "scss/meta" as *;

$out-of-range-color: lighten(#333, 40%) !default;
$out-of-range-bg-color: lighten(#333, 70%) !default;

$calendar-border: #ddd !default;
$cell-border: #ddd !default;

$border-color: #ccc !default;

$segment-width: 0.1428571429 !default;

$time-selection-color: white !default;
$time-selection-bg-color: rgba(0, 0, 0, 0.5) !default;
$date-selection-bg-color: rgba(0, 0, 0, 0.1) !default;

$event-bg: green;
$event-border: var(--interactive-02);
$event-outline: var(--interactive-01);
$event-color: #fff !default;
$event-border-radius: 5px !default;
$event-padding: 2px 5px !default;
$event-zindex: 4 !default;

$btn-color: #373a3c !default;
$btn-bg: #fff !default;
$btn-border: #ccc !default;

$current-time-color: #74ad31 !default;

$rbc-css-prefix: rbc-i !default;

$today-highlight-bg: #eaf6ff !default;

@import "react-big-calendar/lib/sass/styles";
//@import "react-big-calendar/lib/addons/dragAndDrop/styles";

.rbc-header {
  background: var(--ui-01);
  padding: 1em 0;
}

.rbc-calendar {
  height: auto;
}

.rbc-time-view,
.rbc-month-view {
  background: var(--ui-01);
}

.rbc-month-row {
  overflow: visible;
}

.rbc-event {
  border: none !important;
  background: none;
  overflow: visible !important;
  padding: 4px;
}

.rbc-event-label {
  display: none;
}

.rbc-toolbar-label {
  font-weight: lighter;
  font-size: 2rem;
  @include breakpoint-down(md) {
    display: none;
  }
}

.rbc-btn-group {
  display: flex;
  @include breakpoint-down(md) {
    margin-top: $spacing-04;
    font-size: 1.2em;
    button {
      padding-left: 1.2em;
      padding-right: 1.2em;
    }
  }
  button {
    margin-right: 3px !important;
    &:last-child {
      margin-right: 0 !important;
    }
  }
  @media print {
    display: none;
  }
}
