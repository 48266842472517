@use "scss/meta" as *;
@import "scss/global";

.mobileRemove {
  margin: -1em -0.2em;
  :global(.wfp--btn__icon) {
    width: 1.2rem;
    height: 1.2rem;
  }
}

.mobileName {
  flex: 1;
  margin-left: 1em;
}

:global(.wfp--sidebar-content__container--active) {
  &:after {
    display: block;
    content: "";
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000000;
    background: rgba(#fff, 0.3);
    backdrop-filter: blur(10px) opacity(3);
    //height: 100px;
    height: $safe-area-inset-top;
  }
}
