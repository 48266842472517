@use "scss/meta" as *;
@import "../../scss/global";

.calendarDetails {
  // background: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title {
  border-top: 1px solid $border-strong;
  border-bottom: 1px solid $border-strong;
  //background: $layer;
  padding: 1em;
  display: flex;
  align-items: center;
  background: #fff;
}

.fillInfo {
  flex-grow: 1;
  /*border-top: 1px solid $border-strong;
  border-bottom: 1px solid $border-strong;
  background: $layer;
  padding: 1em;
  .fillText {
    font-weight: 600;
  }*/
}

.noEntries {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $text-secondary;
  flex: 1;
}

.fillInfoTitle {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 0.2em;
}

.fillInfoMeta {
  font-size: 0.9em;
  color: $text-secondary;
}

.noTray {
  text-align: center;
  margin: 2em;
  > svg {
    margin-bottom: 0.5em;
    font-size: 1.5em;
  }
}

.intakeTime {
  display: block;
  text-align: right;
  font-size: 0.8em;
  margin-top: 0.3em;
}
