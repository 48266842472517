@use "scss/meta" as *;
@import "scss/global";

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}

.headerRight {
  display: flex;

  @media print {
    display: none;
  }
  > * {
    margin-left: $spacing-md;
  }
}
.saveIcon {
  color: var(--interactive-01);
}

.infoBlockquote {
  margin-top: 1.5em;
  margin-bottom: 2em;
}

.regularForm {
  min-height: 100%;
  display: flex;
  padding: 1em;
  flex-direction: column;
  .formWrapper > [class="wfp--form-item"],
  .formWrapper > * {
    margin-bottom: 2rem;
  }

  &.formWrapper {
    > [class="wfp--form-item"],
    > * {
      margin-bottom: 2rem;
    }
  }

  @include breakpoint(bp--md--major) {
    /*&.formWrapper {
      padding: 3em 0em;
      max-width: 60em;
    }*/

    margin: auto;
    padding: 2em;
    max-width: 60em;
  }
}

.fullWidth {
  max-width: 100%;
}
.formWrapper {
  height: 100%;
  //display: flex;
  //flex-direction: column;
}

.form {
  @include breakpoint(bp--md--major) {
    padding: 0;
  }
  //margin: auto;
  width: 100%;
  min-height: 100%;

  h3 {
    font-size: 1.2em;
    font-weight: 600;
    margin-top: 2em;
    margin-bottom: 1em;
  }

  @include breakpointMax(bp--md--major) {
    > form {
      margin-bottom: 10px;
      margin-bottom: env(safe-area-inset-bottom);
      //border-bottom: env(safe-area-inset-bottom) solid red;
    }
    //border-bottom: env(safe-area-inset-bottom) solid red;
  }
  // env(safe-area-inset-bottom)
}

.form.narrow {
  > form {
    padding-top: 0;
  }
}

.deleteTitle {
  @include breakpointMax(bp--md--major) {
    display: none;
  }
}

.mobileRemove {
  margin: -1em 0;
}

.global {
  padding: 0;
  .formWrapper {
    padding: 0 !important;
  }
}

.isLoading,
.error {
  opacity: 0.4;
  pointer-events: none;
}

.fullHeight {
  flex: 1;
  display: flex;
  flex-direction: column;
  .formWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }
}
