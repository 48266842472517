@use "scss/meta" as *;

.week-picker {
  .react-datepicker {
    border: 0;
    font-size: 1em;
    width: 100%;
  }
  .react-datepicker__month {
    margin-left: -0.1em;
    margin-right: -0.1em;
  }
  .react-datepicker__current-month {
    font-weight: 1rem !important;
    margin-bottom: 1em;
  }
  .react-datepicker__day,
  .react-datepicker__day-name {
    width: calc(100% / 7 - 2 * 0.166rem);
    line-height: 2.5rem;
    @include breakpoint(md) {
      width: 3.5rem;
      line-height: 3.5rem;
    }
    position: relative;
    background: none;
  }
  .react-datepicker__day-name {
    font-weight: 500;
  }

  .react-datepicker__day--outside-month {
    opacity: 0.5;
  }
  .react-datepicker__baked {
    background: $support-error-light;
    &.react-datepicker__day--disabled {
      &:hover {
        background: $support-error-light;
      }
    }
    &:hover {
      background: $support-error;
    }
  }
  .react-datepicker__day--disabled {
    color: rgba(0, 0, 0, 0.3);
  }
}
.react-datepicker-popper[data-placement^="top"],
.react-datepicker__triangle {
  &:before {
    // &:after {
    border-top-color: transparent !important; //$background;
  }
  &:after {
    border-top-color: $background;
  }
}

.react-datepicker-popper[data-placement^="bottom"] {
  .react-datepicker__triangle {
    &:before {
      // &:after {
      border-bottom-color: transparent !important; //$background;
    }
    &:after {
      border-bottom-color: $background !important;
    }
  }
}
.react-datepicker__day--in-range {
  color: $text-inverse;
  background: $interactive !important;
}

.react-datepicker__month-container {
  width: 100%;
}
.react-datepicker__header {
  background: none;
  border-bottom: 1px solid $layer;
}
.react-datepicker__day--keyboard-selected {
  color: $text-primary;
}
.react-datepicker-popper {
  .react-datepicker {
    border: 0;
    font-size: 0.8em;
    width: 100%;
    box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.1);
  }
}

.react-datepicker__close-icon {
  margin-right: 0.2em;
  &:after {
    font-size: 1.4em;
    width: 1.3em;
    height: 1.3em;
    color: $interactive;
    background-color: $layer;
  }
}

.react-datepicker__day--today {
  position: relative;
  z-index: 1;
  color: $text-inverse;
  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -1em;
    margin-top: -1em;
    width: 2em;
    height: 2em;
    border-radius: 30px;
    background: #ff2c00 !important;
    z-index: -1;
  }
}
