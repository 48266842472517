@use "scss/meta" as *;
@import "../../scss/global";

.trayStyle {
  background: var(--ui-01);
  flex: 1;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  position: relative;
  border: 1px solid var(--ui-03);
  @include breakpointMax(bp--lg--major) {
    border-right: 0;
    border-bottom: 0;
    &:last-child {
      border-bottom: 1px solid var(--ui-03);
    }
  }

  @include breakpoint(bp--lg--major) {
    border-radius: 10px;
    border-width: 2px;
    margin: 0.3rem;
    padding: 0.5rem;
  }

  &.dot {
    //border: 1px solid var(--ui-03);
    border-right: 0;
    border-bottom: 0;
  }
}

.trayFill {
  padding: 0;
  .entryList {
    display: flex;
  }
}

$gradientColor: $layer;
$gradientColorB: #fff;

.tray {
  /*@media print {
    border: 1px solid #000;
    background: #fff;
  }*/
  text-decoration: none;
  // overflow: auto;
  :global {
    .newEntryButton {
      opacity: 0;
    }
  }
  &:hover {
    :global {
      .newEntryButton {
        opacity: 1;
      }
    }
  }
  &.isPast {
    /* background: #f5f7f8;
    background: repeating-linear-gradient(
      -45deg,
      $gradientColor,
      $gradientColor 3px,
      $gradientColorB 3px,
      $gradientColorB 10px
    );
    @include breakpointMax(bp--lg--major) {
      .entryList {
        display: none;
      }
    }*/
  }
  &.isActive {
    @include breakpointMax(bp--lg--major) {
      z-index: 50;
      //outline: 3px solid $interactive;
      box-shadow: 0px 7px 15px -2px rgba(#000, 0.2);
    }
  }
}
.punctual,
.slightlyUnpunctual,
.unpunctual {
  @include breakpointMax(bp--lg--major) {
    //padding-left: 0.3em;
  }
  /*&:after {
    position: absolute;
    top: 0;
    left: 0%;
    bottom: 0;
    // bottom: 0;
    margin-left: 0;

    margin-top: 0em;
    content: "";
    margin: 0.2em;
    margin-left: 0.2em;

    width: 0.2em;
    border-radius: 0;
    @include breakpoint(bp--md--major) {
      //  display: none;
    }
  }*/
}

.intakePunctuality {
  font-size: 0.7em;
  //position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  background: $background;
  justify-content: center;
  bottom: 0;
  right: 0;
  margin: 0.3em;
  padding: 0.7em 0.3em;
  border-radius: 5px;
  font-weight: 600;
  z-index: 1;

  @include breakpoint-down(md) {
    padding: 0.3em;
    font-size: 1.5em;
    position: relative;
    flex: 1;
  }
  @include breakpoint-up(md) {
    font-size: 0.7em;
    padding: 0.7em;
  }
  &:after {
    @include breakpoint-down(md) {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -0.7em;
      margin-top: -0.7em;
      display: block;
      content: "";
      width: 1.4em;
      height: 1.4em;
      border-radius: 10em;
      z-index: -1;
      opacity: 0.2;
    }
  }
}
.punctual {
  &:after {
    @include breakpoint-up(md) {
      background: $support-success-light;
    }
  }
  .intakePunctuality {
    &:after {
      background: $support-success-light;
    }
  }
}

.slightlyUnpunctual {
  .intakePunctuality {
    @include breakpoint-up(md) {
      background: $support-warning-light;
    }
    &:after {
      background: $support-warning-light;
    }
  }
}

.unpunctual {
  .intakePunctuality {
    @include breakpoint-up(md) {
      background: $support-error-light;
    }
    &:after {
      background: $support-error-light;
    }
  }
}
.intakePunctualityText {
  margin-left: 0.3em;
}

/*
.past {
  background-color: #fff !important;
  border: 1px solid red;
}
*/

.entryList {
  flex: 1;
  position: relative;
}

.dot {
  //flex: none;
  .entryList {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
  }
}
.dayName {
  text-align: center;
}

.isToday {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0em;
    left: -1px;
    right: -1px;

    bottom: 0;
    border: 0;
    pointer-events: none;
    border: 1px solid #ff2c00;
    @include breakpoint(bp--lg--major) {
      top: -1em;
      border-width: 2px;
      left: -0.5em;
    }
    border-style: dashed;
    z-index: 1;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
  }
}

.moreEvents {
  margin-top: 0.2em;
  font-weight: 700;
  font-size: 0.7em;
  color: $text-secondary;
}
