@use "scss/meta" as *;
@import "scss/global";

.color {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.subTitle {
  margin-top: $spacing-xs;
  color: var(--text-02);
  @include typescale("caption");
}

.medicationRadio {
  input:checked ~ label {
    .medicationIcon {
      opacity: 1;

      :global(#background) {
        //fill: green;
        /* stroke: rgba(#fff, 0.5);
        stroke-width: 3.5px; */
        filter: drop-shadow(1px 4px 1px rgba(0, 0, 0, 0.3));
      }
    }
  }
}

.weekdayWrapper {
  :global(.wfp--input-group-inside) {
  }
}

.medicationInfo {
  margin: 0;
  border: none !important;
  background: white !important;
  border-bottom: 1px solid $border-strong !important;
}
