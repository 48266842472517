// Code generated by @un/themes. DO NOT EDIT.
//
// Copyright IBM Corp. 2018, 2019
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

/// Primary interactive color; Primary buttons
/// @type {undefined}
/// @access public
/// @group @un/themes
$interactive-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "interactive-01"),
  map-get($carbon--theme, "interactive-01"),
  #0b77c2
) !default;

/// Secondary interactive color; Secondary button
/// @type {undefined}
/// @access public
/// @group @un/themes
$interactive-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "interactive-02"),
  map-get($carbon--theme, "interactive-02"),
  #0b77c2
) !default;

/// 4.5:1 AA contrast; Tertiary button
/// @type {undefined}
/// @access public
/// @group @un/themes
$interactive-03: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "interactive-03"),
  map-get($carbon--theme, "interactive-03"),
  #5a6872
) !default;

/// 3:1 AA contrast; Selected elements; Active elements; Accent icons
/// @type {undefined}
/// @access public
/// @group @un/themes
$interactive-04: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "interactive-04"),
  map-get($carbon--theme, "interactive-04"),
  #0b77c2
) !default;

/// Default page background
/// @type {undefined}
/// @access public
/// @group @un/themes
$ui-background: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "ui-background"),
  map-get($carbon--theme, "ui-background"),
  #ffffff
) !default;

/// Primary container background; Secondary page background
/// @type {undefined}
/// @access public
/// @group @un/themes
$ui-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "ui-01"),
  map-get($carbon--theme, "ui-01"),
  #ebf1f4
) !default;

/// Primary page background; Secondary container background
/// @type {undefined}
/// @access public
/// @group @un/themes
$ui-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "ui-02"),
  map-get($carbon--theme, "ui-02"),
  #cccccc
) !default;

/// Subtle border; Tertiary background color
/// @type {undefined}
/// @access public
/// @group @un/themes
$ui-03: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "ui-03"),
  map-get($carbon--theme, "ui-03"),
  #d7dfe7
) !default;

/// 3:1 AA element contrast; Medium contrast border
/// @type {undefined}
/// @access public
/// @group @un/themes
$ui-04: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "ui-04"),
  map-get($carbon--theme, "ui-04"),
  #d3e1e8
) !default;

/// 4.5:1 AA element contrast; High contrast border; Emphasis elements
/// @type {undefined}
/// @access public
/// @group @un/themes
$ui-05: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "ui-05"),
  map-get($carbon--theme, "ui-05"),
  #8c9ba5
) !default;

/// Primary text; Body copy; Headers; Hover text color for `$text-02`
/// @type {undefined}
/// @access public
/// @group @un/themes
$text-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-01"),
  map-get($carbon--theme, "text-01"),
  #031c2d
) !default;

/// Secondary text; Input labels; Help text
/// @type {undefined}
/// @access public
/// @group @un/themes
$text-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-02"),
  map-get($carbon--theme, "text-02"),
  #5a6872
) !default;

/// Placeholder text
/// @type {undefined}
/// @access public
/// @group @un/themes
$text-03: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-03"),
  map-get($carbon--theme, "text-03"),
  #96a2a8
) !default;

/// Text on interactive colors
/// @type {undefined}
/// @access public
/// @group @un/themes
$text-04: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-04"),
  map-get($carbon--theme, "text-04"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$text-05: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-05"),
  map-get($carbon--theme, "text-05"),
  #5a6872
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$text-error: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-error"),
  map-get($carbon--theme, "text-error"),
  #c5192d
) !default;

/// Primary icons
/// @type {undefined}
/// @access public
/// @group @un/themes
$icon-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "icon-01"),
  map-get($carbon--theme, "icon-01"),
  #1d2023
) !default;

/// Secondary icons
/// @type {undefined}
/// @access public
/// @group @un/themes
$icon-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "icon-02"),
  map-get($carbon--theme, "icon-02"),
  #97a2a9
) !default;

/// Tertiary icons; Icons on interactive colors; Icons on non-ui colors
/// @type {undefined}
/// @access public
/// @group @un/themes
$icon-03: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "icon-03"),
  map-get($carbon--theme, "icon-03"),
  #ffffff
) !default;

/// Primary links; Ghost button
/// @type {undefined}
/// @access public
/// @group @un/themes
$link-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "link-01"),
  map-get($carbon--theme, "link-01"),
  #0b77c2
) !default;

/// Secondary link color for lower contrast backgrounds
/// @type {undefined}
/// @access public
/// @group @un/themes
$link-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "link-02"),
  map-get($carbon--theme, "link-02"),
  #085387
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$inverse-link: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "inverse-link"),
  map-get($carbon--theme, "inverse-link"),
  #8ccfff
) !default;

/// Default input fields; Field color on $ui-backgrounds
/// @type {undefined}
/// @access public
/// @group @un/themes
$field-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "field-01"),
  map-get($carbon--theme, "field-01"),
  #ffffff
) !default;

/// Input field color on `$ui-02` backgrounds
/// @type {undefined}
/// @access public
/// @group @un/themes
$field-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "field-02"),
  map-get($carbon--theme, "field-02"),
  #cccccc
) !default;

/// Inverse text color; Inverse icon color
/// @type {undefined}
/// @access public
/// @group @un/themes
$inverse-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "inverse-01"),
  map-get($carbon--theme, "inverse-01"),
  #f3f7f9
) !default;

/// High contrast backgrounds; High contrast elements
/// @type {undefined}
/// @access public
/// @group @un/themes
$inverse-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "inverse-02"),
  map-get($carbon--theme, "inverse-02"),
  #333945
) !default;

/// Error
/// @type {undefined}
/// @access public
/// @group @un/themes
$support-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "support-01"),
  map-get($carbon--theme, "support-01"),
  #c5192d
) !default;

/// Success
/// @type {undefined}
/// @access public
/// @group @un/themes
$support-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "support-02"),
  map-get($carbon--theme, "support-02"),
  #689e18
) !default;

/// Warning
/// @type {undefined}
/// @access public
/// @group @un/themes
$support-03: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "support-03"),
  map-get($carbon--theme, "support-03"),
  #f7b825
) !default;

/// Information
/// @type {undefined}
/// @access public
/// @group @un/themes
$support-04: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "support-04"),
  map-get($carbon--theme, "support-04"),
  #007dbc
) !default;

/// Error on high contrast backgrounds
/// @type {undefined}
/// @access public
/// @group @un/themes
$inverse-support-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "inverse-support-01"),
  map-get($carbon--theme, "inverse-support-01"),
  #ff4056
) !default;

/// Success on high contrast backgrounds
/// @type {undefined}
/// @access public
/// @group @un/themes
$inverse-support-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "inverse-support-02"),
  map-get($carbon--theme, "inverse-support-02"),
  #9eea2d
) !default;

/// Warning on high contrast backgrounds
/// @type {undefined}
/// @access public
/// @group @un/themes
$inverse-support-03: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "inverse-support-03"),
  map-get($carbon--theme, "inverse-support-03"),
  #ffcf60
) !default;

/// Information on high contrast backgrounds
/// @type {undefined}
/// @access public
/// @group @un/themes
$inverse-support-04: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "inverse-support-04"),
  map-get($carbon--theme, "inverse-support-04"),
  #17b1ff
) !default;

/// Background overlay
/// @type {undefined}
/// @access public
/// @group @un/themes
$overlay-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "overlay-01"),
  map-get($carbon--theme, "overlay-01"),
  #0b1923
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$danger-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "danger-01"),
  map-get($carbon--theme, "danger-01"),
  #c5192d
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$danger-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "danger-02"),
  map-get($carbon--theme, "danger-02"),
  #c5192d
) !default;

/// Focus border; Focus underline
/// @type {undefined}
/// @access public
/// @group @un/themes
$focus: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "focus"),
  map-get($carbon--theme, "focus"),
  #0076ff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$inverse-focus-ui: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "inverse-focus-ui"),
  map-get($carbon--theme, "inverse-focus-ui"),
  #0b77c2
) !default;

/// `$interactive-01` hover
/// @type {undefined}
/// @access public
/// @group @un/themes
$hover-primary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "hover-primary"),
  map-get($carbon--theme, "hover-primary"),
  #085387
) !default;

/// `$interactive-01` active
/// @type {undefined}
/// @access public
/// @group @un/themes
$active-primary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "active-primary"),
  map-get($carbon--theme, "active-primary"),
  #1a4262
) !default;

/// `$interactive-01` text hover
/// @type {undefined}
/// @access public
/// @group @un/themes
$hover-primary-text: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "hover-primary-text"),
  map-get($carbon--theme, "hover-primary-text"),
  #085387
) !default;

/// `$interactive-02` hover
/// @type {undefined}
/// @access public
/// @group @un/themes
$hover-secondary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "hover-secondary"),
  map-get($carbon--theme, "hover-secondary"),
  #085387
) !default;

/// `$interactive-02` active; `$inverse-01` active
/// @type {undefined}
/// @access public
/// @group @un/themes
$active-secondary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "active-secondary"),
  map-get($carbon--theme, "active-secondary"),
  #1a4262
) !default;

/// `$interactive-03` hover; `$inverse-01` hover
/// @type {undefined}
/// @access public
/// @group @un/themes
$hover-tertiary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "hover-tertiary"),
  map-get($carbon--theme, "hover-tertiary"),
  #031c2d
) !default;

/// `$interactive-03` active
/// @type {undefined}
/// @access public
/// @group @un/themes
$active-tertiary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "active-tertiary"),
  map-get($carbon--theme, "active-tertiary"),
  #031c2d
) !default;

/// `$ui-01` hover; `$ui-02` hover; Transparent background hover
/// @type {undefined}
/// @access public
/// @group @un/themes
$hover-ui: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "hover-ui"),
  map-get($carbon--theme, "hover-ui"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$hover-light-ui: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "hover-light-ui"),
  map-get($carbon--theme, "hover-light-ui"),
  #5e5e5e
) !default;

/// Data table selected row hover
/// @type {undefined}
/// @access public
/// @group @un/themes
$hover-selected-ui: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "hover-selected-ui"),
  map-get($carbon--theme, "hover-selected-ui"),
  #0b77c2
) !default;

/// `$ui-01` active; `$ui-02` active
/// @type {undefined}
/// @access public
/// @group @un/themes
$active-ui: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "active-ui"),
  map-get($carbon--theme, "active-ui"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$active-light-ui: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "active-light-ui"),
  map-get($carbon--theme, "active-light-ui"),
  #cccccc
) !default;

/// Selected UI elements
/// @type {undefined}
/// @access public
/// @group @un/themes
$selected-ui: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "selected-ui"),
  map-get($carbon--theme, "selected-ui"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$selected-light-ui: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "selected-light-ui"),
  map-get($carbon--theme, "selected-light-ui"),
  #cccccc
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$inverse-hover-ui: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "inverse-hover-ui"),
  map-get($carbon--theme, "inverse-hover-ui"),
  #313c44
) !default;

/// Danger hover; `$support-01` hover
/// @type {undefined}
/// @access public
/// @group @un/themes
$hover-danger: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "hover-danger"),
  map-get($carbon--theme, "hover-danger"),
  #981323
) !default;

/// Danger active; `$support-01` active
/// @type {undefined}
/// @access public
/// @group @un/themes
$active-danger: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "active-danger"),
  map-get($carbon--theme, "active-danger"),
  #451e22
) !default;

/// Row hover
/// @type {undefined}
/// @access public
/// @group @un/themes
$hover-row: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "hover-row"),
  map-get($carbon--theme, "hover-row"),
  #0b77c2
) !default;

/// Visited links
/// @type {undefined}
/// @access public
/// @group @un/themes
$visited-link: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "visited-link"),
  map-get($carbon--theme, "visited-link"),
  #085387
) !default;

/// Disabled fields; Disabled backgrounds; Disabled border
/// @type {undefined}
/// @access public
/// @group @un/themes
$disabled-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "disabled-01"),
  map-get($carbon--theme, "disabled-01"),
  #586b77
) !default;

/// Disabled elements on `$disabled-01`; Disabled label; Disabled text on `$disabled-01`; Disabled icons; Disabled border
/// @type {undefined}
/// @access public
/// @group @un/themes
$disabled-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "disabled-02"),
  map-get($carbon--theme, "disabled-02"),
  #8c9ba5
) !default;

/// Disabled text on `$disabled-02`; Disabled icons on `$disabled-02`
/// @type {undefined}
/// @access public
/// @group @un/themes
$disabled-03: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "disabled-03"),
  map-get($carbon--theme, "disabled-03"),
  #5a6872
) !default;

/// `$interactive-01` high light
/// @type {undefined}
/// @access public
/// @group @un/themes
$highlight: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "highlight"),
  map-get($carbon--theme, "highlight"),
  #bbe3ff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$decorative-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "decorative-01"),
  map-get($carbon--theme, "decorative-01"),
  #cccccc
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-separator: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-separator"),
  map-get($carbon--theme, "button-separator"),
  #c5192d
) !default;

/// Skeleton state of graphics
/// @type {undefined}
/// @access public
/// @group @un/themes
$skeleton-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "skeleton-01"),
  map-get($carbon--theme, "skeleton-01"),
  #8c9ba5
) !default;

/// Skeleton state of text
/// @type {undefined}
/// @access public
/// @group @un/themes
$skeleton-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "skeleton-02"),
  map-get($carbon--theme, "skeleton-02"),
  #dfe3e6
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$background: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "background"),
  map-get($carbon--theme, "background"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$layer: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layer"),
  map-get($carbon--theme, "layer"),
  #ebf1f4
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$layer-accent: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layer-accent"),
  map-get($carbon--theme, "layer-accent"),
  #d7dfe7
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$layer-accent-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layer-accent-hover"),
  map-get($carbon--theme, "layer-accent-hover"),
  #586b77
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$layer-accent-active: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layer-accent-active"),
  map-get($carbon--theme, "layer-accent-active"),
  #7e8b93
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$field: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "field"),
  map-get($carbon--theme, "field"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$background-inverse: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "background-inverse"),
  map-get($carbon--theme, "background-inverse"),
  #333945
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$background-brand: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "background-brand"),
  map-get($carbon--theme, "background-brand"),
  #0b77c2
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$interactive: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "interactive"),
  map-get($carbon--theme, "interactive"),
  #0076ff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$border-subtle: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "border-subtle"),
  map-get($carbon--theme, "border-subtle"),
  #f0f3f6
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$border-strong: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "border-strong"),
  map-get($carbon--theme, "border-strong"),
  #d3e1e8
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$border-inverse: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "border-inverse"),
  map-get($carbon--theme, "border-inverse"),
  #8c9ba5
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$border-interactive: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "border-interactive"),
  map-get($carbon--theme, "border-interactive"),
  #007dbc
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$text-primary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-primary"),
  map-get($carbon--theme, "text-primary"),
  #031c2d
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$text-secondary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-secondary"),
  map-get($carbon--theme, "text-secondary"),
  #5a6872
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$text-placeholder: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-placeholder"),
  map-get($carbon--theme, "text-placeholder"),
  #96a2a8
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$text-helper: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-helper"),
  map-get($carbon--theme, "text-helper"),
  #5a6872
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$text-on-color: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-on-color"),
  map-get($carbon--theme, "text-on-color"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$text-inverse: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-inverse"),
  map-get($carbon--theme, "text-inverse"),
  #f3f7f9
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$link-primary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "link-primary"),
  map-get($carbon--theme, "link-primary"),
  #0b77c2
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$link-secondary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "link-secondary"),
  map-get($carbon--theme, "link-secondary"),
  #085387
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$link-visited: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "link-visited"),
  map-get($carbon--theme, "link-visited"),
  #085387
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$link-inverse: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "link-inverse"),
  map-get($carbon--theme, "link-inverse"),
  #8ccfff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$icon-primary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "icon-primary"),
  map-get($carbon--theme, "icon-primary"),
  #1d2023
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$icon-secondary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "icon-secondary"),
  map-get($carbon--theme, "icon-secondary"),
  #97a2a9
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$icon-on-color: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "icon-on-color"),
  map-get($carbon--theme, "icon-on-color"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$icon-inverse: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "icon-inverse"),
  map-get($carbon--theme, "icon-inverse"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$support-error: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "support-error"),
  map-get($carbon--theme, "support-error"),
  #c5192d
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$support-success: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "support-success"),
  map-get($carbon--theme, "support-success"),
  #7dc125
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$support-warning: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "support-warning"),
  map-get($carbon--theme, "support-warning"),
  #f7b825
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$support-info: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "support-info"),
  map-get($carbon--theme, "support-info"),
  #007dbc
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$support-error-inverse: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "support-error-inverse"),
  map-get($carbon--theme, "support-error-inverse"),
  #ff4056
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$support-success-inverse: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "support-success-inverse"),
  map-get($carbon--theme, "support-success-inverse"),
  #9eea2d
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$support-warning-inverse: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "support-warning-inverse"),
  map-get($carbon--theme, "support-warning-inverse"),
  #ffcf60
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$support-info-inverse: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "support-info-inverse"),
  map-get($carbon--theme, "support-info-inverse"),
  #17b1ff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$overlay: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "overlay"),
  map-get($carbon--theme, "overlay"),
  #0b1923
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$toggle-off: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "toggle-off"),
  map-get($carbon--theme, "toggle-off"),
  #8c9ba5
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-primary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-primary"),
  map-get($carbon--theme, "button-primary"),
  #0076ff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-secondary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-secondary"),
  map-get($carbon--theme, "button-secondary"),
  #0b77c2
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-tertiary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-tertiary"),
  map-get($carbon--theme, "button-tertiary"),
  #d7dfe7
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-danger-primary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-danger-primary"),
  map-get($carbon--theme, "button-danger-primary"),
  #c5192d
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-danger-secondary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-danger-secondary"),
  map-get($carbon--theme, "button-danger-secondary"),
  #c5192d
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$background-active: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "background-active"),
  map-get($carbon--theme, "background-active"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$layer-active: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layer-active"),
  map-get($carbon--theme, "layer-active"),
  #dfe3e6
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-danger-active: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-danger-active"),
  map-get($carbon--theme, "button-danger-active"),
  #451e22
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-primary-active: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-primary-active"),
  map-get($carbon--theme, "button-primary-active"),
  #006be7
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-secondary-active: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-secondary-active"),
  map-get($carbon--theme, "button-secondary-active"),
  #1a4262
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-tertiary-active: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-tertiary-active"),
  map-get($carbon--theme, "button-tertiary-active"),
  #c4cfdb
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$focus-inset: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "focus-inset"),
  map-get($carbon--theme, "focus-inset"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$focus-inverse: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "focus-inverse"),
  map-get($carbon--theme, "focus-inverse"),
  #0b77c2
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$background-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "background-hover"),
  map-get($carbon--theme, "background-hover"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$layer-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layer-hover"),
  map-get($carbon--theme, "layer-hover"),
  #0b77c2
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$field-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "field-hover"),
  map-get($carbon--theme, "field-hover"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$background-inverse-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "background-inverse-hover"),
  map-get($carbon--theme, "background-inverse-hover"),
  #232831
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$link-primary-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "link-primary-hover"),
  map-get($carbon--theme, "link-primary-hover"),
  #006be7
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-danger-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-danger-hover"),
  map-get($carbon--theme, "button-danger-hover"),
  #981323
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-primary-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-primary-hover"),
  map-get($carbon--theme, "button-primary-hover"),
  #006be7
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-secondary-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-secondary-hover"),
  map-get($carbon--theme, "button-secondary-hover"),
  #085387
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-tertiary-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-tertiary-hover"),
  map-get($carbon--theme, "button-tertiary-hover"),
  #c4cfda
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$background-selected: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "background-selected"),
  map-get($carbon--theme, "background-selected"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$background-selected-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "background-selected-hover"),
  map-get($carbon--theme, "background-selected-hover"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$layer-selected: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layer-selected"),
  map-get($carbon--theme, "layer-selected"),
  #0b77c2
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$layer-selected-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layer-selected-hover"),
  map-get($carbon--theme, "layer-selected-hover"),
  #0b77c2
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$layer-selected-inverse: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layer-selected-inverse"),
  map-get($carbon--theme, "layer-selected-inverse"),
  #0b77c2
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$border-subtle-selected: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "border-subtle-selected"),
  map-get($carbon--theme, "border-subtle-selected"),
  #f0f3f6
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$layer-disabled: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layer-disabled"),
  map-get($carbon--theme, "layer-disabled"),
  #586b77
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$field-disabled: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "field-disabled"),
  map-get($carbon--theme, "field-disabled"),
  #f9fbfc
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$border-disabled: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "border-disabled"),
  map-get($carbon--theme, "border-disabled"),
  #dfe6eb
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$text-disabled: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-disabled"),
  map-get($carbon--theme, "text-disabled"),
  #8c9ba5
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-disabled: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-disabled"),
  map-get($carbon--theme, "button-disabled"),
  #c5cdd2
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$icon-disabled: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "icon-disabled"),
  map-get($carbon--theme, "icon-disabled"),
  #8c9ba5
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$text-on-color-disabled: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-on-color-disabled"),
  map-get($carbon--theme, "text-on-color-disabled"),
  #5a6872
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$icon-on-color-disabled: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "icon-on-color-disabled"),
  map-get($carbon--theme, "icon-on-color-disabled"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$layer-selected-disabled: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layer-selected-disabled"),
  map-get($carbon--theme, "layer-selected-disabled"),
  #0b77c2
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$skeleton-background: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "skeleton-background"),
  map-get($carbon--theme, "skeleton-background"),
  #8c9ba5
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$skeleton-element: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "skeleton-element"),
  map-get($carbon--theme, "skeleton-element"),
  #dfe3e6
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
/// @alias interactive-01
/// @deprecated
$brand-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "brand-01"),
  map-get($carbon--theme, "brand-01"),
  #0b77c2
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
/// @alias interactive-02
/// @deprecated
$brand-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "brand-02"),
  map-get($carbon--theme, "brand-02"),
  #0b77c2
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
/// @alias interactive-03
/// @deprecated
$brand-03: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "brand-03"),
  map-get($carbon--theme, "brand-03"),
  #5a6872
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
/// @alias active-ui
/// @deprecated
$active-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "active-01"),
  map-get($carbon--theme, "active-01"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
/// @alias hover-ui
/// @deprecated
$hover-field: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "hover-field"),
  map-get($carbon--theme, "hover-field"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$danger: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "danger"),
  map-get($carbon--theme, "danger"),
  #c5192d
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$corporate-1: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "corporate-1"),
  map-get($carbon--theme, "corporate-1"),
  #007dbc
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$corporate-2: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "corporate-2"),
  map-get($carbon--theme, "corporate-2"),
  #000000
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$corporate-3: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "corporate-3"),
  map-get($carbon--theme, "corporate-3"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$corporate-4: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "corporate-4"),
  map-get($carbon--theme, "corporate-4"),
  #36b5c5
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$corporate-5: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "corporate-5"),
  map-get($carbon--theme, "corporate-5"),
  #00b485
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$corporate-6: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "corporate-6"),
  map-get($carbon--theme, "corporate-6"),
  #008868
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$corporate-7: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "corporate-7"),
  map-get($carbon--theme, "corporate-7"),
  #1a4262
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$corporate-8: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "corporate-8"),
  map-get($carbon--theme, "corporate-8"),
  #982b56
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$corporate-9: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "corporate-9"),
  map-get($carbon--theme, "corporate-9"),
  #ef404c
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$corporate-10: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "corporate-10"),
  map-get($carbon--theme, "corporate-10"),
  #f47847
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$corporate-11: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "corporate-11"),
  map-get($carbon--theme, "corporate-11"),
  #b79f8d
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$corporate-12: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "corporate-12"),
  map-get($carbon--theme, "corporate-12"),
  #ecdfbb
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$brand: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "brand"),
  map-get($carbon--theme, "brand"),
  #0076ff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$brand-dark: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "brand-dark"),
  map-get($carbon--theme, "brand-dark"),
  #1a4262
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$focus-invterse: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "focus-invterse"),
  map-get($carbon--theme, "focus-invterse"),
  #0076ff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$-focus-inset: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "-focus-inset"),
  map-get($carbon--theme, "-focus-inset"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$-highlight: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "-highlight"),
  map-get($carbon--theme, "-highlight"),
  #bbe3ff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$background-inverse-max: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "background-inverse-max"),
  map-get($carbon--theme, "background-inverse-max"),
  #232831
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$text-warning: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-warning"),
  map-get($carbon--theme, "text-warning"),
  #b98407
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$text-success: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-success"),
  map-get($carbon--theme, "text-success"),
  #3f600f
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$text-info: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "text-info"),
  map-get($carbon--theme, "text-info"),
  #085387
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-ghost-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-ghost-hover"),
  map-get($carbon--theme, "button-ghost-hover"),
  #f0f3f6
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-ghost-active: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-ghost-active"),
  map-get($carbon--theme, "button-ghost-active"),
  #dde8ed
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-inverse: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-inverse"),
  map-get($carbon--theme, "button-inverse"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-inverse-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-inverse-hover"),
  map-get($carbon--theme, "button-inverse-hover"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-inverse-active: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-inverse-active"),
  map-get($carbon--theme, "button-inverse-active"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-main-navigation: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-main-navigation"),
  map-get($carbon--theme, "button-main-navigation"),
  #3b94d3
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$button-main-navigation-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "button-main-navigation-hover"),
  map-get($carbon--theme, "button-main-navigation-hover"),
  #509fd8
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$link-on-color: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "link-on-color"),
  map-get($carbon--theme, "link-on-color"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$-field-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "-field-hover"),
  map-get($carbon--theme, "-field-hover"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$field-border: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "field-border"),
  map-get($carbon--theme, "field-border"),
  #77a0b6
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$field-option-hover: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "field-option-hover"),
  map-get($carbon--theme, "field-option-hover"),
  #e0ebfd
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$field-option-active: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "field-option-active"),
  map-get($carbon--theme, "field-option-active"),
  #dfe3e6
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$icon-tertiary: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "icon-tertiary"),
  map-get($carbon--theme, "icon-tertiary"),
  #77a0b6
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$support-error-light: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "support-error-light"),
  map-get($carbon--theme, "support-error-light"),
  #f1c6cb
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$support-success-light: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "support-success-light"),
  map-get($carbon--theme, "support-success-light"),
  #eefbdd
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$support-warning-light: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "support-warning-light"),
  map-get($carbon--theme, "support-warning-light"),
  #fdedc9
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$support-info-light: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "support-info-light"),
  map-get($carbon--theme, "support-info-light"),
  #dae9f4
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$logo-white: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "logo-white"),
  map-get($carbon--theme, "logo-white"),
  #ffffff
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$logo-black: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "logo-black"),
  map-get($carbon--theme, "logo-black"),
  #000000
) !default;

/// @type {undefined}
/// @access public
/// @group @un/themes
$logo-blue: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "logo-blue"),
  map-get($carbon--theme, "logo-blue"),
  #0b77c2
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$caption-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "caption-01"),
  map-get($carbon--theme, "caption-01"),
  (
    font-size: 0.75rem,
    font-weight: 400,
    line-height: 1.34,
    letter-spacing: 0.32px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$caption-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "caption-02"),
  map-get($carbon--theme, "caption-02"),
  (
    font-size: 0.875rem,
    font-weight: 400,
    line-height: 1.29,
    letter-spacing: 0.32px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$label-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "label-01"),
  map-get($carbon--theme, "label-01"),
  (
    font-size: 0.75rem,
    font-weight: 400,
    line-height: 1.34,
    letter-spacing: 0.32px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$label-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "label-02"),
  map-get($carbon--theme, "label-02"),
  (
    font-size: 0.875rem,
    font-weight: 400,
    line-height: 1.29,
    letter-spacing: 0.32px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$helper-text-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "helper-text-01"),
  map-get($carbon--theme, "helper-text-01"),
  (
    font-size: 0.75rem,
    line-height: 1.34,
    letter-spacing: 0.32px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$helper-text-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "helper-text-02"),
  map-get($carbon--theme, "helper-text-02"),
  (
    font-size: 0.875rem,
    line-height: 1.29,
    letter-spacing: 0.32px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$body-short-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "body-short-01"),
  map-get($carbon--theme, "body-short-01"),
  (
    font-size: 0.875rem,
    font-weight: 400,
    line-height: 1.29,
    letter-spacing: 0.16px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$body-long-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "body-long-01"),
  map-get($carbon--theme, "body-long-01"),
  (
    font-size: 0.875rem,
    font-weight: 400,
    line-height: 1.43,
    letter-spacing: 0.16px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$body-short-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "body-short-02"),
  map-get($carbon--theme, "body-short-02"),
  (
    font-size: 1rem,
    font-weight: 400,
    line-height: 1.375,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$body-long-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "body-long-02"),
  map-get($carbon--theme, "body-long-02"),
  (
    font-size: 1rem,
    font-weight: 400,
    line-height: 1.5,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$code-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "code-01"),
  map-get($carbon--theme, "code-01"),
  (
    font-family:
      unquote(
        "'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace"
      ),
    font-size: 0.75rem,
    font-weight: 400,
    line-height: 1.34,
    letter-spacing: 0.32px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$code-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "code-02"),
  map-get($carbon--theme, "code-02"),
  (
    font-family:
      unquote(
        "'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace"
      ),
    font-size: 1rem,
    font-weight: 400,
    line-height: 1.43,
    letter-spacing: 0.32px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$heading-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "heading-01"),
  map-get($carbon--theme, "heading-01"),
  (
    font-size: 0.875rem,
    font-weight: 600,
    line-height: 1.29,
    letter-spacing: 0.16px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$heading-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "heading-02"),
  map-get($carbon--theme, "heading-02"),
  (
    font-size: 1rem,
    font-weight: 600,
    line-height: 1.375,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$productive-heading-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "productive-heading-01"),
  map-get($carbon--theme, "productive-heading-01"),
  (
    font-size: 1rem,
    font-weight: 600,
    line-height: 1.375,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$productive-heading-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "productive-heading-02"),
  map-get($carbon--theme, "productive-heading-02"),
  (
    font-size: 1.125rem,
    font-weight: 600,
    line-height: 1.375,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$productive-heading-03: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "productive-heading-03"),
  map-get($carbon--theme, "productive-heading-03"),
  (
    font-size: 1.25rem,
    font-weight: 600,
    line-height: 1.4,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$productive-heading-04: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "productive-heading-04"),
  map-get($carbon--theme, "productive-heading-04"),
  (
    font-size: 1.75rem,
    font-weight: 600,
    line-height: 1.29,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$productive-heading-05: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "productive-heading-05"),
  map-get($carbon--theme, "productive-heading-05"),
  (
    font-size: 1.5rem,
    font-weight: 600,
    line-height: 1.25,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$productive-heading-06: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "productive-heading-06"),
  map-get($carbon--theme, "productive-heading-06"),
  (
    font-size: 2.625rem,
    font-weight: 300,
    line-height: 1.199,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$productive-heading-07: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "productive-heading-07"),
  map-get($carbon--theme, "productive-heading-07"),
  (
    font-size: 3.375rem,
    font-weight: 300,
    line-height: 1.19,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$expressive-heading-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "expressive-heading-01"),
  map-get($carbon--theme, "expressive-heading-01"),
  (
    font-size: 0.875rem,
    font-weight: 600,
    line-height: 1.25,
    letter-spacing: 0.16px,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$expressive-heading-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "expressive-heading-02"),
  map-get($carbon--theme, "expressive-heading-02"),
  (
    font-size: 1rem,
    font-weight: 600,
    line-height: 1.5,
    letter-spacing: 0,
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$expressive-heading-03: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "expressive-heading-03"),
  map-get($carbon--theme, "expressive-heading-03"),
  (
    font-size: 1.25rem,
    font-weight: 400,
    line-height: 1.4,
    letter-spacing: 0,
    breakpoints: (
      xlg: (
        font-size: 1.25rem,
        line-height: 1.25,
      ),
      max: (
        font-size: 1.5rem,
        line-height: 1.334,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$expressive-heading-04: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "expressive-heading-04"),
  map-get($carbon--theme, "expressive-heading-04"),
  (
    font-size: 1.75rem,
    font-weight: 400,
    line-height: 1.29,
    letter-spacing: 0,
    breakpoints: (
      xlg: (
        font-size: 1.75rem,
        line-height: 1.25,
      ),
      max: (
        font-size: 2rem,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$expressive-heading-05: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "expressive-heading-05"),
  map-get($carbon--theme, "expressive-heading-05"),
  (
    font-size: 2rem,
    font-weight: 400,
    line-height: 1.25,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 2.25rem,
        font-weight: 300,
        line-height: 1.22,
        letter-spacing: 0,
      ),
      lg: (
        font-size: 2.625rem,
        font-weight: 300,
        line-height: 1.19,
        letter-spacing: 0,
      ),
      xlg: (
        font-size: 3rem,
        font-weight: 300,
        line-height: 1.17,
        letter-spacing: 0,
      ),
      max: (
        font-size: 3.75rem,
        font-weight: 300,
        letter-spacing: 0,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$expressive-heading-06: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "expressive-heading-06"),
  map-get($carbon--theme, "expressive-heading-06"),
  (
    font-size: 2rem,
    font-weight: 600,
    line-height: 1.25,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 2.25rem,
        font-weight: 600,
        line-height: 1.22,
        letter-spacing: 0,
      ),
      lg: (
        font-size: 2.625rem,
        font-weight: 600,
        line-height: 1.19,
        letter-spacing: 0,
      ),
      xlg: (
        font-size: 3rem,
        font-weight: 600,
        line-height: 1.17,
        letter-spacing: 0,
      ),
      max: (
        font-size: 3.75rem,
        font-weight: 600,
        letter-spacing: 0,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$expressive-paragraph-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "expressive-paragraph-01"),
  map-get($carbon--theme, "expressive-paragraph-01"),
  (
    font-size: 1.5rem,
    font-weight: 300,
    line-height: 1.334,
    letter-spacing: 0,
    breakpoints: (
      lg: (
        font-size: 1.75rem,
        line-height: 1.29,
      ),
      max: (
        font-size: 2rem,
        line-height: 1.25,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$quotation-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "quotation-01"),
  map-get($carbon--theme, "quotation-01"),
  (
    font-size: 1.25rem,
    font-weight: 400,
    line-height: 1.3,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 1.25rem,
        font-weight: 400,
        letter-spacing: 0,
      ),
      lg: (
        font-size: 1.5rem,
        font-weight: 400,
        line-height: 1.334,
        letter-spacing: 0,
      ),
      xlg: (
        font-size: 1.75rem,
        font-weight: 400,
        line-height: 1.29,
        letter-spacing: 0,
      ),
      max: (
        font-size: 2rem,
        font-weight: 400,
        line-height: 1.25,
        letter-spacing: 0,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$quotation-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "quotation-02"),
  map-get($carbon--theme, "quotation-02"),
  (
    font-size: 2rem,
    font-weight: 300,
    line-height: 1.25,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 2.25rem,
        line-height: 1.22,
      ),
      lg: (
        font-size: 2.625rem,
        line-height: 1.19,
      ),
      xlg: (
        font-size: 3rem,
        line-height: 1.17,
      ),
      max: (
        font-size: 3.75rem,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$display-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "display-01"),
  map-get($carbon--theme, "display-01"),
  (
    font-size: 2.625rem,
    font-weight: 300,
    line-height: 1.19,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 2.625rem,
      ),
      lg: (
        font-size: 3.375rem,
      ),
      xlg: (
        font-size: 3.75rem,
        line-height: 1.17,
      ),
      max: (
        font-size: 4.75rem,
        line-height: 1.13,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$display-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "display-02"),
  map-get($carbon--theme, "display-02"),
  (
    font-size: 2.625rem,
    font-weight: 600,
    line-height: 1.19,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 2.625rem,
      ),
      lg: (
        font-size: 3.375rem,
      ),
      xlg: (
        font-size: 3.75rem,
        line-height: 1.16,
      ),
      max: (
        font-size: 4.75rem,
        line-height: 1.13,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$display-03: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "display-03"),
  map-get($carbon--theme, "display-03"),
  (
    font-size: 2.625rem,
    font-weight: 300,
    line-height: 1.19,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 4.25rem,
        line-height: 1.15,
      ),
      lg: (
        font-size: 5.75rem,
        line-height: 1.11,
        letter-spacing: -0.64px,
      ),
      xlg: (
        font-size: 7.625rem,
        line-height: 1.07,
      ),
      max: (
        font-size: 9.75rem,
        line-height: 1.05,
        letter-spacing: -0.96px,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$display-04: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "display-04"),
  map-get($carbon--theme, "display-04"),
  (
    font-size: 2.625rem,
    font-weight: 600,
    line-height: 1.19,
    letter-spacing: 0,
    breakpoints: (
      md: (
        font-size: 4.25rem,
        line-height: 1.15,
      ),
      lg: (
        font-size: 5.75rem,
        line-height: 1.11,
        letter-spacing: -0.64px,
      ),
      xlg: (
        font-size: 7.625rem,
        line-height: 1.07,
        letter-spacing: -0.64px,
      ),
      max: (
        font-size: 9.75rem,
        line-height: 1.05,
        letter-spacing: -0.96px,
      ),
    ),
  )
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$spacing-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "spacing-01"),
  map-get($carbon--theme, "spacing-01"),
  0.125rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$spacing-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "spacing-02"),
  map-get($carbon--theme, "spacing-02"),
  0.25rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$spacing-03: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "spacing-03"),
  map-get($carbon--theme, "spacing-03"),
  0.5rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$spacing-04: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "spacing-04"),
  map-get($carbon--theme, "spacing-04"),
  0.75rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$spacing-05: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "spacing-05"),
  map-get($carbon--theme, "spacing-05"),
  1rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$spacing-06: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "spacing-06"),
  map-get($carbon--theme, "spacing-06"),
  1.5rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$spacing-07: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "spacing-07"),
  map-get($carbon--theme, "spacing-07"),
  2rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$spacing-08: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "spacing-08"),
  map-get($carbon--theme, "spacing-08"),
  2.5rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$spacing-09: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "spacing-09"),
  map-get($carbon--theme, "spacing-09"),
  3rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$spacing-10: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "spacing-10"),
  map-get($carbon--theme, "spacing-10"),
  4rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$spacing-11: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "spacing-11"),
  map-get($carbon--theme, "spacing-11"),
  5rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$spacing-12: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "spacing-12"),
  map-get($carbon--theme, "spacing-12"),
  6rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$spacing-13: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "spacing-13"),
  map-get($carbon--theme, "spacing-13"),
  10rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$fluid-spacing-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "fluid-spacing-01"),
  map-get($carbon--theme, "fluid-spacing-01"),
  0
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$fluid-spacing-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "fluid-spacing-02"),
  map-get($carbon--theme, "fluid-spacing-02"),
  2vw
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$fluid-spacing-03: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "fluid-spacing-03"),
  map-get($carbon--theme, "fluid-spacing-03"),
  5vw
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$fluid-spacing-04: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "fluid-spacing-04"),
  map-get($carbon--theme, "fluid-spacing-04"),
  10vw
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$layout-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layout-01"),
  map-get($carbon--theme, "layout-01"),
  1rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$layout-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layout-02"),
  map-get($carbon--theme, "layout-02"),
  1.5rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$layout-03: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layout-03"),
  map-get($carbon--theme, "layout-03"),
  2rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$layout-04: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layout-04"),
  map-get($carbon--theme, "layout-04"),
  3rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$layout-05: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layout-05"),
  map-get($carbon--theme, "layout-05"),
  4rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$layout-06: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layout-06"),
  map-get($carbon--theme, "layout-06"),
  6rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$layout-07: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "layout-07"),
  map-get($carbon--theme, "layout-07"),
  10rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$container-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "container-01"),
  map-get($carbon--theme, "container-01"),
  1.5rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$container-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "container-02"),
  map-get($carbon--theme, "container-02"),
  2rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$container-03: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "container-03"),
  map-get($carbon--theme, "container-03"),
  2.5rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$container-04: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "container-04"),
  map-get($carbon--theme, "container-04"),
  3rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$container-05: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "container-05"),
  map-get($carbon--theme, "container-05"),
  4rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$size-xsmall: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "size-xsmall"),
  map-get($carbon--theme, "size-xsmall"),
  1.5rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$size-small: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "size-small"),
  map-get($carbon--theme, "size-small"),
  2rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$size-medium: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "size-medium"),
  map-get($carbon--theme, "size-medium"),
  2.5rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$size-large: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "size-large"),
  map-get($carbon--theme, "size-large"),
  3rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$size-xlarge: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "size-xlarge"),
  map-get($carbon--theme, "size-xlarge"),
  4rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$size-2XLarge: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "size-2XLarge"),
  map-get($carbon--theme, "size-2XLarge"),
  5rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$icon-size-01: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "icon-size-01"),
  map-get($carbon--theme, "icon-size-01"),
  1rem
) !default;

/// @type {Number}
/// @access public
/// @group @un/themes
$icon-size-02: if(
  global-variable-exists("carbon--theme") and
    map-has-key($carbon--theme, "icon-size-02"),
  map-get($carbon--theme, "icon-size-02"),
  1.25rem
) !default;
