@import "scss/global";

.fillProcess {
  height: 100%;
  display: flex;
  background: #fff;
  padding-top: $safe-area-inset-top;
}

.sidebar {
  height: 100%;
  overflow: auto;
}

.patientSummary {
  text-align: center;
  padding: $spacing-md;
  padding-bottom: $spacing-lg;
  border-bottom: 1px solid var(--ui-04);

  :global {
    .wfp--user {
      justify-content: center;
    }
  }
}

.returnButton {
  margin-left: $spacing-md;
  margin-top: $spacing-md;
}

.avatar {
  margin: $spacing-md 0;
}

.name {
  @include typescale("delta");
}

.meta {
  @include typescale("zeta");
  color: var(--text-02);
}

.address {
  @include typescale("zeta");
}

.submitWrapper {
  padding: $spacing-md;
  display: flex;
  align-content: stretch;
  :global(.wfp--btn) {
    width: 100% !important;
  }
}
