@use "scss/meta" as *;

.dataVolumeWrapper {
  display: flex;
  align-items: center;
}
.dataVolume {
  width: 100%;
  max-width: 10em;
  margin-right: 0.5em;
  height: 2em;
  background: $support-success;
}

.dataVolumeUsed {
  background: $support-error;
  height: 100%;
  border-right: 2px solid $background;
}
