@import "../../scss/global";

.preview {
  width: 400px;
  height: auto;
}

.pictureButton {
  margin-bottom: $spacing-md;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing-md;
}

.patientName {
  font-weight: 600;
}

.subTitle {
  margin-top: $spacing-xs;
  color: var(--text-02);
  @include typescale("caption");
}

.imeiWrapper {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.buttonClassName {
  margin-left: $spacing-xs;
}

.battery {
  width: 1.7rem;
}

.content {
  flex: 1;
}
