@use "scss/meta" as *;

.inlineLoadingWrapper {
  display: flex;
  min-height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.inlineLoading {
  width: auto;
}
