@use "scss/meta" as *;

.submitWrapper {
  display: flex;
  align-items: center;
  gap: 1em;
  @include breakpoint-down(md) {
    align-content: stretch;

    > * {
      flex: 1;
      //margin-right: 1em;
    }
  }
  @include breakpoint-up(md) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}
