@import "scss/global";

.boundaries {
  display: flex;
  align-items: flex-end;
}

.saveIcon {
  color: var(--interactive-01);
}

.name {
  max-width: 20em;
}
