@use "scss/meta" as *;
@import "scss/global";

.select {
  :global {
    .wfp--react-select-container {
      width: 100%;
      min-width: 170px;
    }
    .wfp--react-select__single-value {
      font-weight: 600;
      font-size: 0.9rem;
      margin-top: -0.02em;
    }
    .wfp--react-select__control {
      min-height: 20px;
    }
    .wfp--react-select__option--is-focused {
      background: var(--ui-03);
    }
    .wfp--react-select__option--is-selected {
      background: $interactive;
      &:hover {
        color: $text-inverse;
      }
    }
  }
  &__stock {
    font-size: 0.7em;
    /*position: absolute;
    right: 0;
    > span {
      margin-top: 2rem;
      font-size: 0.5rem;
      opacity: 0.8;
      display: none;
    }
    */
  }
}

.input {
  width: 100%;
  display: flex;
  align-items: stretch;
}

.singleValueTitle {
  font-weight: 600;
  margin-top: 0.3em;
}

.singleValueSubTitle {
  color: var(--text-02);
  @include typescale("caption");
  margin-bottom: 0.3em;
}

:global(.wfp--react-select__option--is-selected) {
  color: #fff;
  .singleValueSubTitle {
    color: #fff;
  }
}

.noOptionsMessage {
  text-align: center;
  padding: $spacing-md 0;
}

.manage {
  margin-bottom: 1em;
}

.noOptionsMessageTitle {
  margin-bottom: $spacing-md;
  font-weight: 600;
  @include typescale("delta");
}

.menuAdditions {
  display: flex;
  padding: 0.8em 0 0.5em 0;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $border-strong;
  > * {
    margin: 0 0.2em;
  }
}

.scanButton {
  order: 3;
  margin-left: 0.5em;
  height: 100%;
}
