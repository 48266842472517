// Code generated by @un/themes. DO NOT EDIT.
//
// Copyright IBM Corp. 2018, 2019
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@import "./themes";

/// Define theme variables from a map of tokens
/// @access public
/// @param {Map} $theme [$carbon--theme] - Map of theme tokens
/// @param {Bool} $emit-custom-properties [false] - Output CSS Custom Properties for theme tokens
/// @content Pass in your custom declaration blocks to be used after the token maps set theming variables.
///
/// @example scss
///   // Default usage
///   @include carbon--theme();
///
///   // Alternate styling (not white theme)
///   @include carbon--theme($carbon--theme--g90) {
///     // declarations...
///   }
///
///   // Inline styling
///   @include carbon--theme($carbon--theme--g90) {
///     .my-dark-theme {
///       // declarations...
///     }
///   }
///
/// @group @un/themes
@mixin carbon--theme(
  $theme: $carbon--theme,
  $emit-custom-properties: false,
  $emit-difference: false
) {
  $parent-carbon-theme: $carbon--theme;
  $carbon--theme: $theme !global;
  $interactive-01: map-get($theme, "interactive-01") !global;
  $interactive-02: map-get($theme, "interactive-02") !global;
  $interactive-03: map-get($theme, "interactive-03") !global;
  $interactive-04: map-get($theme, "interactive-04") !global;
  $ui-background: map-get($theme, "ui-background") !global;
  $ui-01: map-get($theme, "ui-01") !global;
  $ui-02: map-get($theme, "ui-02") !global;
  $ui-03: map-get($theme, "ui-03") !global;
  $ui-04: map-get($theme, "ui-04") !global;
  $ui-05: map-get($theme, "ui-05") !global;
  $text-01: map-get($theme, "text-01") !global;
  $text-02: map-get($theme, "text-02") !global;
  $text-03: map-get($theme, "text-03") !global;
  $text-04: map-get($theme, "text-04") !global;
  $text-05: map-get($theme, "text-05") !global;
  $text-error: map-get($theme, "text-error") !global;
  $icon-01: map-get($theme, "icon-01") !global;
  $icon-02: map-get($theme, "icon-02") !global;
  $icon-03: map-get($theme, "icon-03") !global;
  $link-01: map-get($theme, "link-01") !global;
  $link-02: map-get($theme, "link-02") !global;
  $inverse-link: map-get($theme, "inverse-link") !global;
  $field-01: map-get($theme, "field-01") !global;
  $field-02: map-get($theme, "field-02") !global;
  $inverse-01: map-get($theme, "inverse-01") !global;
  $inverse-02: map-get($theme, "inverse-02") !global;
  $support-01: map-get($theme, "support-01") !global;
  $support-02: map-get($theme, "support-02") !global;
  $support-03: map-get($theme, "support-03") !global;
  $support-04: map-get($theme, "support-04") !global;
  $inverse-support-01: map-get($theme, "inverse-support-01") !global;
  $inverse-support-02: map-get($theme, "inverse-support-02") !global;
  $inverse-support-03: map-get($theme, "inverse-support-03") !global;
  $inverse-support-04: map-get($theme, "inverse-support-04") !global;
  $overlay-01: map-get($theme, "overlay-01") !global;
  $danger-01: map-get($theme, "danger-01") !global;
  $danger-02: map-get($theme, "danger-02") !global;
  $focus: map-get($theme, "focus") !global;
  $inverse-focus-ui: map-get($theme, "inverse-focus-ui") !global;
  $hover-primary: map-get($theme, "hover-primary") !global;
  $active-primary: map-get($theme, "active-primary") !global;
  $hover-primary-text: map-get($theme, "hover-primary-text") !global;
  $hover-secondary: map-get($theme, "hover-secondary") !global;
  $active-secondary: map-get($theme, "active-secondary") !global;
  $hover-tertiary: map-get($theme, "hover-tertiary") !global;
  $active-tertiary: map-get($theme, "active-tertiary") !global;
  $hover-ui: map-get($theme, "hover-ui") !global;
  $hover-light-ui: map-get($theme, "hover-light-ui") !global;
  $hover-selected-ui: map-get($theme, "hover-selected-ui") !global;
  $active-ui: map-get($theme, "active-ui") !global;
  $active-light-ui: map-get($theme, "active-light-ui") !global;
  $selected-ui: map-get($theme, "selected-ui") !global;
  $selected-light-ui: map-get($theme, "selected-light-ui") !global;
  $inverse-hover-ui: map-get($theme, "inverse-hover-ui") !global;
  $hover-danger: map-get($theme, "hover-danger") !global;
  $active-danger: map-get($theme, "active-danger") !global;
  $hover-row: map-get($theme, "hover-row") !global;
  $visited-link: map-get($theme, "visited-link") !global;
  $disabled-01: map-get($theme, "disabled-01") !global;
  $disabled-02: map-get($theme, "disabled-02") !global;
  $disabled-03: map-get($theme, "disabled-03") !global;
  $highlight: map-get($theme, "highlight") !global;
  $decorative-01: map-get($theme, "decorative-01") !global;
  $button-separator: map-get($theme, "button-separator") !global;
  $skeleton-01: map-get($theme, "skeleton-01") !global;
  $skeleton-02: map-get($theme, "skeleton-02") !global;
  $background: map-get($theme, "background") !global;
  $layer: map-get($theme, "layer") !global;
  $layer-accent: map-get($theme, "layer-accent") !global;
  $layer-accent-hover: map-get($theme, "layer-accent-hover") !global;
  $layer-accent-active: map-get($theme, "layer-accent-active") !global;
  $field: map-get($theme, "field") !global;
  $background-inverse: map-get($theme, "background-inverse") !global;
  $background-brand: map-get($theme, "background-brand") !global;
  $interactive: map-get($theme, "interactive") !global;
  $border-subtle: map-get($theme, "border-subtle") !global;
  $border-strong: map-get($theme, "border-strong") !global;
  $border-inverse: map-get($theme, "border-inverse") !global;
  $border-interactive: map-get($theme, "border-interactive") !global;
  $text-primary: map-get($theme, "text-primary") !global;
  $text-secondary: map-get($theme, "text-secondary") !global;
  $text-placeholder: map-get($theme, "text-placeholder") !global;
  $text-helper: map-get($theme, "text-helper") !global;
  $text-on-color: map-get($theme, "text-on-color") !global;
  $text-inverse: map-get($theme, "text-inverse") !global;
  $link-primary: map-get($theme, "link-primary") !global;
  $link-secondary: map-get($theme, "link-secondary") !global;
  $link-visited: map-get($theme, "link-visited") !global;
  $link-inverse: map-get($theme, "link-inverse") !global;
  $icon-primary: map-get($theme, "icon-primary") !global;
  $icon-secondary: map-get($theme, "icon-secondary") !global;
  $icon-on-color: map-get($theme, "icon-on-color") !global;
  $icon-inverse: map-get($theme, "icon-inverse") !global;
  $support-error: map-get($theme, "support-error") !global;
  $support-success: map-get($theme, "support-success") !global;
  $support-warning: map-get($theme, "support-warning") !global;
  $support-info: map-get($theme, "support-info") !global;
  $support-error-inverse: map-get($theme, "support-error-inverse") !global;
  $support-success-inverse: map-get($theme, "support-success-inverse") !global;
  $support-warning-inverse: map-get($theme, "support-warning-inverse") !global;
  $support-info-inverse: map-get($theme, "support-info-inverse") !global;
  $overlay: map-get($theme, "overlay") !global;
  $toggle-off: map-get($theme, "toggle-off") !global;
  $button-primary: map-get($theme, "button-primary") !global;
  $button-secondary: map-get($theme, "button-secondary") !global;
  $button-tertiary: map-get($theme, "button-tertiary") !global;
  $button-danger-primary: map-get($theme, "button-danger-primary") !global;
  $button-danger-secondary: map-get($theme, "button-danger-secondary") !global;
  $background-active: map-get($theme, "background-active") !global;
  $layer-active: map-get($theme, "layer-active") !global;
  $button-danger-active: map-get($theme, "button-danger-active") !global;
  $button-primary-active: map-get($theme, "button-primary-active") !global;
  $button-secondary-active: map-get($theme, "button-secondary-active") !global;
  $button-tertiary-active: map-get($theme, "button-tertiary-active") !global;
  $focus-inset: map-get($theme, "focus-inset") !global;
  $focus-inverse: map-get($theme, "focus-inverse") !global;
  $background-hover: map-get($theme, "background-hover") !global;
  $layer-hover: map-get($theme, "layer-hover") !global;
  $field-hover: map-get($theme, "field-hover") !global;
  $background-inverse-hover: map-get(
    $theme,
    "background-inverse-hover"
  ) !global;
  $link-primary-hover: map-get($theme, "link-primary-hover") !global;
  $button-danger-hover: map-get($theme, "button-danger-hover") !global;
  $button-primary-hover: map-get($theme, "button-primary-hover") !global;
  $button-secondary-hover: map-get($theme, "button-secondary-hover") !global;
  $button-tertiary-hover: map-get($theme, "button-tertiary-hover") !global;
  $background-selected: map-get($theme, "background-selected") !global;
  $background-selected-hover: map-get(
    $theme,
    "background-selected-hover"
  ) !global;
  $layer-selected: map-get($theme, "layer-selected") !global;
  $layer-selected-hover: map-get($theme, "layer-selected-hover") !global;
  $layer-selected-inverse: map-get($theme, "layer-selected-inverse") !global;
  $border-subtle-selected: map-get($theme, "border-subtle-selected") !global;
  $layer-disabled: map-get($theme, "layer-disabled") !global;
  $field-disabled: map-get($theme, "field-disabled") !global;
  $border-disabled: map-get($theme, "border-disabled") !global;
  $text-disabled: map-get($theme, "text-disabled") !global;
  $button-disabled: map-get($theme, "button-disabled") !global;
  $icon-disabled: map-get($theme, "icon-disabled") !global;
  $text-on-color-disabled: map-get($theme, "text-on-color-disabled") !global;
  $icon-on-color-disabled: map-get($theme, "icon-on-color-disabled") !global;
  $layer-selected-disabled: map-get($theme, "layer-selected-disabled") !global;
  $skeleton-background: map-get($theme, "skeleton-background") !global;
  $skeleton-element: map-get($theme, "skeleton-element") !global;
  $brand-01: map-get($theme, "brand-01") !global;
  $brand-02: map-get($theme, "brand-02") !global;
  $brand-03: map-get($theme, "brand-03") !global;
  $active-01: map-get($theme, "active-01") !global;
  $hover-field: map-get($theme, "hover-field") !global;
  $danger: map-get($theme, "danger") !global;
  $corporate-1: map-get($theme, "corporate-1") !global;
  $corporate-2: map-get($theme, "corporate-2") !global;
  $corporate-3: map-get($theme, "corporate-3") !global;
  $corporate-4: map-get($theme, "corporate-4") !global;
  $corporate-5: map-get($theme, "corporate-5") !global;
  $corporate-6: map-get($theme, "corporate-6") !global;
  $corporate-7: map-get($theme, "corporate-7") !global;
  $corporate-8: map-get($theme, "corporate-8") !global;
  $corporate-9: map-get($theme, "corporate-9") !global;
  $corporate-10: map-get($theme, "corporate-10") !global;
  $corporate-11: map-get($theme, "corporate-11") !global;
  $corporate-12: map-get($theme, "corporate-12") !global;
  $brand: map-get($theme, "brand") !global;
  $brand-dark: map-get($theme, "brand-dark") !global;
  $focus-invterse: map-get($theme, "focus-invterse") !global;
  $-focus-inset: map-get($theme, "-focus-inset") !global;
  $-highlight: map-get($theme, "-highlight") !global;
  $background-inverse-max: map-get($theme, "background-inverse-max") !global;
  $text-warning: map-get($theme, "text-warning") !global;
  $text-success: map-get($theme, "text-success") !global;
  $text-info: map-get($theme, "text-info") !global;
  $button-ghost-hover: map-get($theme, "button-ghost-hover") !global;
  $button-ghost-active: map-get($theme, "button-ghost-active") !global;
  $button-inverse: map-get($theme, "button-inverse") !global;
  $button-inverse-hover: map-get($theme, "button-inverse-hover") !global;
  $button-inverse-active: map-get($theme, "button-inverse-active") !global;
  $button-main-navigation: map-get($theme, "button-main-navigation") !global;
  $button-main-navigation-hover: map-get(
    $theme,
    "button-main-navigation-hover"
  ) !global;
  $link-on-color: map-get($theme, "link-on-color") !global;
  $-field-hover: map-get($theme, "-field-hover") !global;
  $field-border: map-get($theme, "field-border") !global;
  $field-option-hover: map-get($theme, "field-option-hover") !global;
  $field-option-active: map-get($theme, "field-option-active") !global;
  $icon-tertiary: map-get($theme, "icon-tertiary") !global;
  $support-error-light: map-get($theme, "support-error-light") !global;
  $support-success-light: map-get($theme, "support-success-light") !global;
  $support-warning-light: map-get($theme, "support-warning-light") !global;
  $support-info-light: map-get($theme, "support-info-light") !global;
  $logo-white: map-get($theme, "logo-white") !global;
  $logo-black: map-get($theme, "logo-black") !global;
  $logo-blue: map-get($theme, "logo-blue") !global;
  $caption-01: map-get($theme, "caption-01") !global;
  $caption-02: map-get($theme, "caption-02") !global;
  $label-01: map-get($theme, "label-01") !global;
  $label-02: map-get($theme, "label-02") !global;
  $helper-text-01: map-get($theme, "helper-text-01") !global;
  $helper-text-02: map-get($theme, "helper-text-02") !global;
  $body-short-01: map-get($theme, "body-short-01") !global;
  $body-long-01: map-get($theme, "body-long-01") !global;
  $body-short-02: map-get($theme, "body-short-02") !global;
  $body-long-02: map-get($theme, "body-long-02") !global;
  $code-01: map-get($theme, "code-01") !global;
  $code-02: map-get($theme, "code-02") !global;
  $heading-01: map-get($theme, "heading-01") !global;
  $heading-02: map-get($theme, "heading-02") !global;
  $productive-heading-01: map-get($theme, "productive-heading-01") !global;
  $productive-heading-02: map-get($theme, "productive-heading-02") !global;
  $productive-heading-03: map-get($theme, "productive-heading-03") !global;
  $productive-heading-04: map-get($theme, "productive-heading-04") !global;
  $productive-heading-05: map-get($theme, "productive-heading-05") !global;
  $productive-heading-06: map-get($theme, "productive-heading-06") !global;
  $productive-heading-07: map-get($theme, "productive-heading-07") !global;
  $expressive-heading-01: map-get($theme, "expressive-heading-01") !global;
  $expressive-heading-02: map-get($theme, "expressive-heading-02") !global;
  $expressive-heading-03: map-get($theme, "expressive-heading-03") !global;
  $expressive-heading-04: map-get($theme, "expressive-heading-04") !global;
  $expressive-heading-05: map-get($theme, "expressive-heading-05") !global;
  $expressive-heading-06: map-get($theme, "expressive-heading-06") !global;
  $expressive-paragraph-01: map-get($theme, "expressive-paragraph-01") !global;
  $quotation-01: map-get($theme, "quotation-01") !global;
  $quotation-02: map-get($theme, "quotation-02") !global;
  $display-01: map-get($theme, "display-01") !global;
  $display-02: map-get($theme, "display-02") !global;
  $display-03: map-get($theme, "display-03") !global;
  $display-04: map-get($theme, "display-04") !global;
  $spacing-01: map-get($theme, "spacing-01") !global;
  $spacing-02: map-get($theme, "spacing-02") !global;
  $spacing-03: map-get($theme, "spacing-03") !global;
  $spacing-04: map-get($theme, "spacing-04") !global;
  $spacing-05: map-get($theme, "spacing-05") !global;
  $spacing-06: map-get($theme, "spacing-06") !global;
  $spacing-07: map-get($theme, "spacing-07") !global;
  $spacing-08: map-get($theme, "spacing-08") !global;
  $spacing-09: map-get($theme, "spacing-09") !global;
  $spacing-10: map-get($theme, "spacing-10") !global;
  $spacing-11: map-get($theme, "spacing-11") !global;
  $spacing-12: map-get($theme, "spacing-12") !global;
  $spacing-13: map-get($theme, "spacing-13") !global;
  $fluid-spacing-01: map-get($theme, "fluid-spacing-01") !global;
  $fluid-spacing-02: map-get($theme, "fluid-spacing-02") !global;
  $fluid-spacing-03: map-get($theme, "fluid-spacing-03") !global;
  $fluid-spacing-04: map-get($theme, "fluid-spacing-04") !global;
  $layout-01: map-get($theme, "layout-01") !global;
  $layout-02: map-get($theme, "layout-02") !global;
  $layout-03: map-get($theme, "layout-03") !global;
  $layout-04: map-get($theme, "layout-04") !global;
  $layout-05: map-get($theme, "layout-05") !global;
  $layout-06: map-get($theme, "layout-06") !global;
  $layout-07: map-get($theme, "layout-07") !global;
  $container-01: map-get($theme, "container-01") !global;
  $container-02: map-get($theme, "container-02") !global;
  $container-03: map-get($theme, "container-03") !global;
  $container-04: map-get($theme, "container-04") !global;
  $container-05: map-get($theme, "container-05") !global;
  $size-xsmall: map-get($theme, "size-xsmall") !global;
  $size-small: map-get($theme, "size-small") !global;
  $size-medium: map-get($theme, "size-medium") !global;
  $size-large: map-get($theme, "size-large") !global;
  $size-xlarge: map-get($theme, "size-xlarge") !global;
  $size-2XLarge: map-get($theme, "size-2XLarge") !global;
  $icon-size-01: map-get($theme, "icon-size-01") !global;
  $icon-size-02: map-get($theme, "icon-size-02") !global;

  @if global-variable-exists("feature-flags") ==
    false or
    global-variable-exists("feature-flags") and
    map-get($feature-flags, "enable-v11-release") !=
    true
  {
    $background: $ui-background !global;
    $layer: $ui-01 !global;
    $layer-accent: $ui-03 !global;
    $field: $field-01 !global;
    $background-inverse: $inverse-02 !global;
    $background-brand: $interactive-01 !global;
    $interactive: $interactive-04 !global;
    $border-subtle: $ui-03 !global;
    $border-strong: $ui-04 !global;
    $border-inverse: $ui-05 !global;
    $border-interactive: $interactive-04 !global;
    $text-primary: $text-01 !global;
    $text-secondary: $text-02 !global;
    $text-placeholder: $text-03 !global;
    $text-helper: $text-05 !global;
    $text-on-color: $text-04 !global;
    $text-inverse: $inverse-01 !global;
    $link-primary: $link-01 !global;
    $link-secondary: $link-02 !global;
    $link-visited: $visited-link !global;
    $link-inverse: $inverse-link !global;
    $icon-primary: $icon-01 !global;
    $icon-secondary: $icon-02 !global;
    $icon-on-color: $icon-03 !global;
    $icon-inverse: $inverse-01 !global;
    $support-error: $support-01 !global;
    $support-success: $support-02 !global;
    $support-warning: $support-03 !global;
    $support-info: $support-04 !global;
    $support-error-inverse: $inverse-support-01 !global;
    $support-success-inverse: $inverse-support-02 !global;
    $support-warning-inverse: $inverse-support-03 !global;
    $support-info-inverse: $inverse-support-04 !global;
    $overlay: $overlay-01 !global;
    $toggle-off: $ui-04 !global;
    $button-primary: $interactive-01 !global;
    $button-secondary: $interactive-02 !global;
    $button-tertiary: $interactive-03 !global;
    $button-danger-primary: $danger-01 !global;
    $button-danger-secondary: $danger-02 !global;
    $background-active: $active-ui !global;
    $layer-active: $active-ui !global;
    $button-danger-active: $active-danger !global;
    $button-primary-active: $active-primary !global;
    $button-secondary-active: $active-secondary !global;
    $button-tertiary-active: $active-tertiary !global;
    $focus-inset: $inverse-01 !global;
    $focus-inverse: $inverse-focus-ui !global;
    $background-hover: $hover-ui !global;
    $layer-hover: $hover-ui !global;
    $field-hover: $hover-ui !global;
    $background-inverse-hover: $inverse-hover-ui !global;
    $link-primary-hover: $hover-primary-text !global;
    $button-danger-hover: $hover-danger !global;
    $button-primary-hover: $hover-primary !global;
    $button-secondary-hover: $hover-secondary !global;
    $button-tertiary-hover: $hover-tertiary !global;
    $background-selected: $selected-ui !global;
    $background-selected-hover: $hover-selected-ui !global;
    $layer-selected: $selected-ui !global;
    $layer-selected-hover: $hover-selected-ui !global;
    $layer-selected-inverse: $ui-05 !global;
    $border-subtle-selected: $active-ui !global;
    $layer-disabled: $disabled-01 !global;
    $field-disabled: $disabled-01 !global;
    $border-disabled: $disabled-01 !global;
    $text-disabled: $disabled-02 !global;
    $button-disabled: $disabled-02 !global;
    $icon-disabled: $disabled-02 !global;
    $text-on-color-disabled: $disabled-03 !global;
    $icon-on-color-disabled: $disabled-03 !global;
    $layer-selected-disabled: $disabled-03 !global;
    $skeleton-background: $skeleton-01 !global;
    $skeleton-element: $skeleton-02 !global;
  }
  @if global-variable-exists("feature-flags") and
    map-get($feature-flags, "enable-css-custom-properties")
  {
    $interactive-01: var(
      --#{$custom-property-prefix}-interactive-01,
      map-get($theme, "interactive-01")
    ) !global;
    $interactive-02: var(
      --#{$custom-property-prefix}-interactive-02,
      map-get($theme, "interactive-02")
    ) !global;
    $interactive-03: var(
      --#{$custom-property-prefix}-interactive-03,
      map-get($theme, "interactive-03")
    ) !global;
    $interactive-04: var(
      --#{$custom-property-prefix}-interactive-04,
      map-get($theme, "interactive-04")
    ) !global;
    $ui-background: var(
      --#{$custom-property-prefix}-ui-background,
      map-get($theme, "ui-background")
    ) !global;
    $ui-01: var(
      --#{$custom-property-prefix}-ui-01,
      map-get($theme, "ui-01")
    ) !global;
    $ui-02: var(
      --#{$custom-property-prefix}-ui-02,
      map-get($theme, "ui-02")
    ) !global;
    $ui-03: var(
      --#{$custom-property-prefix}-ui-03,
      map-get($theme, "ui-03")
    ) !global;
    $ui-04: var(
      --#{$custom-property-prefix}-ui-04,
      map-get($theme, "ui-04")
    ) !global;
    $ui-05: var(
      --#{$custom-property-prefix}-ui-05,
      map-get($theme, "ui-05")
    ) !global;
    $text-01: var(
      --#{$custom-property-prefix}-text-01,
      map-get($theme, "text-01")
    ) !global;
    $text-02: var(
      --#{$custom-property-prefix}-text-02,
      map-get($theme, "text-02")
    ) !global;
    $text-03: var(
      --#{$custom-property-prefix}-text-03,
      map-get($theme, "text-03")
    ) !global;
    $text-04: var(
      --#{$custom-property-prefix}-text-04,
      map-get($theme, "text-04")
    ) !global;
    $text-05: var(
      --#{$custom-property-prefix}-text-05,
      map-get($theme, "text-05")
    ) !global;
    $text-error: var(
      --#{$custom-property-prefix}-text-error,
      map-get($theme, "text-error")
    ) !global;
    $icon-01: var(
      --#{$custom-property-prefix}-icon-01,
      map-get($theme, "icon-01")
    ) !global;
    $icon-02: var(
      --#{$custom-property-prefix}-icon-02,
      map-get($theme, "icon-02")
    ) !global;
    $icon-03: var(
      --#{$custom-property-prefix}-icon-03,
      map-get($theme, "icon-03")
    ) !global;
    $link-01: var(
      --#{$custom-property-prefix}-link-01,
      map-get($theme, "link-01")
    ) !global;
    $link-02: var(
      --#{$custom-property-prefix}-link-02,
      map-get($theme, "link-02")
    ) !global;
    $inverse-link: var(
      --#{$custom-property-prefix}-inverse-link,
      map-get($theme, "inverse-link")
    ) !global;
    $field-01: var(
      --#{$custom-property-prefix}-field-01,
      map-get($theme, "field-01")
    ) !global;
    $field-02: var(
      --#{$custom-property-prefix}-field-02,
      map-get($theme, "field-02")
    ) !global;
    $inverse-01: var(
      --#{$custom-property-prefix}-inverse-01,
      map-get($theme, "inverse-01")
    ) !global;
    $inverse-02: var(
      --#{$custom-property-prefix}-inverse-02,
      map-get($theme, "inverse-02")
    ) !global;
    $support-01: var(
      --#{$custom-property-prefix}-support-01,
      map-get($theme, "support-01")
    ) !global;
    $support-02: var(
      --#{$custom-property-prefix}-support-02,
      map-get($theme, "support-02")
    ) !global;
    $support-03: var(
      --#{$custom-property-prefix}-support-03,
      map-get($theme, "support-03")
    ) !global;
    $support-04: var(
      --#{$custom-property-prefix}-support-04,
      map-get($theme, "support-04")
    ) !global;
    $inverse-support-01: var(
      --#{$custom-property-prefix}-inverse-support-01,
      map-get($theme, "inverse-support-01")
    ) !global;
    $inverse-support-02: var(
      --#{$custom-property-prefix}-inverse-support-02,
      map-get($theme, "inverse-support-02")
    ) !global;
    $inverse-support-03: var(
      --#{$custom-property-prefix}-inverse-support-03,
      map-get($theme, "inverse-support-03")
    ) !global;
    $inverse-support-04: var(
      --#{$custom-property-prefix}-inverse-support-04,
      map-get($theme, "inverse-support-04")
    ) !global;
    $overlay-01: var(
      --#{$custom-property-prefix}-overlay-01,
      map-get($theme, "overlay-01")
    ) !global;
    $danger-01: var(
      --#{$custom-property-prefix}-danger-01,
      map-get($theme, "danger-01")
    ) !global;
    $danger-02: var(
      --#{$custom-property-prefix}-danger-02,
      map-get($theme, "danger-02")
    ) !global;
    $focus: var(
      --#{$custom-property-prefix}-focus,
      map-get($theme, "focus")
    ) !global;
    $inverse-focus-ui: var(
      --#{$custom-property-prefix}-inverse-focus-ui,
      map-get($theme, "inverse-focus-ui")
    ) !global;
    $hover-primary: var(
      --#{$custom-property-prefix}-hover-primary,
      map-get($theme, "hover-primary")
    ) !global;
    $active-primary: var(
      --#{$custom-property-prefix}-active-primary,
      map-get($theme, "active-primary")
    ) !global;
    $hover-primary-text: var(
      --#{$custom-property-prefix}-hover-primary-text,
      map-get($theme, "hover-primary-text")
    ) !global;
    $hover-secondary: var(
      --#{$custom-property-prefix}-hover-secondary,
      map-get($theme, "hover-secondary")
    ) !global;
    $active-secondary: var(
      --#{$custom-property-prefix}-active-secondary,
      map-get($theme, "active-secondary")
    ) !global;
    $hover-tertiary: var(
      --#{$custom-property-prefix}-hover-tertiary,
      map-get($theme, "hover-tertiary")
    ) !global;
    $active-tertiary: var(
      --#{$custom-property-prefix}-active-tertiary,
      map-get($theme, "active-tertiary")
    ) !global;
    $hover-ui: var(
      --#{$custom-property-prefix}-hover-ui,
      map-get($theme, "hover-ui")
    ) !global;
    $hover-light-ui: var(
      --#{$custom-property-prefix}-hover-light-ui,
      map-get($theme, "hover-light-ui")
    ) !global;
    $hover-selected-ui: var(
      --#{$custom-property-prefix}-hover-selected-ui,
      map-get($theme, "hover-selected-ui")
    ) !global;
    $active-ui: var(
      --#{$custom-property-prefix}-active-ui,
      map-get($theme, "active-ui")
    ) !global;
    $active-light-ui: var(
      --#{$custom-property-prefix}-active-light-ui,
      map-get($theme, "active-light-ui")
    ) !global;
    $selected-ui: var(
      --#{$custom-property-prefix}-selected-ui,
      map-get($theme, "selected-ui")
    ) !global;
    $selected-light-ui: var(
      --#{$custom-property-prefix}-selected-light-ui,
      map-get($theme, "selected-light-ui")
    ) !global;
    $inverse-hover-ui: var(
      --#{$custom-property-prefix}-inverse-hover-ui,
      map-get($theme, "inverse-hover-ui")
    ) !global;
    $hover-danger: var(
      --#{$custom-property-prefix}-hover-danger,
      map-get($theme, "hover-danger")
    ) !global;
    $active-danger: var(
      --#{$custom-property-prefix}-active-danger,
      map-get($theme, "active-danger")
    ) !global;
    $hover-row: var(
      --#{$custom-property-prefix}-hover-row,
      map-get($theme, "hover-row")
    ) !global;
    $visited-link: var(
      --#{$custom-property-prefix}-visited-link,
      map-get($theme, "visited-link")
    ) !global;
    $disabled-01: var(
      --#{$custom-property-prefix}-disabled-01,
      map-get($theme, "disabled-01")
    ) !global;
    $disabled-02: var(
      --#{$custom-property-prefix}-disabled-02,
      map-get($theme, "disabled-02")
    ) !global;
    $disabled-03: var(
      --#{$custom-property-prefix}-disabled-03,
      map-get($theme, "disabled-03")
    ) !global;
    $highlight: var(
      --#{$custom-property-prefix}-highlight,
      map-get($theme, "highlight")
    ) !global;
    $decorative-01: var(
      --#{$custom-property-prefix}-decorative-01,
      map-get($theme, "decorative-01")
    ) !global;
    $button-separator: var(
      --#{$custom-property-prefix}-button-separator,
      map-get($theme, "button-separator")
    ) !global;
    $skeleton-01: var(
      --#{$custom-property-prefix}-skeleton-01,
      map-get($theme, "skeleton-01")
    ) !global;
    $skeleton-02: var(
      --#{$custom-property-prefix}-skeleton-02,
      map-get($theme, "skeleton-02")
    ) !global;
    $background: var(
      --#{$custom-property-prefix}-background,
      map-get($theme, "background")
    ) !global;
    $layer: var(
      --#{$custom-property-prefix}-layer,
      map-get($theme, "layer")
    ) !global;
    $layer-accent: var(
      --#{$custom-property-prefix}-layer-accent,
      map-get($theme, "layer-accent")
    ) !global;
    $layer-accent-hover: var(
      --#{$custom-property-prefix}-layer-accent-hover,
      map-get($theme, "layer-accent-hover")
    ) !global;
    $layer-accent-active: var(
      --#{$custom-property-prefix}-layer-accent-active,
      map-get($theme, "layer-accent-active")
    ) !global;
    $field: var(
      --#{$custom-property-prefix}-field,
      map-get($theme, "field")
    ) !global;
    $background-inverse: var(
      --#{$custom-property-prefix}-background-inverse,
      map-get($theme, "background-inverse")
    ) !global;
    $background-brand: var(
      --#{$custom-property-prefix}-background-brand,
      map-get($theme, "background-brand")
    ) !global;
    $interactive: var(
      --#{$custom-property-prefix}-interactive,
      map-get($theme, "interactive")
    ) !global;
    $border-subtle: var(
      --#{$custom-property-prefix}-border-subtle,
      map-get($theme, "border-subtle")
    ) !global;
    $border-strong: var(
      --#{$custom-property-prefix}-border-strong,
      map-get($theme, "border-strong")
    ) !global;
    $border-inverse: var(
      --#{$custom-property-prefix}-border-inverse,
      map-get($theme, "border-inverse")
    ) !global;
    $border-interactive: var(
      --#{$custom-property-prefix}-border-interactive,
      map-get($theme, "border-interactive")
    ) !global;
    $text-primary: var(
      --#{$custom-property-prefix}-text-primary,
      map-get($theme, "text-primary")
    ) !global;
    $text-secondary: var(
      --#{$custom-property-prefix}-text-secondary,
      map-get($theme, "text-secondary")
    ) !global;
    $text-placeholder: var(
      --#{$custom-property-prefix}-text-placeholder,
      map-get($theme, "text-placeholder")
    ) !global;
    $text-helper: var(
      --#{$custom-property-prefix}-text-helper,
      map-get($theme, "text-helper")
    ) !global;
    $text-on-color: var(
      --#{$custom-property-prefix}-text-on-color,
      map-get($theme, "text-on-color")
    ) !global;
    $text-inverse: var(
      --#{$custom-property-prefix}-text-inverse,
      map-get($theme, "text-inverse")
    ) !global;
    $link-primary: var(
      --#{$custom-property-prefix}-link-primary,
      map-get($theme, "link-primary")
    ) !global;
    $link-secondary: var(
      --#{$custom-property-prefix}-link-secondary,
      map-get($theme, "link-secondary")
    ) !global;
    $link-visited: var(
      --#{$custom-property-prefix}-link-visited,
      map-get($theme, "link-visited")
    ) !global;
    $link-inverse: var(
      --#{$custom-property-prefix}-link-inverse,
      map-get($theme, "link-inverse")
    ) !global;
    $icon-primary: var(
      --#{$custom-property-prefix}-icon-primary,
      map-get($theme, "icon-primary")
    ) !global;
    $icon-secondary: var(
      --#{$custom-property-prefix}-icon-secondary,
      map-get($theme, "icon-secondary")
    ) !global;
    $icon-on-color: var(
      --#{$custom-property-prefix}-icon-on-color,
      map-get($theme, "icon-on-color")
    ) !global;
    $icon-inverse: var(
      --#{$custom-property-prefix}-icon-inverse,
      map-get($theme, "icon-inverse")
    ) !global;
    $support-error: var(
      --#{$custom-property-prefix}-support-error,
      map-get($theme, "support-error")
    ) !global;
    $support-success: var(
      --#{$custom-property-prefix}-support-success,
      map-get($theme, "support-success")
    ) !global;
    $support-warning: var(
      --#{$custom-property-prefix}-support-warning,
      map-get($theme, "support-warning")
    ) !global;
    $support-info: var(
      --#{$custom-property-prefix}-support-info,
      map-get($theme, "support-info")
    ) !global;
    $support-error-inverse: var(
      --#{$custom-property-prefix}-support-error-inverse,
      map-get($theme, "support-error-inverse")
    ) !global;
    $support-success-inverse: var(
      --#{$custom-property-prefix}-support-success-inverse,
      map-get($theme, "support-success-inverse")
    ) !global;
    $support-warning-inverse: var(
      --#{$custom-property-prefix}-support-warning-inverse,
      map-get($theme, "support-warning-inverse")
    ) !global;
    $support-info-inverse: var(
      --#{$custom-property-prefix}-support-info-inverse,
      map-get($theme, "support-info-inverse")
    ) !global;
    $overlay: var(
      --#{$custom-property-prefix}-overlay,
      map-get($theme, "overlay")
    ) !global;
    $toggle-off: var(
      --#{$custom-property-prefix}-toggle-off,
      map-get($theme, "toggle-off")
    ) !global;
    $button-primary: var(
      --#{$custom-property-prefix}-button-primary,
      map-get($theme, "button-primary")
    ) !global;
    $button-secondary: var(
      --#{$custom-property-prefix}-button-secondary,
      map-get($theme, "button-secondary")
    ) !global;
    $button-tertiary: var(
      --#{$custom-property-prefix}-button-tertiary,
      map-get($theme, "button-tertiary")
    ) !global;
    $button-danger-primary: var(
      --#{$custom-property-prefix}-button-danger-primary,
      map-get($theme, "button-danger-primary")
    ) !global;
    $button-danger-secondary: var(
      --#{$custom-property-prefix}-button-danger-secondary,
      map-get($theme, "button-danger-secondary")
    ) !global;
    $background-active: var(
      --#{$custom-property-prefix}-background-active,
      map-get($theme, "background-active")
    ) !global;
    $layer-active: var(
      --#{$custom-property-prefix}-layer-active,
      map-get($theme, "layer-active")
    ) !global;
    $button-danger-active: var(
      --#{$custom-property-prefix}-button-danger-active,
      map-get($theme, "button-danger-active")
    ) !global;
    $button-primary-active: var(
      --#{$custom-property-prefix}-button-primary-active,
      map-get($theme, "button-primary-active")
    ) !global;
    $button-secondary-active: var(
      --#{$custom-property-prefix}-button-secondary-active,
      map-get($theme, "button-secondary-active")
    ) !global;
    $button-tertiary-active: var(
      --#{$custom-property-prefix}-button-tertiary-active,
      map-get($theme, "button-tertiary-active")
    ) !global;
    $focus-inset: var(
      --#{$custom-property-prefix}-focus-inset,
      map-get($theme, "focus-inset")
    ) !global;
    $focus-inverse: var(
      --#{$custom-property-prefix}-focus-inverse,
      map-get($theme, "focus-inverse")
    ) !global;
    $background-hover: var(
      --#{$custom-property-prefix}-background-hover,
      map-get($theme, "background-hover")
    ) !global;
    $layer-hover: var(
      --#{$custom-property-prefix}-layer-hover,
      map-get($theme, "layer-hover")
    ) !global;
    $field-hover: var(
      --#{$custom-property-prefix}-field-hover,
      map-get($theme, "field-hover")
    ) !global;
    $background-inverse-hover: var(
      --#{$custom-property-prefix}-background-inverse-hover,
      map-get($theme, "background-inverse-hover")
    ) !global;
    $link-primary-hover: var(
      --#{$custom-property-prefix}-link-primary-hover,
      map-get($theme, "link-primary-hover")
    ) !global;
    $button-danger-hover: var(
      --#{$custom-property-prefix}-button-danger-hover,
      map-get($theme, "button-danger-hover")
    ) !global;
    $button-primary-hover: var(
      --#{$custom-property-prefix}-button-primary-hover,
      map-get($theme, "button-primary-hover")
    ) !global;
    $button-secondary-hover: var(
      --#{$custom-property-prefix}-button-secondary-hover,
      map-get($theme, "button-secondary-hover")
    ) !global;
    $button-tertiary-hover: var(
      --#{$custom-property-prefix}-button-tertiary-hover,
      map-get($theme, "button-tertiary-hover")
    ) !global;
    $background-selected: var(
      --#{$custom-property-prefix}-background-selected,
      map-get($theme, "background-selected")
    ) !global;
    $background-selected-hover: var(
      --#{$custom-property-prefix}-background-selected-hover,
      map-get($theme, "background-selected-hover")
    ) !global;
    $layer-selected: var(
      --#{$custom-property-prefix}-layer-selected,
      map-get($theme, "layer-selected")
    ) !global;
    $layer-selected-hover: var(
      --#{$custom-property-prefix}-layer-selected-hover,
      map-get($theme, "layer-selected-hover")
    ) !global;
    $layer-selected-inverse: var(
      --#{$custom-property-prefix}-layer-selected-inverse,
      map-get($theme, "layer-selected-inverse")
    ) !global;
    $border-subtle-selected: var(
      --#{$custom-property-prefix}-border-subtle-selected,
      map-get($theme, "border-subtle-selected")
    ) !global;
    $layer-disabled: var(
      --#{$custom-property-prefix}-layer-disabled,
      map-get($theme, "layer-disabled")
    ) !global;
    $field-disabled: var(
      --#{$custom-property-prefix}-field-disabled,
      map-get($theme, "field-disabled")
    ) !global;
    $border-disabled: var(
      --#{$custom-property-prefix}-border-disabled,
      map-get($theme, "border-disabled")
    ) !global;
    $text-disabled: var(
      --#{$custom-property-prefix}-text-disabled,
      map-get($theme, "text-disabled")
    ) !global;
    $button-disabled: var(
      --#{$custom-property-prefix}-button-disabled,
      map-get($theme, "button-disabled")
    ) !global;
    $icon-disabled: var(
      --#{$custom-property-prefix}-icon-disabled,
      map-get($theme, "icon-disabled")
    ) !global;
    $text-on-color-disabled: var(
      --#{$custom-property-prefix}-text-on-color-disabled,
      map-get($theme, "text-on-color-disabled")
    ) !global;
    $icon-on-color-disabled: var(
      --#{$custom-property-prefix}-icon-on-color-disabled,
      map-get($theme, "icon-on-color-disabled")
    ) !global;
    $layer-selected-disabled: var(
      --#{$custom-property-prefix}-layer-selected-disabled,
      map-get($theme, "layer-selected-disabled")
    ) !global;
    $skeleton-background: var(
      --#{$custom-property-prefix}-skeleton-background,
      map-get($theme, "skeleton-background")
    ) !global;
    $skeleton-element: var(
      --#{$custom-property-prefix}-skeleton-element,
      map-get($theme, "skeleton-element")
    ) !global;
    $brand-01: var(
      --#{$custom-property-prefix}-brand-01,
      map-get($theme, "brand-01")
    ) !global;
    $brand-02: var(
      --#{$custom-property-prefix}-brand-02,
      map-get($theme, "brand-02")
    ) !global;
    $brand-03: var(
      --#{$custom-property-prefix}-brand-03,
      map-get($theme, "brand-03")
    ) !global;
    $active-01: var(
      --#{$custom-property-prefix}-active-01,
      map-get($theme, "active-01")
    ) !global;
    $hover-field: var(
      --#{$custom-property-prefix}-hover-field,
      map-get($theme, "hover-field")
    ) !global;
    $danger: var(
      --#{$custom-property-prefix}-danger,
      map-get($theme, "danger")
    ) !global;
    $corporate-1: var(
      --#{$custom-property-prefix}-corporate-1,
      map-get($theme, "corporate-1")
    ) !global;
    $corporate-2: var(
      --#{$custom-property-prefix}-corporate-2,
      map-get($theme, "corporate-2")
    ) !global;
    $corporate-3: var(
      --#{$custom-property-prefix}-corporate-3,
      map-get($theme, "corporate-3")
    ) !global;
    $corporate-4: var(
      --#{$custom-property-prefix}-corporate-4,
      map-get($theme, "corporate-4")
    ) !global;
    $corporate-5: var(
      --#{$custom-property-prefix}-corporate-5,
      map-get($theme, "corporate-5")
    ) !global;
    $corporate-6: var(
      --#{$custom-property-prefix}-corporate-6,
      map-get($theme, "corporate-6")
    ) !global;
    $corporate-7: var(
      --#{$custom-property-prefix}-corporate-7,
      map-get($theme, "corporate-7")
    ) !global;
    $corporate-8: var(
      --#{$custom-property-prefix}-corporate-8,
      map-get($theme, "corporate-8")
    ) !global;
    $corporate-9: var(
      --#{$custom-property-prefix}-corporate-9,
      map-get($theme, "corporate-9")
    ) !global;
    $corporate-10: var(
      --#{$custom-property-prefix}-corporate-10,
      map-get($theme, "corporate-10")
    ) !global;
    $corporate-11: var(
      --#{$custom-property-prefix}-corporate-11,
      map-get($theme, "corporate-11")
    ) !global;
    $corporate-12: var(
      --#{$custom-property-prefix}-corporate-12,
      map-get($theme, "corporate-12")
    ) !global;
    $brand: var(
      --#{$custom-property-prefix}-brand,
      map-get($theme, "brand")
    ) !global;
    $brand-dark: var(
      --#{$custom-property-prefix}-brand-dark,
      map-get($theme, "brand-dark")
    ) !global;
    $focus-invterse: var(
      --#{$custom-property-prefix}-focus-invterse,
      map-get($theme, "focus-invterse")
    ) !global;
    $-focus-inset: var(
      --#{$custom-property-prefix}--focus-inset,
      map-get($theme, "-focus-inset")
    ) !global;
    $-highlight: var(
      --#{$custom-property-prefix}--highlight,
      map-get($theme, "-highlight")
    ) !global;
    $background-inverse-max: var(
      --#{$custom-property-prefix}-background-inverse-max,
      map-get($theme, "background-inverse-max")
    ) !global;
    $text-warning: var(
      --#{$custom-property-prefix}-text-warning,
      map-get($theme, "text-warning")
    ) !global;
    $text-success: var(
      --#{$custom-property-prefix}-text-success,
      map-get($theme, "text-success")
    ) !global;
    $text-info: var(
      --#{$custom-property-prefix}-text-info,
      map-get($theme, "text-info")
    ) !global;
    $button-ghost-hover: var(
      --#{$custom-property-prefix}-button-ghost-hover,
      map-get($theme, "button-ghost-hover")
    ) !global;
    $button-ghost-active: var(
      --#{$custom-property-prefix}-button-ghost-active,
      map-get($theme, "button-ghost-active")
    ) !global;
    $button-inverse: var(
      --#{$custom-property-prefix}-button-inverse,
      map-get($theme, "button-inverse")
    ) !global;
    $button-inverse-hover: var(
      --#{$custom-property-prefix}-button-inverse-hover,
      map-get($theme, "button-inverse-hover")
    ) !global;
    $button-inverse-active: var(
      --#{$custom-property-prefix}-button-inverse-active,
      map-get($theme, "button-inverse-active")
    ) !global;
    $button-main-navigation: var(
      --#{$custom-property-prefix}-button-main-navigation,
      map-get($theme, "button-main-navigation")
    ) !global;
    $button-main-navigation-hover: var(
      --#{$custom-property-prefix}-button-main-navigation-hover,
      map-get($theme, "button-main-navigation-hover")
    ) !global;
    $link-on-color: var(
      --#{$custom-property-prefix}-link-on-color,
      map-get($theme, "link-on-color")
    ) !global;
    $-field-hover: var(
      --#{$custom-property-prefix}--field-hover,
      map-get($theme, "-field-hover")
    ) !global;
    $field-border: var(
      --#{$custom-property-prefix}-field-border,
      map-get($theme, "field-border")
    ) !global;
    $field-option-hover: var(
      --#{$custom-property-prefix}-field-option-hover,
      map-get($theme, "field-option-hover")
    ) !global;
    $field-option-active: var(
      --#{$custom-property-prefix}-field-option-active,
      map-get($theme, "field-option-active")
    ) !global;
    $icon-tertiary: var(
      --#{$custom-property-prefix}-icon-tertiary,
      map-get($theme, "icon-tertiary")
    ) !global;
    $support-error-light: var(
      --#{$custom-property-prefix}-support-error-light,
      map-get($theme, "support-error-light")
    ) !global;
    $support-success-light: var(
      --#{$custom-property-prefix}-support-success-light,
      map-get($theme, "support-success-light")
    ) !global;
    $support-warning-light: var(
      --#{$custom-property-prefix}-support-warning-light,
      map-get($theme, "support-warning-light")
    ) !global;
    $support-info-light: var(
      --#{$custom-property-prefix}-support-info-light,
      map-get($theme, "support-info-light")
    ) !global;
    $logo-white: var(
      --#{$custom-property-prefix}-logo-white,
      map-get($theme, "logo-white")
    ) !global;
    $logo-black: var(
      --#{$custom-property-prefix}-logo-black,
      map-get($theme, "logo-black")
    ) !global;
    $logo-blue: var(
      --#{$custom-property-prefix}-logo-blue,
      map-get($theme, "logo-blue")
    ) !global;
    $spacing-01: var(
      --#{$custom-property-prefix}-spacing-01,
      map-get($theme, "spacing-01")
    ) !global;
    $spacing-02: var(
      --#{$custom-property-prefix}-spacing-02,
      map-get($theme, "spacing-02")
    ) !global;
    $spacing-03: var(
      --#{$custom-property-prefix}-spacing-03,
      map-get($theme, "spacing-03")
    ) !global;
    $spacing-04: var(
      --#{$custom-property-prefix}-spacing-04,
      map-get($theme, "spacing-04")
    ) !global;
    $spacing-05: var(
      --#{$custom-property-prefix}-spacing-05,
      map-get($theme, "spacing-05")
    ) !global;
    $spacing-06: var(
      --#{$custom-property-prefix}-spacing-06,
      map-get($theme, "spacing-06")
    ) !global;
    $spacing-07: var(
      --#{$custom-property-prefix}-spacing-07,
      map-get($theme, "spacing-07")
    ) !global;
    $spacing-08: var(
      --#{$custom-property-prefix}-spacing-08,
      map-get($theme, "spacing-08")
    ) !global;
    $spacing-09: var(
      --#{$custom-property-prefix}-spacing-09,
      map-get($theme, "spacing-09")
    ) !global;
    $spacing-10: var(
      --#{$custom-property-prefix}-spacing-10,
      map-get($theme, "spacing-10")
    ) !global;
    $spacing-11: var(
      --#{$custom-property-prefix}-spacing-11,
      map-get($theme, "spacing-11")
    ) !global;
    $spacing-12: var(
      --#{$custom-property-prefix}-spacing-12,
      map-get($theme, "spacing-12")
    ) !global;
    $spacing-13: var(
      --#{$custom-property-prefix}-spacing-13,
      map-get($theme, "spacing-13")
    ) !global;
    $fluid-spacing-01: var(
      --#{$custom-property-prefix}-fluid-spacing-01,
      map-get($theme, "fluid-spacing-01")
    ) !global;
    $fluid-spacing-02: var(
      --#{$custom-property-prefix}-fluid-spacing-02,
      map-get($theme, "fluid-spacing-02")
    ) !global;
    $fluid-spacing-03: var(
      --#{$custom-property-prefix}-fluid-spacing-03,
      map-get($theme, "fluid-spacing-03")
    ) !global;
    $fluid-spacing-04: var(
      --#{$custom-property-prefix}-fluid-spacing-04,
      map-get($theme, "fluid-spacing-04")
    ) !global;
    $layout-01: var(
      --#{$custom-property-prefix}-layout-01,
      map-get($theme, "layout-01")
    ) !global;
    $layout-02: var(
      --#{$custom-property-prefix}-layout-02,
      map-get($theme, "layout-02")
    ) !global;
    $layout-03: var(
      --#{$custom-property-prefix}-layout-03,
      map-get($theme, "layout-03")
    ) !global;
    $layout-04: var(
      --#{$custom-property-prefix}-layout-04,
      map-get($theme, "layout-04")
    ) !global;
    $layout-05: var(
      --#{$custom-property-prefix}-layout-05,
      map-get($theme, "layout-05")
    ) !global;
    $layout-06: var(
      --#{$custom-property-prefix}-layout-06,
      map-get($theme, "layout-06")
    ) !global;
    $layout-07: var(
      --#{$custom-property-prefix}-layout-07,
      map-get($theme, "layout-07")
    ) !global;
    $container-01: var(
      --#{$custom-property-prefix}-container-01,
      map-get($theme, "container-01")
    ) !global;
    $container-02: var(
      --#{$custom-property-prefix}-container-02,
      map-get($theme, "container-02")
    ) !global;
    $container-03: var(
      --#{$custom-property-prefix}-container-03,
      map-get($theme, "container-03")
    ) !global;
    $container-04: var(
      --#{$custom-property-prefix}-container-04,
      map-get($theme, "container-04")
    ) !global;
    $container-05: var(
      --#{$custom-property-prefix}-container-05,
      map-get($theme, "container-05")
    ) !global;
    $size-xsmall: var(
      --#{$custom-property-prefix}-size-xsmall,
      map-get($theme, "size-xsmall")
    ) !global;
    $size-small: var(
      --#{$custom-property-prefix}-size-small,
      map-get($theme, "size-small")
    ) !global;
    $size-medium: var(
      --#{$custom-property-prefix}-size-medium,
      map-get($theme, "size-medium")
    ) !global;
    $size-large: var(
      --#{$custom-property-prefix}-size-large,
      map-get($theme, "size-large")
    ) !global;
    $size-xlarge: var(
      --#{$custom-property-prefix}-size-xlarge,
      map-get($theme, "size-xlarge")
    ) !global;
    $size-2XLarge: var(
      --#{$custom-property-prefix}-size-2XLarge,
      map-get($theme, "size-2XLarge")
    ) !global;
    $icon-size-01: var(
      --#{$custom-property-prefix}-icon-size-01,
      map-get($theme, "icon-size-01")
    ) !global;
    $icon-size-02: var(
      --#{$custom-property-prefix}-icon-size-02,
      map-get($theme, "icon-size-02")
    ) !global;
  }
  @if $emit-custom-properties == true {
    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "interactive-01",
      $emit-difference
    )
    {
      @include custom-property(
        "interactive-01",
        map-get($theme, "interactive-01")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "interactive-02",
      $emit-difference
    )
    {
      @include custom-property(
        "interactive-02",
        map-get($theme, "interactive-02")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "interactive-03",
      $emit-difference
    )
    {
      @include custom-property(
        "interactive-03",
        map-get($theme, "interactive-03")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "interactive-04",
      $emit-difference
    )
    {
      @include custom-property(
        "interactive-04",
        map-get($theme, "interactive-04")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "ui-background",
      $emit-difference
    )
    {
      @include custom-property(
        "ui-background",
        map-get($theme, "ui-background")
      );
    }

    @if should-emit($theme, $parent-carbon-theme, "ui-01", $emit-difference) {
      @include custom-property("ui-01", map-get($theme, "ui-01"));
    }

    @if should-emit($theme, $parent-carbon-theme, "ui-02", $emit-difference) {
      @include custom-property("ui-02", map-get($theme, "ui-02"));
    }

    @if should-emit($theme, $parent-carbon-theme, "ui-03", $emit-difference) {
      @include custom-property("ui-03", map-get($theme, "ui-03"));
    }

    @if should-emit($theme, $parent-carbon-theme, "ui-04", $emit-difference) {
      @include custom-property("ui-04", map-get($theme, "ui-04"));
    }

    @if should-emit($theme, $parent-carbon-theme, "ui-05", $emit-difference) {
      @include custom-property("ui-05", map-get($theme, "ui-05"));
    }

    @if should-emit($theme, $parent-carbon-theme, "text-01", $emit-difference) {
      @include custom-property("text-01", map-get($theme, "text-01"));
    }

    @if should-emit($theme, $parent-carbon-theme, "text-02", $emit-difference) {
      @include custom-property("text-02", map-get($theme, "text-02"));
    }

    @if should-emit($theme, $parent-carbon-theme, "text-03", $emit-difference) {
      @include custom-property("text-03", map-get($theme, "text-03"));
    }

    @if should-emit($theme, $parent-carbon-theme, "text-04", $emit-difference) {
      @include custom-property("text-04", map-get($theme, "text-04"));
    }

    @if should-emit($theme, $parent-carbon-theme, "text-05", $emit-difference) {
      @include custom-property("text-05", map-get($theme, "text-05"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "text-error",
      $emit-difference
    )
    {
      @include custom-property("text-error", map-get($theme, "text-error"));
    }

    @if should-emit($theme, $parent-carbon-theme, "icon-01", $emit-difference) {
      @include custom-property("icon-01", map-get($theme, "icon-01"));
    }

    @if should-emit($theme, $parent-carbon-theme, "icon-02", $emit-difference) {
      @include custom-property("icon-02", map-get($theme, "icon-02"));
    }

    @if should-emit($theme, $parent-carbon-theme, "icon-03", $emit-difference) {
      @include custom-property("icon-03", map-get($theme, "icon-03"));
    }

    @if should-emit($theme, $parent-carbon-theme, "link-01", $emit-difference) {
      @include custom-property("link-01", map-get($theme, "link-01"));
    }

    @if should-emit($theme, $parent-carbon-theme, "link-02", $emit-difference) {
      @include custom-property("link-02", map-get($theme, "link-02"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "inverse-link",
      $emit-difference
    )
    {
      @include custom-property("inverse-link", map-get($theme, "inverse-link"));
    }

    @if should-emit($theme, $parent-carbon-theme, "field-01", $emit-difference)
    {
      @include custom-property("field-01", map-get($theme, "field-01"));
    }

    @if should-emit($theme, $parent-carbon-theme, "field-02", $emit-difference)
    {
      @include custom-property("field-02", map-get($theme, "field-02"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "inverse-01",
      $emit-difference
    )
    {
      @include custom-property("inverse-01", map-get($theme, "inverse-01"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "inverse-02",
      $emit-difference
    )
    {
      @include custom-property("inverse-02", map-get($theme, "inverse-02"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "support-01",
      $emit-difference
    )
    {
      @include custom-property("support-01", map-get($theme, "support-01"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "support-02",
      $emit-difference
    )
    {
      @include custom-property("support-02", map-get($theme, "support-02"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "support-03",
      $emit-difference
    )
    {
      @include custom-property("support-03", map-get($theme, "support-03"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "support-04",
      $emit-difference
    )
    {
      @include custom-property("support-04", map-get($theme, "support-04"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "inverse-support-01",
      $emit-difference
    )
    {
      @include custom-property(
        "inverse-support-01",
        map-get($theme, "inverse-support-01")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "inverse-support-02",
      $emit-difference
    )
    {
      @include custom-property(
        "inverse-support-02",
        map-get($theme, "inverse-support-02")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "inverse-support-03",
      $emit-difference
    )
    {
      @include custom-property(
        "inverse-support-03",
        map-get($theme, "inverse-support-03")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "inverse-support-04",
      $emit-difference
    )
    {
      @include custom-property(
        "inverse-support-04",
        map-get($theme, "inverse-support-04")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "overlay-01",
      $emit-difference
    )
    {
      @include custom-property("overlay-01", map-get($theme, "overlay-01"));
    }

    @if should-emit($theme, $parent-carbon-theme, "danger-01", $emit-difference)
    {
      @include custom-property("danger-01", map-get($theme, "danger-01"));
    }

    @if should-emit($theme, $parent-carbon-theme, "danger-02", $emit-difference)
    {
      @include custom-property("danger-02", map-get($theme, "danger-02"));
    }

    @if should-emit($theme, $parent-carbon-theme, "focus", $emit-difference) {
      @include custom-property("focus", map-get($theme, "focus"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "inverse-focus-ui",
      $emit-difference
    )
    {
      @include custom-property(
        "inverse-focus-ui",
        map-get($theme, "inverse-focus-ui")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "hover-primary",
      $emit-difference
    )
    {
      @include custom-property(
        "hover-primary",
        map-get($theme, "hover-primary")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "active-primary",
      $emit-difference
    )
    {
      @include custom-property(
        "active-primary",
        map-get($theme, "active-primary")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "hover-primary-text",
      $emit-difference
    )
    {
      @include custom-property(
        "hover-primary-text",
        map-get($theme, "hover-primary-text")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "hover-secondary",
      $emit-difference
    )
    {
      @include custom-property(
        "hover-secondary",
        map-get($theme, "hover-secondary")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "active-secondary",
      $emit-difference
    )
    {
      @include custom-property(
        "active-secondary",
        map-get($theme, "active-secondary")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "hover-tertiary",
      $emit-difference
    )
    {
      @include custom-property(
        "hover-tertiary",
        map-get($theme, "hover-tertiary")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "active-tertiary",
      $emit-difference
    )
    {
      @include custom-property(
        "active-tertiary",
        map-get($theme, "active-tertiary")
      );
    }

    @if should-emit($theme, $parent-carbon-theme, "hover-ui", $emit-difference)
    {
      @include custom-property("hover-ui", map-get($theme, "hover-ui"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "hover-light-ui",
      $emit-difference
    )
    {
      @include custom-property(
        "hover-light-ui",
        map-get($theme, "hover-light-ui")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "hover-selected-ui",
      $emit-difference
    )
    {
      @include custom-property(
        "hover-selected-ui",
        map-get($theme, "hover-selected-ui")
      );
    }

    @if should-emit($theme, $parent-carbon-theme, "active-ui", $emit-difference)
    {
      @include custom-property("active-ui", map-get($theme, "active-ui"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "active-light-ui",
      $emit-difference
    )
    {
      @include custom-property(
        "active-light-ui",
        map-get($theme, "active-light-ui")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "selected-ui",
      $emit-difference
    )
    {
      @include custom-property("selected-ui", map-get($theme, "selected-ui"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "selected-light-ui",
      $emit-difference
    )
    {
      @include custom-property(
        "selected-light-ui",
        map-get($theme, "selected-light-ui")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "inverse-hover-ui",
      $emit-difference
    )
    {
      @include custom-property(
        "inverse-hover-ui",
        map-get($theme, "inverse-hover-ui")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "hover-danger",
      $emit-difference
    )
    {
      @include custom-property("hover-danger", map-get($theme, "hover-danger"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "active-danger",
      $emit-difference
    )
    {
      @include custom-property(
        "active-danger",
        map-get($theme, "active-danger")
      );
    }

    @if should-emit($theme, $parent-carbon-theme, "hover-row", $emit-difference)
    {
      @include custom-property("hover-row", map-get($theme, "hover-row"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "visited-link",
      $emit-difference
    )
    {
      @include custom-property("visited-link", map-get($theme, "visited-link"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "disabled-01",
      $emit-difference
    )
    {
      @include custom-property("disabled-01", map-get($theme, "disabled-01"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "disabled-02",
      $emit-difference
    )
    {
      @include custom-property("disabled-02", map-get($theme, "disabled-02"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "disabled-03",
      $emit-difference
    )
    {
      @include custom-property("disabled-03", map-get($theme, "disabled-03"));
    }

    @if should-emit($theme, $parent-carbon-theme, "highlight", $emit-difference)
    {
      @include custom-property("highlight", map-get($theme, "highlight"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "decorative-01",
      $emit-difference
    )
    {
      @include custom-property(
        "decorative-01",
        map-get($theme, "decorative-01")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-separator",
      $emit-difference
    )
    {
      @include custom-property(
        "button-separator",
        map-get($theme, "button-separator")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "skeleton-01",
      $emit-difference
    )
    {
      @include custom-property("skeleton-01", map-get($theme, "skeleton-01"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "skeleton-02",
      $emit-difference
    )
    {
      @include custom-property("skeleton-02", map-get($theme, "skeleton-02"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "background",
      $emit-difference
    )
    {
      @include custom-property("background", map-get($theme, "background"));
    }

    @if should-emit($theme, $parent-carbon-theme, "layer", $emit-difference) {
      @include custom-property("layer", map-get($theme, "layer"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "layer-accent",
      $emit-difference
    )
    {
      @include custom-property("layer-accent", map-get($theme, "layer-accent"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "layer-accent-hover",
      $emit-difference
    )
    {
      @include custom-property(
        "layer-accent-hover",
        map-get($theme, "layer-accent-hover")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "layer-accent-active",
      $emit-difference
    )
    {
      @include custom-property(
        "layer-accent-active",
        map-get($theme, "layer-accent-active")
      );
    }

    @if should-emit($theme, $parent-carbon-theme, "field", $emit-difference) {
      @include custom-property("field", map-get($theme, "field"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "background-inverse",
      $emit-difference
    )
    {
      @include custom-property(
        "background-inverse",
        map-get($theme, "background-inverse")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "background-brand",
      $emit-difference
    )
    {
      @include custom-property(
        "background-brand",
        map-get($theme, "background-brand")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "interactive",
      $emit-difference
    )
    {
      @include custom-property("interactive", map-get($theme, "interactive"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "border-subtle",
      $emit-difference
    )
    {
      @include custom-property(
        "border-subtle",
        map-get($theme, "border-subtle")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "border-strong",
      $emit-difference
    )
    {
      @include custom-property(
        "border-strong",
        map-get($theme, "border-strong")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "border-inverse",
      $emit-difference
    )
    {
      @include custom-property(
        "border-inverse",
        map-get($theme, "border-inverse")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "border-interactive",
      $emit-difference
    )
    {
      @include custom-property(
        "border-interactive",
        map-get($theme, "border-interactive")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "text-primary",
      $emit-difference
    )
    {
      @include custom-property("text-primary", map-get($theme, "text-primary"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "text-secondary",
      $emit-difference
    )
    {
      @include custom-property(
        "text-secondary",
        map-get($theme, "text-secondary")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "text-placeholder",
      $emit-difference
    )
    {
      @include custom-property(
        "text-placeholder",
        map-get($theme, "text-placeholder")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "text-helper",
      $emit-difference
    )
    {
      @include custom-property("text-helper", map-get($theme, "text-helper"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "text-on-color",
      $emit-difference
    )
    {
      @include custom-property(
        "text-on-color",
        map-get($theme, "text-on-color")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "text-inverse",
      $emit-difference
    )
    {
      @include custom-property("text-inverse", map-get($theme, "text-inverse"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "link-primary",
      $emit-difference
    )
    {
      @include custom-property("link-primary", map-get($theme, "link-primary"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "link-secondary",
      $emit-difference
    )
    {
      @include custom-property(
        "link-secondary",
        map-get($theme, "link-secondary")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "link-visited",
      $emit-difference
    )
    {
      @include custom-property("link-visited", map-get($theme, "link-visited"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "link-inverse",
      $emit-difference
    )
    {
      @include custom-property("link-inverse", map-get($theme, "link-inverse"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "icon-primary",
      $emit-difference
    )
    {
      @include custom-property("icon-primary", map-get($theme, "icon-primary"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "icon-secondary",
      $emit-difference
    )
    {
      @include custom-property(
        "icon-secondary",
        map-get($theme, "icon-secondary")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "icon-on-color",
      $emit-difference
    )
    {
      @include custom-property(
        "icon-on-color",
        map-get($theme, "icon-on-color")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "icon-inverse",
      $emit-difference
    )
    {
      @include custom-property("icon-inverse", map-get($theme, "icon-inverse"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "support-error",
      $emit-difference
    )
    {
      @include custom-property(
        "support-error",
        map-get($theme, "support-error")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "support-success",
      $emit-difference
    )
    {
      @include custom-property(
        "support-success",
        map-get($theme, "support-success")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "support-warning",
      $emit-difference
    )
    {
      @include custom-property(
        "support-warning",
        map-get($theme, "support-warning")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "support-info",
      $emit-difference
    )
    {
      @include custom-property("support-info", map-get($theme, "support-info"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "support-error-inverse",
      $emit-difference
    )
    {
      @include custom-property(
        "support-error-inverse",
        map-get($theme, "support-error-inverse")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "support-success-inverse",
      $emit-difference
    )
    {
      @include custom-property(
        "support-success-inverse",
        map-get($theme, "support-success-inverse")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "support-warning-inverse",
      $emit-difference
    )
    {
      @include custom-property(
        "support-warning-inverse",
        map-get($theme, "support-warning-inverse")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "support-info-inverse",
      $emit-difference
    )
    {
      @include custom-property(
        "support-info-inverse",
        map-get($theme, "support-info-inverse")
      );
    }

    @if should-emit($theme, $parent-carbon-theme, "overlay", $emit-difference) {
      @include custom-property("overlay", map-get($theme, "overlay"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "toggle-off",
      $emit-difference
    )
    {
      @include custom-property("toggle-off", map-get($theme, "toggle-off"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-primary",
      $emit-difference
    )
    {
      @include custom-property(
        "button-primary",
        map-get($theme, "button-primary")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-secondary",
      $emit-difference
    )
    {
      @include custom-property(
        "button-secondary",
        map-get($theme, "button-secondary")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-tertiary",
      $emit-difference
    )
    {
      @include custom-property(
        "button-tertiary",
        map-get($theme, "button-tertiary")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-danger-primary",
      $emit-difference
    )
    {
      @include custom-property(
        "button-danger-primary",
        map-get($theme, "button-danger-primary")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-danger-secondary",
      $emit-difference
    )
    {
      @include custom-property(
        "button-danger-secondary",
        map-get($theme, "button-danger-secondary")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "background-active",
      $emit-difference
    )
    {
      @include custom-property(
        "background-active",
        map-get($theme, "background-active")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "layer-active",
      $emit-difference
    )
    {
      @include custom-property("layer-active", map-get($theme, "layer-active"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-danger-active",
      $emit-difference
    )
    {
      @include custom-property(
        "button-danger-active",
        map-get($theme, "button-danger-active")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-primary-active",
      $emit-difference
    )
    {
      @include custom-property(
        "button-primary-active",
        map-get($theme, "button-primary-active")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-secondary-active",
      $emit-difference
    )
    {
      @include custom-property(
        "button-secondary-active",
        map-get($theme, "button-secondary-active")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-tertiary-active",
      $emit-difference
    )
    {
      @include custom-property(
        "button-tertiary-active",
        map-get($theme, "button-tertiary-active")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "focus-inset",
      $emit-difference
    )
    {
      @include custom-property("focus-inset", map-get($theme, "focus-inset"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "focus-inverse",
      $emit-difference
    )
    {
      @include custom-property(
        "focus-inverse",
        map-get($theme, "focus-inverse")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "background-hover",
      $emit-difference
    )
    {
      @include custom-property(
        "background-hover",
        map-get($theme, "background-hover")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "layer-hover",
      $emit-difference
    )
    {
      @include custom-property("layer-hover", map-get($theme, "layer-hover"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "field-hover",
      $emit-difference
    )
    {
      @include custom-property("field-hover", map-get($theme, "field-hover"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "background-inverse-hover",
      $emit-difference
    )
    {
      @include custom-property(
        "background-inverse-hover",
        map-get($theme, "background-inverse-hover")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "link-primary-hover",
      $emit-difference
    )
    {
      @include custom-property(
        "link-primary-hover",
        map-get($theme, "link-primary-hover")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-danger-hover",
      $emit-difference
    )
    {
      @include custom-property(
        "button-danger-hover",
        map-get($theme, "button-danger-hover")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-primary-hover",
      $emit-difference
    )
    {
      @include custom-property(
        "button-primary-hover",
        map-get($theme, "button-primary-hover")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-secondary-hover",
      $emit-difference
    )
    {
      @include custom-property(
        "button-secondary-hover",
        map-get($theme, "button-secondary-hover")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-tertiary-hover",
      $emit-difference
    )
    {
      @include custom-property(
        "button-tertiary-hover",
        map-get($theme, "button-tertiary-hover")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "background-selected",
      $emit-difference
    )
    {
      @include custom-property(
        "background-selected",
        map-get($theme, "background-selected")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "background-selected-hover",
      $emit-difference
    )
    {
      @include custom-property(
        "background-selected-hover",
        map-get($theme, "background-selected-hover")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "layer-selected",
      $emit-difference
    )
    {
      @include custom-property(
        "layer-selected",
        map-get($theme, "layer-selected")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "layer-selected-hover",
      $emit-difference
    )
    {
      @include custom-property(
        "layer-selected-hover",
        map-get($theme, "layer-selected-hover")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "layer-selected-inverse",
      $emit-difference
    )
    {
      @include custom-property(
        "layer-selected-inverse",
        map-get($theme, "layer-selected-inverse")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "border-subtle-selected",
      $emit-difference
    )
    {
      @include custom-property(
        "border-subtle-selected",
        map-get($theme, "border-subtle-selected")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "layer-disabled",
      $emit-difference
    )
    {
      @include custom-property(
        "layer-disabled",
        map-get($theme, "layer-disabled")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "field-disabled",
      $emit-difference
    )
    {
      @include custom-property(
        "field-disabled",
        map-get($theme, "field-disabled")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "border-disabled",
      $emit-difference
    )
    {
      @include custom-property(
        "border-disabled",
        map-get($theme, "border-disabled")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "text-disabled",
      $emit-difference
    )
    {
      @include custom-property(
        "text-disabled",
        map-get($theme, "text-disabled")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-disabled",
      $emit-difference
    )
    {
      @include custom-property(
        "button-disabled",
        map-get($theme, "button-disabled")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "icon-disabled",
      $emit-difference
    )
    {
      @include custom-property(
        "icon-disabled",
        map-get($theme, "icon-disabled")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "text-on-color-disabled",
      $emit-difference
    )
    {
      @include custom-property(
        "text-on-color-disabled",
        map-get($theme, "text-on-color-disabled")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "icon-on-color-disabled",
      $emit-difference
    )
    {
      @include custom-property(
        "icon-on-color-disabled",
        map-get($theme, "icon-on-color-disabled")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "layer-selected-disabled",
      $emit-difference
    )
    {
      @include custom-property(
        "layer-selected-disabled",
        map-get($theme, "layer-selected-disabled")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "skeleton-background",
      $emit-difference
    )
    {
      @include custom-property(
        "skeleton-background",
        map-get($theme, "skeleton-background")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "skeleton-element",
      $emit-difference
    )
    {
      @include custom-property(
        "skeleton-element",
        map-get($theme, "skeleton-element")
      );
    }

    @if should-emit($theme, $parent-carbon-theme, "brand-01", $emit-difference)
    {
      @include custom-property("brand-01", map-get($theme, "brand-01"));
    }

    @if should-emit($theme, $parent-carbon-theme, "brand-02", $emit-difference)
    {
      @include custom-property("brand-02", map-get($theme, "brand-02"));
    }

    @if should-emit($theme, $parent-carbon-theme, "brand-03", $emit-difference)
    {
      @include custom-property("brand-03", map-get($theme, "brand-03"));
    }

    @if should-emit($theme, $parent-carbon-theme, "active-01", $emit-difference)
    {
      @include custom-property("active-01", map-get($theme, "active-01"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "hover-field",
      $emit-difference
    )
    {
      @include custom-property("hover-field", map-get($theme, "hover-field"));
    }

    @if should-emit($theme, $parent-carbon-theme, "danger", $emit-difference) {
      @include custom-property("danger", map-get($theme, "danger"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "corporate-1",
      $emit-difference
    )
    {
      @include custom-property("corporate-1", map-get($theme, "corporate-1"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "corporate-2",
      $emit-difference
    )
    {
      @include custom-property("corporate-2", map-get($theme, "corporate-2"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "corporate-3",
      $emit-difference
    )
    {
      @include custom-property("corporate-3", map-get($theme, "corporate-3"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "corporate-4",
      $emit-difference
    )
    {
      @include custom-property("corporate-4", map-get($theme, "corporate-4"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "corporate-5",
      $emit-difference
    )
    {
      @include custom-property("corporate-5", map-get($theme, "corporate-5"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "corporate-6",
      $emit-difference
    )
    {
      @include custom-property("corporate-6", map-get($theme, "corporate-6"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "corporate-7",
      $emit-difference
    )
    {
      @include custom-property("corporate-7", map-get($theme, "corporate-7"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "corporate-8",
      $emit-difference
    )
    {
      @include custom-property("corporate-8", map-get($theme, "corporate-8"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "corporate-9",
      $emit-difference
    )
    {
      @include custom-property("corporate-9", map-get($theme, "corporate-9"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "corporate-10",
      $emit-difference
    )
    {
      @include custom-property("corporate-10", map-get($theme, "corporate-10"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "corporate-11",
      $emit-difference
    )
    {
      @include custom-property("corporate-11", map-get($theme, "corporate-11"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "corporate-12",
      $emit-difference
    )
    {
      @include custom-property("corporate-12", map-get($theme, "corporate-12"));
    }

    @if should-emit($theme, $parent-carbon-theme, "brand", $emit-difference) {
      @include custom-property("brand", map-get($theme, "brand"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "brand-dark",
      $emit-difference
    )
    {
      @include custom-property("brand-dark", map-get($theme, "brand-dark"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "focus-invterse",
      $emit-difference
    )
    {
      @include custom-property(
        "focus-invterse",
        map-get($theme, "focus-invterse")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "-focus-inset",
      $emit-difference
    )
    {
      @include custom-property("-focus-inset", map-get($theme, "-focus-inset"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "-highlight",
      $emit-difference
    )
    {
      @include custom-property("-highlight", map-get($theme, "-highlight"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "background-inverse-max",
      $emit-difference
    )
    {
      @include custom-property(
        "background-inverse-max",
        map-get($theme, "background-inverse-max")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "text-warning",
      $emit-difference
    )
    {
      @include custom-property("text-warning", map-get($theme, "text-warning"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "text-success",
      $emit-difference
    )
    {
      @include custom-property("text-success", map-get($theme, "text-success"));
    }

    @if should-emit($theme, $parent-carbon-theme, "text-info", $emit-difference)
    {
      @include custom-property("text-info", map-get($theme, "text-info"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-ghost-hover",
      $emit-difference
    )
    {
      @include custom-property(
        "button-ghost-hover",
        map-get($theme, "button-ghost-hover")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-ghost-active",
      $emit-difference
    )
    {
      @include custom-property(
        "button-ghost-active",
        map-get($theme, "button-ghost-active")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-inverse",
      $emit-difference
    )
    {
      @include custom-property(
        "button-inverse",
        map-get($theme, "button-inverse")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-inverse-hover",
      $emit-difference
    )
    {
      @include custom-property(
        "button-inverse-hover",
        map-get($theme, "button-inverse-hover")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-inverse-active",
      $emit-difference
    )
    {
      @include custom-property(
        "button-inverse-active",
        map-get($theme, "button-inverse-active")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-main-navigation",
      $emit-difference
    )
    {
      @include custom-property(
        "button-main-navigation",
        map-get($theme, "button-main-navigation")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "button-main-navigation-hover",
      $emit-difference
    )
    {
      @include custom-property(
        "button-main-navigation-hover",
        map-get($theme, "button-main-navigation-hover")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "link-on-color",
      $emit-difference
    )
    {
      @include custom-property(
        "link-on-color",
        map-get($theme, "link-on-color")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "-field-hover",
      $emit-difference
    )
    {
      @include custom-property("-field-hover", map-get($theme, "-field-hover"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "field-border",
      $emit-difference
    )
    {
      @include custom-property("field-border", map-get($theme, "field-border"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "field-option-hover",
      $emit-difference
    )
    {
      @include custom-property(
        "field-option-hover",
        map-get($theme, "field-option-hover")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "field-option-active",
      $emit-difference
    )
    {
      @include custom-property(
        "field-option-active",
        map-get($theme, "field-option-active")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "icon-tertiary",
      $emit-difference
    )
    {
      @include custom-property(
        "icon-tertiary",
        map-get($theme, "icon-tertiary")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "support-error-light",
      $emit-difference
    )
    {
      @include custom-property(
        "support-error-light",
        map-get($theme, "support-error-light")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "support-success-light",
      $emit-difference
    )
    {
      @include custom-property(
        "support-success-light",
        map-get($theme, "support-success-light")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "support-warning-light",
      $emit-difference
    )
    {
      @include custom-property(
        "support-warning-light",
        map-get($theme, "support-warning-light")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "support-info-light",
      $emit-difference
    )
    {
      @include custom-property(
        "support-info-light",
        map-get($theme, "support-info-light")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "logo-white",
      $emit-difference
    )
    {
      @include custom-property("logo-white", map-get($theme, "logo-white"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "logo-black",
      $emit-difference
    )
    {
      @include custom-property("logo-black", map-get($theme, "logo-black"));
    }

    @if should-emit($theme, $parent-carbon-theme, "logo-blue", $emit-difference)
    {
      @include custom-property("logo-blue", map-get($theme, "logo-blue"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "caption-01",
      $emit-difference
    )
    {
      @include custom-property("caption-01", map-get($theme, "caption-01"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "caption-02",
      $emit-difference
    )
    {
      @include custom-property("caption-02", map-get($theme, "caption-02"));
    }

    @if should-emit($theme, $parent-carbon-theme, "label-01", $emit-difference)
    {
      @include custom-property("label-01", map-get($theme, "label-01"));
    }

    @if should-emit($theme, $parent-carbon-theme, "label-02", $emit-difference)
    {
      @include custom-property("label-02", map-get($theme, "label-02"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "helper-text-01",
      $emit-difference
    )
    {
      @include custom-property(
        "helper-text-01",
        map-get($theme, "helper-text-01")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "helper-text-02",
      $emit-difference
    )
    {
      @include custom-property(
        "helper-text-02",
        map-get($theme, "helper-text-02")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "body-short-01",
      $emit-difference
    )
    {
      @include custom-property(
        "body-short-01",
        map-get($theme, "body-short-01")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "body-long-01",
      $emit-difference
    )
    {
      @include custom-property("body-long-01", map-get($theme, "body-long-01"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "body-short-02",
      $emit-difference
    )
    {
      @include custom-property(
        "body-short-02",
        map-get($theme, "body-short-02")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "body-long-02",
      $emit-difference
    )
    {
      @include custom-property("body-long-02", map-get($theme, "body-long-02"));
    }

    @if should-emit($theme, $parent-carbon-theme, "code-01", $emit-difference) {
      @include custom-property("code-01", map-get($theme, "code-01"));
    }

    @if should-emit($theme, $parent-carbon-theme, "code-02", $emit-difference) {
      @include custom-property("code-02", map-get($theme, "code-02"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "heading-01",
      $emit-difference
    )
    {
      @include custom-property("heading-01", map-get($theme, "heading-01"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "heading-02",
      $emit-difference
    )
    {
      @include custom-property("heading-02", map-get($theme, "heading-02"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "productive-heading-01",
      $emit-difference
    )
    {
      @include custom-property(
        "productive-heading-01",
        map-get($theme, "productive-heading-01")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "productive-heading-02",
      $emit-difference
    )
    {
      @include custom-property(
        "productive-heading-02",
        map-get($theme, "productive-heading-02")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "productive-heading-03",
      $emit-difference
    )
    {
      @include custom-property(
        "productive-heading-03",
        map-get($theme, "productive-heading-03")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "productive-heading-04",
      $emit-difference
    )
    {
      @include custom-property(
        "productive-heading-04",
        map-get($theme, "productive-heading-04")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "productive-heading-05",
      $emit-difference
    )
    {
      @include custom-property(
        "productive-heading-05",
        map-get($theme, "productive-heading-05")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "productive-heading-06",
      $emit-difference
    )
    {
      @include custom-property(
        "productive-heading-06",
        map-get($theme, "productive-heading-06")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "productive-heading-07",
      $emit-difference
    )
    {
      @include custom-property(
        "productive-heading-07",
        map-get($theme, "productive-heading-07")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "expressive-heading-01",
      $emit-difference
    )
    {
      @include custom-property(
        "expressive-heading-01",
        map-get($theme, "expressive-heading-01")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "expressive-heading-02",
      $emit-difference
    )
    {
      @include custom-property(
        "expressive-heading-02",
        map-get($theme, "expressive-heading-02")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "expressive-heading-03",
      $emit-difference
    )
    {
      @include custom-property(
        "expressive-heading-03",
        map-get($theme, "expressive-heading-03")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "expressive-heading-04",
      $emit-difference
    )
    {
      @include custom-property(
        "expressive-heading-04",
        map-get($theme, "expressive-heading-04")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "expressive-heading-05",
      $emit-difference
    )
    {
      @include custom-property(
        "expressive-heading-05",
        map-get($theme, "expressive-heading-05")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "expressive-heading-06",
      $emit-difference
    )
    {
      @include custom-property(
        "expressive-heading-06",
        map-get($theme, "expressive-heading-06")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "expressive-paragraph-01",
      $emit-difference
    )
    {
      @include custom-property(
        "expressive-paragraph-01",
        map-get($theme, "expressive-paragraph-01")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "quotation-01",
      $emit-difference
    )
    {
      @include custom-property("quotation-01", map-get($theme, "quotation-01"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "quotation-02",
      $emit-difference
    )
    {
      @include custom-property("quotation-02", map-get($theme, "quotation-02"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "display-01",
      $emit-difference
    )
    {
      @include custom-property("display-01", map-get($theme, "display-01"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "display-02",
      $emit-difference
    )
    {
      @include custom-property("display-02", map-get($theme, "display-02"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "display-03",
      $emit-difference
    )
    {
      @include custom-property("display-03", map-get($theme, "display-03"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "display-04",
      $emit-difference
    )
    {
      @include custom-property("display-04", map-get($theme, "display-04"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "spacing-01",
      $emit-difference
    )
    {
      @include custom-property("spacing-01", map-get($theme, "spacing-01"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "spacing-02",
      $emit-difference
    )
    {
      @include custom-property("spacing-02", map-get($theme, "spacing-02"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "spacing-03",
      $emit-difference
    )
    {
      @include custom-property("spacing-03", map-get($theme, "spacing-03"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "spacing-04",
      $emit-difference
    )
    {
      @include custom-property("spacing-04", map-get($theme, "spacing-04"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "spacing-05",
      $emit-difference
    )
    {
      @include custom-property("spacing-05", map-get($theme, "spacing-05"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "spacing-06",
      $emit-difference
    )
    {
      @include custom-property("spacing-06", map-get($theme, "spacing-06"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "spacing-07",
      $emit-difference
    )
    {
      @include custom-property("spacing-07", map-get($theme, "spacing-07"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "spacing-08",
      $emit-difference
    )
    {
      @include custom-property("spacing-08", map-get($theme, "spacing-08"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "spacing-09",
      $emit-difference
    )
    {
      @include custom-property("spacing-09", map-get($theme, "spacing-09"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "spacing-10",
      $emit-difference
    )
    {
      @include custom-property("spacing-10", map-get($theme, "spacing-10"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "spacing-11",
      $emit-difference
    )
    {
      @include custom-property("spacing-11", map-get($theme, "spacing-11"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "spacing-12",
      $emit-difference
    )
    {
      @include custom-property("spacing-12", map-get($theme, "spacing-12"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "spacing-13",
      $emit-difference
    )
    {
      @include custom-property("spacing-13", map-get($theme, "spacing-13"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "fluid-spacing-01",
      $emit-difference
    )
    {
      @include custom-property(
        "fluid-spacing-01",
        map-get($theme, "fluid-spacing-01")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "fluid-spacing-02",
      $emit-difference
    )
    {
      @include custom-property(
        "fluid-spacing-02",
        map-get($theme, "fluid-spacing-02")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "fluid-spacing-03",
      $emit-difference
    )
    {
      @include custom-property(
        "fluid-spacing-03",
        map-get($theme, "fluid-spacing-03")
      );
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "fluid-spacing-04",
      $emit-difference
    )
    {
      @include custom-property(
        "fluid-spacing-04",
        map-get($theme, "fluid-spacing-04")
      );
    }

    @if should-emit($theme, $parent-carbon-theme, "layout-01", $emit-difference)
    {
      @include custom-property("layout-01", map-get($theme, "layout-01"));
    }

    @if should-emit($theme, $parent-carbon-theme, "layout-02", $emit-difference)
    {
      @include custom-property("layout-02", map-get($theme, "layout-02"));
    }

    @if should-emit($theme, $parent-carbon-theme, "layout-03", $emit-difference)
    {
      @include custom-property("layout-03", map-get($theme, "layout-03"));
    }

    @if should-emit($theme, $parent-carbon-theme, "layout-04", $emit-difference)
    {
      @include custom-property("layout-04", map-get($theme, "layout-04"));
    }

    @if should-emit($theme, $parent-carbon-theme, "layout-05", $emit-difference)
    {
      @include custom-property("layout-05", map-get($theme, "layout-05"));
    }

    @if should-emit($theme, $parent-carbon-theme, "layout-06", $emit-difference)
    {
      @include custom-property("layout-06", map-get($theme, "layout-06"));
    }

    @if should-emit($theme, $parent-carbon-theme, "layout-07", $emit-difference)
    {
      @include custom-property("layout-07", map-get($theme, "layout-07"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "container-01",
      $emit-difference
    )
    {
      @include custom-property("container-01", map-get($theme, "container-01"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "container-02",
      $emit-difference
    )
    {
      @include custom-property("container-02", map-get($theme, "container-02"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "container-03",
      $emit-difference
    )
    {
      @include custom-property("container-03", map-get($theme, "container-03"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "container-04",
      $emit-difference
    )
    {
      @include custom-property("container-04", map-get($theme, "container-04"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "container-05",
      $emit-difference
    )
    {
      @include custom-property("container-05", map-get($theme, "container-05"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "size-xsmall",
      $emit-difference
    )
    {
      @include custom-property("size-xsmall", map-get($theme, "size-xsmall"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "size-small",
      $emit-difference
    )
    {
      @include custom-property("size-small", map-get($theme, "size-small"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "size-medium",
      $emit-difference
    )
    {
      @include custom-property("size-medium", map-get($theme, "size-medium"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "size-large",
      $emit-difference
    )
    {
      @include custom-property("size-large", map-get($theme, "size-large"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "size-xlarge",
      $emit-difference
    )
    {
      @include custom-property("size-xlarge", map-get($theme, "size-xlarge"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "size-2XLarge",
      $emit-difference
    )
    {
      @include custom-property("size-2XLarge", map-get($theme, "size-2XLarge"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "icon-size-01",
      $emit-difference
    )
    {
      @include custom-property("icon-size-01", map-get($theme, "icon-size-01"));
    }

    @if should-emit(
      $theme,
      $parent-carbon-theme,
      "icon-size-02",
      $emit-difference
    )
    {
      @include custom-property("icon-size-02", map-get($theme, "icon-size-02"));
    }
  }

  @content;

  // Reset to default theme after apply in content
  @if $carbon--theme != $parent-carbon-theme {
    $carbon--theme: $parent-carbon-theme !global;

    @include carbon--theme();
  }
}
