@import "../../scss/global";

.preview {
  width: 400px;
  height: auto;
}

.pictureButton {
  margin-bottom: $spacing-md;
}

.patientName {
  font-weight: 600;
}

.subTitle {
  margin-top: $spacing-xs;
  color: var(--text-02);
  @include typescale("caption");
}

.buttonClassName {
  margin-left: $spacing-xs;
}

.battery {
  width: 2rem;
}

.content {
  flex: 1;
}

.currentImage {
  :global(.wfp--user__icon) {
    width: 4rem !important;
    height: 4rem !important;
    margin-bottom: 1em;
  }
}

.deviceIcon {
  > img {
    max-width: 7em;
  }
}
