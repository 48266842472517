@use "scss/meta" as *;

.select {
  font-size: 1.4rem;
  color: var(--text-01);
  flex: 1;
  @include breakpoint-down(md) {
    margin: -0.3em;
  }
  //color: #fff;
}
.title {
  color: $text-primary;
  font-weight: 600;
  font-size: 1rem;
  //@include typescale("epsilon");
}

.valueWrapper {
  display: flex;
  align-items: center;
  @include breakpoint-up(md) {
    color: #fff;
  }
}

.valueContent {
  flex: 1;
  .subTitle {
    @include breakpoint-up(md) {
      color: #fff;
    }
    font-size: 0.8rem;
  }
}

.avatar {
  /*width: 2.7rem;
  height: 2.7rem;
  border-radius: 2rem;
  margin-right: 0.7rem;
  background: var(--ui-04);
  overflow: hidden;*/
  //transition: all 0.1s;
  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  :global {
    .wfp--user__icon {
      width: 2.7rem;
      height: 2.7rem;
    }
  }
  /*&:hover {
    transform: translateX(1em) scale(2);
  }*/
}

.subTitle {
  //color: var(--text-02);
  color: $text-secondary;
  font-size: 0.6em;
  margin-top: 0.2em;
}

:global(.react-select__option--is-selected) {
  .title {
    color: $text-inverse;
  }
  .subTitle {
    color: $text-inverse;
  }
}

:global(.react-select__value-container) {
  @include breakpoint-up(md) {
    .title {
      color: $text-inverse;
    }
  }
}

.select {
  :global {
    .react-select__control {
      background: rgba(#fff, 0.05);

      border: 0;
      @include breakpoint-up(md) {
        border-top: 1px solid rgba(#fff, 0.1);
        border-bottom: 1px solid rgba(#fff, 0.1);
        &:hover {
          border-color: rgba(#fff, 0.1);
        }
      }
      border-radius: 0;
      cursor: pointer;
      //border-color: transparent !important;
    }
    .react-select__value-container {
      padding: $spacing-03;
      @include breakpoint-up(md) {
        padding: $spacing-04;
      }
    }
    .react-select__control--is-focused {
      //border-color: transparent !important;
      box-shadow: 0 0 0 1px transparent !important;
    }
    .react-select__menu {
      color: var(--text-01);
    }
    .react-select__indicators {
      width: 2.7rem;
      //display: none !important;
    }
    .react-select__indicators {
      margin-right: 0.3em;
    }
    .react-select__dropdown-indicator {
      color: #000 !important;
      @include breakpoint-up(md) {
        color: #fff !important;
      }
      > svg {
        width: 25px;
        height: 25px;
      }
    }
    .react-select__indicator-separator {
      display: none;
    }
  }
}

:global {
  .react-select__menu {
    left: 0.7em !important;
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      top: -7px;
      left: 15px;
      width: 16px;
      height: 16px;
      background: #fff;
      transform: rotate(45deg);
    }
  }
  .react-select__menu-list {
    position: relative;
    z-index: 1;
  }
  .react-select__option {
    cursor: pointer !important;
  }
  .react-select__input {
    input {
      color: #fff !important;
    }
  }
  /*.react-select__value-container,
  .react-select__single-value {
    overflow: visible !important;
  }*/
}

.oneOption {
  :global {
    .react-select__indicator {
      display: none;
    }
  }
}
