@use "scss/meta" as *;
@import "../../scss/global";

.mainWrapper {
  display: flex;
  //height: 100vh;
  //height: -webkit-fill-available;
  flex: 1;
  min-height: 100%;
  max-height: 100%;
  @include breakpointMax(bp--lg--major) {
    flex-direction: column;
    overflow: auto;
  }

  @media print {
    flex-direction: column;
  }
}

.editor {
  position: absolute;
  z-index: 3;
  width: 25vw;
  margin-top: $spacing-md;
  margin-left: $spacing-md;
  transition: all 0.15s;
}

.wrapperEdit {
  .editor {
    transform: translateX(500px);
  }
  .wrapperContent {
    //transform: translateX(10vw);
  }
}

.sidebar {
  width: 100%;
  //color: var(--ui-01);
  background: var(--ui-02);
  @include breakpointMax(bp--md--major) {
    position: sticky;
    top: 0;
    border-bottom: 1px solid $border-strong; //var(--ui-02);
  }
  @include breakpoint(bp--md--major) {
    color: var(--ui-01);
    background: $background-inverse;
    //max-height: 100vh;
  }
  padding-top: $safe-area-inset-top;
  background: var(--ui-01);
  //box-shadow: 3px 3px 7px rgba(#000, 0.05);
  position: relative;
  z-index: 10;
  display: flex;
  @include breakpoint(bp--lg--major) {
    //height: 100vh;
    //height: -webkit-fill-available;
    width: 350px;
    min-width: 350px;
  }
  flex-direction: column;
  @media print {
    display: none;
  }
}

.wrapperContent {
  flex: 1;
  transition: all 0.2s;
  width: 100%;
  display: flex;
  min-width: 0;
  @media print {
    width: 100%;
  }
  @include breakpoint(bp--md--major) {
    max-height: 100%;
    overflow: auto;
  }
}
