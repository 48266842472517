@use "scss/meta" as *;

.boundaries {
  display: flex;
  align-items: flex-end;
}

.saveIcon {
  color: $interactive;
}

.account {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accountIllustration {
  position: absolute;
  left: -20vw;
  width: 130vw;
  bottom: 0;
  @include breakpoint("md") {
    //position: relative;
    left: -3vw;
    bottom: -1em;
    width: 70vw;
    //margin: 3em 0;
  }
}

.developer {
  padding: 1em;
  border: 2px solid $layer;
  h2 {
    font-weight: 600;
    margin-bottom: 1em;
  }
}
