@use "scss/meta" as *;

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include breakpoint-down(lg) {
    //flex-direction: column;
    margin: 0 $spacing-04 $spacing-04;
  }
  @include breakpoint-up(lg) {
    margin: $spacing-05;
  }
  :global {
    .wfp--btn {
      font-size: 0.8em;
      margin: -0.05em;
      @include breakpoint-down(lg) {
        padding: 0.5em 1.2em;
      }
      &:focus {
        background-color: var(--ui-01) !important;
      }
      &.rbc-active {
        position: relative;
        //z-index: 10;
        color: var(--ui-01);
        color: var(--interactive-01);
      }
    }
  }
}

.toolbarButton {
  :global {
    .wfp--btn__icon {
      width: 0.5em;
    }
  }
}
