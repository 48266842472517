$css--variables: false !default;

@import "~@un/react/src/globals/scss/colors";
@import "~@un/react/src/globals/scss/vars";
@import "~@un/react/src/globals/scss/layout";
@import "~@un/react/src/globals/scss/_typography";

$hover-secondary: var(--interactive-01);
$hover-primary: #005ecb;
$color__green: rgb(67, 194, 41);
$input_border: 2px solid var(--field-border-01);
$button_border_radius: 8px;
$support-02: $color__green;

$desktop-header-height: 55px;
$mobile-detail-header-height: 60px;
$mobile-header-height: 70px;
$mobile-bottom-height: 60px;
$mobile-bottom-swipe-height: 75px;

$safe-area-inset-top: var(--safe-area-inset-top);
