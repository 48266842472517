@use "scss/meta" as *;

.medium {
  width: 2.3rem;
  height: 2.3rem;
  :global {
    .wfp--user__icon {
      min-width: 2.3rem;
      min-height: 2.3rem;
    }
  }
}

.large {
  :global {
    .wfp--user__icon {
      width: 7.5rem;
      height: 7.5rem;
    }
  }
}
