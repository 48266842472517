@import "../../scss/global";

.medicationIcon {
  width: 100%;
  height: 100%;
}

.medicationIcon {
  overflow: visible;
  width: 50px !important;
  height: auto;

  &.medicationIconNoColor {
    path,
    circle,
    rect,
    polygon {
      color: var(--interactive-01);
    }
  }
}
