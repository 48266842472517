/*@use "@un/styles/scss/breakpoint" as *;
@use "@un/styles/scss/config" as *;
@use "@un/styles/scss/motion" as *;
@use "@un/styles/scss/themes" as *;
@use "@un/styles/scss/theme" as *;
@use "@un/styles/scss/spacing" as *;
@use "@un/styles/scss/type" as *;
@use "@un/styles/scss/utilities/component-reset";
@use "@un/styles/scss/utilities/convert";*/

@forward "@un/styles/scss/breakpoint";
@forward "@un/styles/scss/motion";
@forward "@un/styles/scss/spacing";
@forward "@un/styles/scss/type";
@forward "@un/styles/scss/breakpoint";
//@forward "@un/styles/scss/utilities/component-reset";
@forward "@un/styles/scss/utilities/convert";

//@import "@un/themes/scss/themes";

@import "../themes";
@import "../themes/scss";

//$carbon--theme: $carbon--theme--wfp;

// Use the gray 10 theme
//@include carbon--theme();

$css--variables: false !default;

$hover-secondary: var(--interactive-01);
$hover-primary: #005ecb;
$color__green: rgb(67, 194, 41);
$input_border: 2px solid var(--field-border-01);
$button_border_radius: 8px;
$support-02: $color__green;

$desktop-header-height: 55px;
$mobile-detail-header-height: 60px;
$mobile-header-height: 70px;
$mobile-bottom-height: 60px;
$mobile-bottom-swipe-height: 75px;

$safe-area-inset-top: var(--safe-area-inset-top);
