@import "../../scss/global";

.calendarWrapper {
  background: var(--ui-02);
  min-height: 100%;
  max-width: 100%;
  width: 100%;
  flex: 1;
  user-select: none;
}

@media print {
  .calendarWrapper {
    background: #fff;
  }
}

.noDevice {
  margin: 0;
  //padding: 0;
}

.loading {
  position: absolute;
}
