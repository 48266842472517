@import "../../scss/global";

.pwaShadow {
  @media all and (display-mode: standalone) {
    width: 100vw;
    height: 70px;
    overflow: visible;
    position: fixed;
    top: 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );
    z-index: 100000;
    pointer-events: none;
  }
}
