@use "scss/meta" as *;
@import "scss/global";

.dateInput {
  position: relative;
  //max-width: 200px;
  input[type="time"] {
    display: flex;
    align-items: center;
  }
  input[type="time"]::-webkit-inner-spin-button,
  input[type="time"]::-webkit-calendar-picker-indicator {
    //display: none;
    //-webkit-appearance: none;
    opacity: 0;
    cursor: pointer;
  }

  :global {
    .wfp--input-addon-after {
      position: absolute;
      bottom: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      color: var(--interactive-01);
      pointer-events: none;
      background: none;
      border: none;
    }
  }
}

.dateWrapper {
  display: inline-block;
  min-width: 3.3em;
}

.input {
  display: inline-block;
  position: relative;
  > input {
    min-width: 6.5em;
    max-width: 6.5em;
    margin-right: 0.5em;
  }
}

.icon {
  position: absolute;
  top: 50%;
  right: 1em;
  margin-top: -0.5em;
}

.dateInputWrapper {
  display: flex;
  align-items: center;
}
