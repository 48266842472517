$css--variables: true;
$css--reset: true;
$css--body: true;
@use "./meta" as meta;
@use "@un/styles/scss/themes";
@use "@un/styles/scss/theme" with (
  $theme: meta.$carbon--theme--memo
);

@use "@un/styles/scss/reset";
@use "@un/styles/scss/components";

@import "./global.scss";

@import "~@un/react/src/globals/scss/colors";
@import "~@un/react/src/globals/scss/vars";
@import "~@un/react/src/globals/scss/layout";
@import "~@un/react/src/globals/scss/_typography";

@import "~tippy.js/dist/tippy.css";
@import "~tippy.js/themes/light.css";

@import "@un/type/scss/font-face/mono";
@import "@un/type/scss/font-face/sans";
@import "@un/type/scss/font-face/serif";

@include open-sans-font-face();

:root {
  @include meta.carbon--theme(meta.$carbon--theme--memo, true) {
  }
}

/* @media (prefers-color-scheme: dark) {
  :root {
    @include meta.carbon--theme(meta.$carbon--theme--memodark, true) {
    }
  }
} */

/*body {
  //--wfp-interactive: #0076ff;
  //--wfp-button-primary: #0076ff;
  --interactive-02: #005ecb;
  //--field-01: #005ecb;
  --support-01: #d60040;
  --support-02: #00d67c;
  --support-light-02: #7effb2;
  --hover-primary: #005ecb;
  --brand-01: var(--interactive-01);
  --sidebar-01: #333945;
  --sidebar-02: #232831;
  --text-inverse-02: #{lighten(#333945, 25)};
}*/

.no-textselect {
  -webkit-user-select: none !important;
}

body {
  color: theme.$text-primary;
}
:root {
  /*body {
    div {
      --safe-area-inset-top: 10px;
    }
  }*/
  //color: $textPrimary;

  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;

  @supports (top: constant(safe-area-inset-top)) {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }

  @supports (top: env(safe-area-inset-top)) {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

.wfp--text-input {
  min-width: 2rem;
}

html,
body {
  height: 100%;
  background-color: theme.$background;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body.fullHeight {
  height: 100%;
  @include breakpointMax(bp--lg--major) {
    height: -webkit-fill-available;
  }
  overflow: hidden;
}

#root,
.App {
  height: 100%;
}
.fullHeight {
  #root,
  .App {
    height: 100%;
  }
}
.wfp--select-input {
  padding-right: 2em;
  > div {
    width: 120%;
  }
}

b {
  font-weight: 600;
}

.tippy-box[data-placement^="bottom"] {
  > .tippy-arrow {
    &:before {
      top: -12px;
      left: 0;
      border-width: 0 14px 14px;
    }
  }
}

.tippy-content {
  max-height: 90vh;
  overflow: auto;
  padding: 0.8rem;
  overflow: visible;
}

@include meta.breakpoint-down(md) {
  .wfp--modal {
    align-items: end;
  }
}

.wfp--modal-container {
  overflow: visible !important;
  &:before {
    display: none;
  }
}

@include breakpoint(bp--md--major) {
  .wfp--modal-container {
    border-top: 0 !important;
    border-radius: 12px !important;
  }

  .wfp--modal-footer {
    border-radius: 0 0 12px 12px !important;
  }
}

.wfp--btn--secondary {
  background: theme.$layer-accent;
  border: none;
  color: theme.$text-primary;
}

/*.wfp--btn--tertiary {
  background: var(--ui-01);
  border: 1px solid var(--ui-04);

  &:active {
    position: relative;
    z-index: 10000;
  }
}*/

.wfp--btn--lg {
  padding: 0 1.2em;
}

.wfp--btn--danger {
  background: meta.$layer; //meta.$support-error-light;
  border: none !important;

  //background: theme.$support-success;
  //border: 1px solid theme.$support-error-light;

  &:hover {
    color: meta.$support-error;
    background: meta.$support-error-light;
  }
}

.wfp--module--light .wfp--module__inner {
  border: none;
  background: var(--ui-02);
}

.wfp--module__inner {
  border-radius: 7px !important;
}

.#{$prefix}--sidebar-content-mobile-header {
  margin-top: env(safe-area-inset-bottom);
}

.wfp--sidebar-content__header {
  padding-top: env(safe-area-inset-bottom) !important;
}

.wfp--sidebar-content-mobile-header {
  z-index: 1000;
}

.wfp--item__title {
  font-weight: 600;
}

.copy-to-clipboard-container {
  position: absolute;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
div:focus {
  outline: none;
}

@include breakpointMax(bp--lg--major) {
  .wfp--blockquote {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 1em 0;
  }
  .wfp--blockquote__icon {
    margin-left: 1em;
  }
  .wfp--blockquote__action-buttons {
    margin-left: 0.5em;
  }
}

.wfp--sidebar-content-mobile-header {
  margin-top: $safe-area-inset-top;
  padding: 0 1em;
  min-height: 3rem;
}

.wfp--sidebar-content__header {
  padding-top: $safe-area-inset-top !important;
}

.wfp--wrapper--width-mobile-full {
  padding: 0;
}

.wfp--sidebar-content__children {
  height: 100%;
}

.wfp--item__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wfp--item .wfp--item__text-wrapper__inner {
  min-width: 0;
}
.wfp--item__text-wrapper {
  min-width: 0;
}

.wfp--item .wfp--item__content {
  min-width: 0;
}
