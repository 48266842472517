@use "scss/meta" as *;
@import "scss/global";

.fillStartWrapper {
  max-width: 27em;
  margin-bottom: -1em;
}

.fillStart {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.returnWrapper {
  margin: 1em;
  position: relative;
  z-index: 100;
}

.date {
  font-size: 1.3rem;
  margin-bottom: 2em;
  @include breakpoint(bp--lg--major) {
    font-size: 2rem;
    font-weight: 100;
  }
}

.dateIcon {
  height: 0.7em;
  width: auto;
  vertical-align: baseline;
}

.summary {
  @include breakpoint(bp--lg--major) {
    margin: 2em 0;
  }
}

.summaryEntry {
  margin: 1em 0;
}

.hint {
  margin: 1em 0;
  text-align: center;
  line-height: 1.5em;
}
