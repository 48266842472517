@import "scss/global";

.frenchTitle {
  font-size: 1rem;
  color: var(--text-02);
  margin-bottom: 2rem;
  font-weight: 400;
}

.errorWrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
