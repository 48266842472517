@use "scss/meta" as *;
@import "scss/global";

.settingsPageWrapper {
  background: var(--ui-02);
  height: 100vh;
  @include breakpointMax(bp--lg--major) {
    height: -webkit-fill-available;
  }
}
.settingsPage {
  //max-width: 900px;
  margin: auto;
  display: flex;
  align-items: stretch;
  height: 100%;
  flex-direction: column;
}
.header {
  margin: 0 0;
}
.wrapper {
  display: flex;
  height: 100vh;
  @include breakpointMax(bp--lg--major) {
    flex-direction: column-reverse;
    height: -webkit-fill-available;
  }
}

.main {
  flex: 1;
}

.navigation {
  background: $background-inverse-hover;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(#fff, 0.15);
  user-select: none;
  -webkit-touch-callout: none;

  @media print {
    display: none;
  }
  /*margin-top: $spacing-xl * 1;
  margin-right: $spacing-md;
  margin-left: $spacing-md;*/
  //padding: 1em;
  min-width: 5em;
  @include breakpointMax(bp--lg--major) {
    background: var(--ui-01);
    //position: fixed;
    bottom: 0;
    z-index: 100;
    flex-direction: row;
    width: 100%;
    border-top: 1px solid var(--ui-02);
    //bottom: env(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom, 0px);
  }
  @media all and (display-mode: standalone) {
    padding-bottom: env(safe-area-inset-bottom, 0px);
  }
  h3 {
    margin-top: $spacing-lg;
  }
}

.navigationHeader {
  padding: 1em;
  margin-bottom: 1em;
}

.main,
.footer {
  display: flex;
  flex-direction: column;
  @include breakpointMax(bp--lg--major) {
    min-width: 100vw;
    justify-content: space-between;
    align-content: stretch;
  }

  @include breakpointMax(bp--lg--major) {
    flex-direction: row;
  }
  a {
    text-decoration: none;
    color: var(--inverse-01);
    display: flex;
    &.mobileOnly {
      display: none;
    }
    @include breakpointMax(bp--lg--major) {
      color: var(--text-01);
      flex: 1;
      padding: 0.6em 0;
      &.desktopOnly {
        display: none;
      }
      &.mobileOnly {
        display: flex;
      }
    }
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0.9em 0;
    white-space: nowrap;

    > span {
      font-size: 0.65em;
      opacity: 0.5;
    }
    .icon {
      font-size: 1.6em;
      margin-bottom: 0.2em;
      opacity: 0.8;
      .iconActive {
        opacity: 0;
        transition: all 0.2s;
      }
      .iconRegular {
        opacity: 1;
        transition: all 0.2s;
      }
    }

    &.active {
      background: rgba(#fff, 0.05);
      font-weight: 600;
      text-decoration: none;
      @include breakpointMax(bp--lg--major) {
        color: $interactive;
      }
      &:after {
        content: "";
        background: #fff;
        width: 2px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        @include breakpointMax(bp--lg--major) {
          width: 100%;
          height: 2px;
          background: $interactive;
          display: none;
        }
      }
      //color: var(--interactive-01);#
      span {
        opacity: 1;
      }
      .icon {
        opacity: 1;
      }
      /*.iconRegular {
        path {
          stroke-width: 100;
          stroke: rgba(#fff, 0.15);
        }
      }*/
      @include breakpointMax(bp--lg--major) {
        .icon {
          .iconActive {
            opacity: 1;
            color: $interactive;
          }
          .iconRegular {
            opacity: 0;
          }
        }
      }
    }
  }
}
.icon {
  height: 1.1em;
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.footer {
  @include breakpointMax(bp--lg--major) {
    display: none;
  }
}
.content {
  background: $background;
  //padding: 2rem;
  //margin-top: $spacing-xl * 1;
  //margin-bottom: 3rem;
  min-width: 0;
  @include breakpointMax(bp--lg--major) {
    height: 100%;
    width: 100%;
    overflow: auto; //hidden;
    display: flex;
    align-items: stretch;
  }
  flex: 1;
  @include breakpoint(bp--lg--major) {
    border-radius: 5px;

    box-shadow: 0px 5px 10px rgba(#000, 0.03);
  }
  .form > [class="wfp--form-item"],
  .form > * {
    margin-bottom: 1.3rem;
    @include breakpoint(bp--lg--major) {
      margin-bottom: 2rem;
    }
  }
}

.logoutButton {
  cursor: pointer;
  color: $interactive;
  margin-top: $spacing-xl;
}

.repeater {
  height: 100%;
}

.navigationButton {
  width: 100%;
}
