@use "scss/meta" as *;

.intakeTimeWrapper {
  align-items: flex-start;
  width: fit-content;
  @include breakpoint-down("md") {
    width: 100%;
  }
}

.intakeTime {
  padding: $spacing-02;
  border-radius: 7px;
  background: var(--ui-02);
  margin-bottom: $spacing-02;
  :global {
    .wfp--form-item {
      flex: initial;
    }
  }
}
.intakeTimeContent {
  display: flex;
  align-items: center;
}

.intakeTimeTitle {
  min-width: 8em;

  @include breakpoint-down("md") {
    flex: 1;
    min-width: 4em;
  }

  font-weight: 600;
}

.intakeTimeIcon {
  min-width: 2em;
  margin-left: $spacing-02;
}

.warning {
  margin-bottom: 0;
}
