@import "scss/global";

// TODO: move to UI-Kit
.story {
  margin: 0;
  > :first-child {
    margin-top: 0;
  }
}

.iframe {
  margin: -1em;
  height: 100%;
  flex: 1;
}
