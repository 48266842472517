@use "scss/meta" as *;

.inputGroup {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  /*flex-direction: column;
  width: 100%;*/
  /*@include breakpoint-up(md) {
    flex-direction: row;
  }*/

  &.vertical {
    flex-direction: column;
  }
}
