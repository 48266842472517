// Code generated by @un/themes. DO NOT EDIT.
//
// Copyright IBM Corp. 2018, 2019
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@use "sass:map";
@use "../config";
@use "../theme";

/// Internal helper for generating CSS Custom Properties
@function _get($token) {
  @if config.$use-fallback-value == false {
    @return var(--#{config.$prefix}-#{$token});
  } @else {
    @return var(--#{config.$prefix}-#{$token}, #{theme.get($token)});
  }
}

/// The CSS Custom Property for the `background` token
$background: _get("background") !default;

/// The CSS Custom Property for the `background-active` token
$background-active: _get("background-active") !default;

/// The CSS Custom Property for the `background-selected` token
$background-selected: _get("background-selected") !default;

/// The CSS Custom Property for the `background-selected-hover` token
$background-selected-hover: _get("background-selected-hover") !default;

/// The CSS Custom Property for the `background-hover` token
$background-hover: _get("background-hover") !default;

/// The CSS Custom Property for the `background-brand` token
$background-brand: _get("background-brand") !default;

/// The CSS Custom Property for the `background-inverse` token
$background-inverse: _get("background-inverse") !default;

/// The CSS Custom Property for the `background-inverse-hover` token
$background-inverse-hover: _get("background-inverse-hover") !default;

/// The CSS Custom Property for the `layer-01` token
$layer-01: _get("layer-01") !default;

/// The CSS Custom Property for the `layer-active-01` token
$layer-active-01: _get("layer-active-01") !default;

/// The CSS Custom Property for the `layer-hover-01` token
$layer-hover-01: _get("layer-hover-01") !default;

/// The CSS Custom Property for the `layer-selected-01` token
$layer-selected-01: _get("layer-selected-01") !default;

/// The CSS Custom Property for the `layer-selected-hover-01` token
$layer-selected-hover-01: _get("layer-selected-hover-01") !default;

/// The CSS Custom Property for the `layer-02` token
$layer-02: _get("layer-02") !default;

/// The CSS Custom Property for the `layer-active-02` token
$layer-active-02: _get("layer-active-02") !default;

/// The CSS Custom Property for the `layer-hover-02` token
$layer-hover-02: _get("layer-hover-02") !default;

/// The CSS Custom Property for the `layer-selected-02` token
$layer-selected-02: _get("layer-selected-02") !default;

/// The CSS Custom Property for the `layer-selected-hover-02` token
$layer-selected-hover-02: _get("layer-selected-hover-02") !default;

/// The CSS Custom Property for the `layer-03` token
$layer-03: _get("layer-03") !default;

/// The CSS Custom Property for the `layer-active-03` token
$layer-active-03: _get("layer-active-03") !default;

/// The CSS Custom Property for the `layer-hover-03` token
$layer-hover-03: _get("layer-hover-03") !default;

/// The CSS Custom Property for the `layer-selected-03` token
$layer-selected-03: _get("layer-selected-03") !default;

/// The CSS Custom Property for the `layer-selected-hover-03` token
$layer-selected-hover-03: _get("layer-selected-hover-03") !default;

/// The CSS Custom Property for the `layer-disabled` token
$layer-disabled: _get("layer-disabled") !default;

/// The CSS Custom Property for the `layer-selected-inverse` token
$layer-selected-inverse: _get("layer-selected-inverse") !default;

/// The CSS Custom Property for the `layer-selected-disabled` token
$layer-selected-disabled: _get("layer-selected-disabled") !default;

/// The CSS Custom Property for the `layer-accent-01` token
$layer-accent-01: _get("layer-accent-01") !default;

/// The CSS Custom Property for the `layer-accent-active-01` token
$layer-accent-active-01: _get("layer-accent-active-01") !default;

/// The CSS Custom Property for the `layer-accent-hover-01` token
$layer-accent-hover-01: _get("layer-accent-hover-01") !default;

/// The CSS Custom Property for the `layer-accent-02` token
$layer-accent-02: _get("layer-accent-02") !default;

/// The CSS Custom Property for the `layer-accent-active-02` token
$layer-accent-active-02: _get("layer-accent-active-02") !default;

/// The CSS Custom Property for the `layer-accent-hover-02` token
$layer-accent-hover-02: _get("layer-accent-hover-02") !default;

/// The CSS Custom Property for the `layer-accent-03` token
$layer-accent-03: _get("layer-accent-03") !default;

/// The CSS Custom Property for the `layer-accent-active-03` token
$layer-accent-active-03: _get("layer-accent-active-03") !default;

/// The CSS Custom Property for the `layer-accent-hover-03` token
$layer-accent-hover-03: _get("layer-accent-hover-03") !default;

/// The CSS Custom Property for the `field-01` token
$field-01: _get("field-01") !default;

/// The CSS Custom Property for the `field-hover-01` token
$field-hover-01: _get("field-hover-01") !default;

/// The CSS Custom Property for the `field-02` token
$field-02: _get("field-02") !default;

/// The CSS Custom Property for the `field-hover-02` token
$field-hover-02: _get("field-hover-02") !default;

/// The CSS Custom Property for the `field-03` token
$field-03: _get("field-03") !default;

/// The CSS Custom Property for the `field-hover-03` token
$field-hover-03: _get("field-hover-03") !default;

/// The CSS Custom Property for the `field-disabled` token
$field-disabled: _get("field-disabled") !default;

/// The CSS Custom Property for the `interactive` token
$interactive: _get("interactive") !default;

/// The CSS Custom Property for the `border-subtle-00` token
$border-subtle-00: _get("border-subtle-00") !default;

/// The CSS Custom Property for the `border-subtle-01` token
$border-subtle-01: _get("border-subtle-01") !default;

/// The CSS Custom Property for the `border-subtle-selected-01` token
$border-subtle-selected-01: _get("border-subtle-selected-01") !default;

/// The CSS Custom Property for the `border-subtle-02` token
$border-subtle-02: _get("border-subtle-02") !default;

/// The CSS Custom Property for the `border-subtle-selected-02` token
$border-subtle-selected-02: _get("border-subtle-selected-02") !default;

/// The CSS Custom Property for the `border-subtle-03` token
$border-subtle-03: _get("border-subtle-03") !default;

/// The CSS Custom Property for the `border-subtle-selected-03` token
$border-subtle-selected-03: _get("border-subtle-selected-03") !default;

/// The CSS Custom Property for the `border-strong-01` token
$border-strong-01: _get("border-strong-01") !default;

/// The CSS Custom Property for the `border-strong-02` token
$border-strong-02: _get("border-strong-02") !default;

/// The CSS Custom Property for the `border-strong-03` token
$border-strong-03: _get("border-strong-03") !default;

/// The CSS Custom Property for the `border-inverse` token
$border-inverse: _get("border-inverse") !default;

/// The CSS Custom Property for the `border-interactive` token
$border-interactive: _get("border-interactive") !default;

/// The CSS Custom Property for the `border-disabled` token
$border-disabled: _get("border-disabled") !default;

/// The CSS Custom Property for the `text-primary` token
$text-primary: _get("text-primary") !default;

/// The CSS Custom Property for the `text-secondary` token
$text-secondary: _get("text-secondary") !default;

/// The CSS Custom Property for the `text-placeholder` token
$text-placeholder: _get("text-placeholder") !default;

/// The CSS Custom Property for the `text-helper` token
$text-helper: _get("text-helper") !default;

/// The CSS Custom Property for the `text-error` token
$text-error: _get("text-error") !default;

/// The CSS Custom Property for the `text-inverse` token
$text-inverse: _get("text-inverse") !default;

/// The CSS Custom Property for the `text-on-color` token
$text-on-color: _get("text-on-color") !default;

/// The CSS Custom Property for the `text-on-color-disabled` token
$text-on-color-disabled: _get("text-on-color-disabled") !default;

/// The CSS Custom Property for the `text-disabled` token
$text-disabled: _get("text-disabled") !default;

/// The CSS Custom Property for the `link-primary` token
$link-primary: _get("link-primary") !default;

/// The CSS Custom Property for the `link-primary-hover` token
$link-primary-hover: _get("link-primary-hover") !default;

/// The CSS Custom Property for the `link-secondary` token
$link-secondary: _get("link-secondary") !default;

/// The CSS Custom Property for the `link-inverse` token
$link-inverse: _get("link-inverse") !default;

/// The CSS Custom Property for the `link-visited` token
$link-visited: _get("link-visited") !default;

/// The CSS Custom Property for the `icon-primary` token
$icon-primary: _get("icon-primary") !default;

/// The CSS Custom Property for the `icon-secondary` token
$icon-secondary: _get("icon-secondary") !default;

/// The CSS Custom Property for the `icon-inverse` token
$icon-inverse: _get("icon-inverse") !default;

/// The CSS Custom Property for the `icon-on-color` token
$icon-on-color: _get("icon-on-color") !default;

/// The CSS Custom Property for the `icon-on-color-disabled` token
$icon-on-color-disabled: _get("icon-on-color-disabled") !default;

/// The CSS Custom Property for the `icon-disabled` token
$icon-disabled: _get("icon-disabled") !default;

/// The CSS Custom Property for the `support-error` token
$support-error: _get("support-error") !default;

/// The CSS Custom Property for the `support-success` token
$support-success: _get("support-success") !default;

/// The CSS Custom Property for the `support-warning` token
$support-warning: _get("support-warning") !default;

/// The CSS Custom Property for the `support-info` token
$support-info: _get("support-info") !default;

/// The CSS Custom Property for the `support-error-inverse` token
$support-error-inverse: _get("support-error-inverse") !default;

/// The CSS Custom Property for the `support-success-inverse` token
$support-success-inverse: _get("support-success-inverse") !default;

/// The CSS Custom Property for the `support-warning-inverse` token
$support-warning-inverse: _get("support-warning-inverse") !default;

/// The CSS Custom Property for the `support-info-inverse` token
$support-info-inverse: _get("support-info-inverse") !default;

/// The CSS Custom Property for the `support-caution-major` token
$support-caution-major: _get("support-caution-major") !default;

/// The CSS Custom Property for the `support-caution-minor` token
$support-caution-minor: _get("support-caution-minor") !default;

/// The CSS Custom Property for the `support-caution-undefined` token
$support-caution-undefined: _get("support-caution-undefined") !default;

/// The CSS Custom Property for the `highlight` token
$highlight: _get("highlight") !default;

/// The CSS Custom Property for the `overlay` token
$overlay: _get("overlay") !default;

/// The CSS Custom Property for the `toggle-off` token
$toggle-off: _get("toggle-off") !default;

/// The CSS Custom Property for the `focus` token
$focus: _get("focus") !default;

/// The CSS Custom Property for the `focus-inset` token
$focus-inset: _get("focus-inset") !default;

/// The CSS Custom Property for the `focus-inverse` token
$focus-inverse: _get("focus-inverse") !default;

/// The CSS Custom Property for the `skeleton-background` token
$skeleton-background: _get("skeleton-background") !default;

/// The CSS Custom Property for the `skeleton-element` token
$skeleton-element: _get("skeleton-element") !default;
