@use "scss/meta" as *;

.videoWrapper {
  position: relative;
  transform: scaleX(-1);
  transition: all 0.3s;
  width: 100%;
  height: auto;
  transform: scaleX(-1) translateY(0%);
  > video {
    width: 100% !important;
    height: auto !important;
  }
  transform: scaleX(-1) translateY(0%);
  .isEnvironment & {
    transform: scaleX(1);
  }
  @include breakpoint(md) {
    width: 640px;
    height: auto;
  }
}

.points {
  position: absolute;
  top: 0;
  left: 0;

  width: 100% !important;
  height: 100% !important;
}

.pointsPolygon {
  stroke: $interactive;
  stroke-width: 15;
  fill: transparent;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.videoel {
  position: relative;
  display: flex;
  justify-content: center;
  @include breakpoint(md) {
    //margin: 0 -3em -3.5em -3em;
  }
  transition: all 0.3s;
  background: #ccc;
  overflow: hidden;
}

.toolbar {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  display: flex;
  z-index: 1000;
  > * {
    margin-left: 0.5em;
  }
}
button.restart {
  //display: none;
}

.scanner {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  &.isScanned {
    .videoel {
      //transform: translateY(-20%);
      //transform: scale(0.5);
    }
    .restart {
      display: block;
    }
    .select {
      display: none;
    }
    .videoel {
      /*max-height: 330px;
      overflow: hidden;*/
      margin-bottom: 1em;
    }
  }
}

.videoScale {
  width: 100%;
  background: #000;
}

@include breakpoint-down(md) {
  .module {
    margin-top: env(safe-area-inset-bottom);
    :global(.wfp--modal-inner) {
      min-height: 100%;
    }
    :global(.wfp--modal-container) {
      padding-top: $safe-area-inset-top;
      box-shadow: none;
      height: auto;
      margin: 0;
      padding: 0;
      //max-height: 50vh;
      &:before {
        display: none;
      }
      //height: 100%;
    }
    :global(.wfp--modal-container:before) {
      display: none;
    }
    :global(.wfp--modal-header__heading) {
      //display: none;
    }
    :global(.wfp--modal-header) {
      margin: 1em;
    }
    :global(.wfp--modal-content) {
      overflow: auto;
      margin-bottom: 0;
      flex: 1;
    }
  }
}

.empty {
  //padding: 1;
  flex: 1;
  min-height: 0;
}
