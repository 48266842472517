@use "scss/meta" as *;
@import "../../scss/global";

.trayEntryFill {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0.2em;
  padding: 0.2em;
  border-radius: 5px;
  width: 100%;
}

.trayEntryCount {
  font-size: 2vw;
  font-weight: 600;
  margin-bottom: 0.1em;
  @include breakpointMax(bp--lg--major) {
    font-size: 3.8vw;
    margin-bottom: 0em;
  }
}

.trayEntryUnit {
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0.3em;
  @include breakpointMax(bp--lg--major) {
    font-size: 2.2vw;
    margin-bottom: 0.2em;
  }
}

.trayEntrySubTitle {
  font-size: 0.6vw;
  font-weight: 600;
  white-space: pre-wrap;
  word-break: break-word;
  @include breakpointMax(bp--lg--major) {
    display: none;
  }
}
