@import "scss/global";

.colorSelect {
  background-color: var(--ui-02);
  display: flex;
  cursor: pointer;
  border-radius: 7px;
  &:focus {
    outline: 3px solid red;
  }
}

.colorView {
  width: 4rem;
  height: 4rem;
  flex-shrink: 0;
  padding: 1rem;
  border-radius: 7px 0 0 7px;
  background: #fff;
  border: 2px solid var(--ui-02);
  //border-radius: 100px;
}

.colorActive {
  .colorView {
    border-color: transparent;
  }
}

.colorValue {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
  font-family: monospace;
  color: var(--text-02);
  font-weight: 600;
}

.swatches {
  margin-top: 1em;
  button {
    width: 3em;
    height: 3em;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    border: 0;
    border-radius: 7px;
  }
}

.colorPicker {
  height: 180px;
  width: 380px;
}
