@use "scss/meta" as *;
@import "../../scss/global";

.backButton {
  margin: -1em;
  margin-right: 0;
  padding: 1em;
  font-size: 1rem;
  color: $interactive;
  &:hover,
  &:active {
    color: $interactive;
  }
}
