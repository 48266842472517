@import "../../../scss/global";

.meta {
  padding: $spacing-md;
  border-bottom: 1px solid rgba(#fff, 0.1);
  @include breakpointMax(bp--md--major) {
    display: none;
  }
}

.currentStatus {
  padding: $spacing-md;
  border-bottom: 1px solid rgba(#fff, 0.1);
}

.metaOverview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.metaContent {
  flex: 1;
}

.title {
  @include typescale("epsilon");
}

.avatar {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  border-radius: 3rem;
  background: var(--ui-01);
}

.date {
  color: var(--text-03);
  @include typescale("caption");
}

.address {
  color: var(--text-03);
  @include typescale("caption");
}

.comment {
  //color: var(--text-03);
  margin-bottom: 1em;
  &:last-child {
    margin-bottom: 0;
  }
  @include typescale("caption");
}

.fillButton {
  height: 4em;
  width: 100%;
}

.metaValueWrapper {
  display: flex;
}

.metaValue {
  background: rgba(#fff, 0.1);
  flex: 1;
  margin-right: 1rem;
  padding: 0.9rem;
  border-radius: 9px;
  &:last-child {
    margin-right: 0;
  }
  :global {
    .wfp--value {
      display: flex;
      flex-direction: column;
      position: relative;
    }
    .wfp--value__title {
      font-size: 0.8rem;
      opacity: 0.7;
      font-weight: 400;
      order: 3;
      margin-top: $spacing-xs;
      margin-right: -1rem;
    }
    .wfp--value__primary-value {
      font-size: 2rem;
      order: 1;
    }
    .wfp--value__secondary-value {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 0.8rem;
      color: #b5f36e;
    }
    .wfp--unit:after {
      margin-left: 0;
    }
  }
}

.item {
  color: var(--text-03) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.eventList {
  @include breakpointMax(bp--md--major) {
    display: none;
  }
}
