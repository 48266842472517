@use "scss/meta" as *;
@import "scss/global";

.login {
  display: flex;
  height: 100vh;
}

.password {
  margin-left: $spacing-xs;
}

.loginForm {
  display: flex;

  flex-direction: column;
  @include breakpointMax(bp--md--major) {
    width: 100%;
    padding: 5vw;
  }

  @include breakpoint(bp--md--major) {
    width: 38vw;
    padding: 7vw;

    .form {
      width: 100%;
      flex-grow: 1;
      margin-top: 12vh;
    }
  }

  [class="wfp--form-item"] {
    margin-bottom: $spacing-lg;
  }
}

.button {
  display: flex;
}

.submitWrapper {
  display: flex;
  align-items: center;
}
.backLink {
  margin-bottom: 2rem;
}

.loginLogo {
  @include breakpointMax(bp--md--major) {
    display: none;
  }
  /*margin-bottom: 2rem;
  margin-top: 20%;
  margin-left: 20%;*/
  //margin-bottom: -30%;
  svg {
    width: 8vmin;
    height: auto;
  }
  path {
    fill: $brand-01;
  }
}

.loginContent {
  @include breakpointMax(bp--md--major) {
    //display: none;
  }
  /*color: var(--ui-01);
  background: $interactive;
  width: 62vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;*/
  overflow: hidden;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    height: auto !important;
    width: 230% !important;
    transform: translateX(-50%) translateY(-45%) rotate(5deg);
    object-fit: cover;
    @include breakpoint(bp--md--major) {
      width: 130% !important;
    }
  }

  h2 {
    width: 60%;
    font-size: 3rem;
    font-weight: 800;
  }
  p {
    font-size: 1.2rem;
    margin-top: 1.4rem;
    padding-right: 5rem;
    line-height: 2rem;
    width: 60%;
  }
}

.logo {
  width: 3em !important;
  height: auto !important;
  color: $interactive;
}

.localLoginWrapper {
  border-top: 3px solid var(--ui-04);
  margin-top: $spacing-lg;
  padding-top: $spacing-md;
}

.localLoginText {
  padding-top: 2em;
  font-size: 0.8em;
  width: 75%;
}

.buttonWrapper {
  display: flex;
  > * {
    flex-grow: 1;
    height: 3.5em;
  }
  > :first-child {
    margin-right: 0.7em;
  }
}

.description {
  margin: 0.1em 1em 1.5em 0;
  color: $text-secondary;
  line-height: 1.5;
}

.appLink {
  margin-left: 1em;
  @include breakpoint(bp--md--major) {
    display: none;
  }
}
