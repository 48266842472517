@import "../../scss/global";

@keyframes example {
  from {
    opacity: 0.3;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.form {
  //height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: var(--ui-01);
  height: 100%;
  width: calc(350px + 5em);
  padding: 0;
  @include breakpointMax(bp--lg--major) {
    width: 100%;
  }

  > form {
    //padding: 1rem;
    //padding-top: calc(env(safe-area-inset-bottom) + 1em);
    @include breakpoint(bp--lg--major) {
      //padding: 2rem;
    }
  }
  overflow: auto;
  color: var(--text-01);
  //border-radius: 10px;
  box-shadow: 0px 4px 12px -2px rgba(#000, 0.2);
  animation-name: example;
  animation-duration: 0.2s;
  [class="wfp--form-item"] {
    //margin-right: $spacing-xs;
    //margin-bottom: $spacing-md;
    &:last-child {
      margin-right: 0;
    }
  }
}

.formWrapper {
  > :global(.wfp--form-item) {
    // styles here
    margin-bottom: 1rem;
  }

  /*[class="wfp--form-item"] {
    margin-bottom: 1rem;
    background: red;
  }*/
}

.weekdayWrapper {
  width: 100%;

  :global {
    .wfp--input-group-inside {
      align-content: stretch;
      flex-wrap: nowrap;
      //margin-bottom: 1rem;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 0;
}

.headerTitle {
  font-weight: 600;
}

.closeButton {
  width: 2.5rem;
  height: 2.5rem;
  display: flex !important;
  background: none;
  font-size: 1rem;
  border: 0;
  padding: 0 !important;
  //border: 1px solid red;
  color: var(--text-01);
  padding: 0 !important;
  border-radius: 10px;
  position: relative;
  top: -0.5em;
  right: -0.4em;
  line-height: 1.5;
  justify-content: center;
  align-items: center;
  svg {
    margin: 0 !important;
    width: 1.3em !important;
    height: 1.3em !important;
    top: 0 !important;
  }

  &:hover {
    background: var(--ui-02);
  }
}

.pickButton {
  margin-right: $spacing-2xs;
}

.position {
  display: flex;
  align-items: flex-end;
  margin-bottom: $spacing-md;
}

.frequencyWrapper {
  width: 100%;
  margin-bottom: $spacing-md;
  :global {
    .wfp--input-wrapper {
      width: 100%;
    }
  }
}

.dateWrapper {
  display: flex;
  align-items: flex-end;
  :global {
    .wfp--input {
      min-width: 7.5rem;
      max-width: 8rem;
      //padding-right: 0;
    }
  }
  margin-bottom: $spacing-md;
  > * {
    margin-right: $spacing-xs;
    &:last-child {
      margin-right: 0;
    }
  }
}

.dayTime {
  min-width: 8rem;
  flex-grow: 3;
}

.streetWrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: $spacing-md;
}

.commentWrapper {
  margin-bottom: $spacing-md;
  :global {
    .wfp--text-area {
      height: 60px;
    }
  }
}

.townWrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: $spacing-md;
}

.submitWrapper {
  display: flex;
  align-items: center;
  > * {
    margin-right: 1em;
  }
}

.excludeWrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: $spacing-md;
}

.exludeDate {
  padding: 0 0 0 1em;
  > * {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.emptyStomach {
  margin-top: 1em;
}
