@use "scss/meta" as *;
@import "../scss/global";

$gradientColor: $layer;
$gradientColorB: #fff;

.tray {
  font-weight: 600;
  /*&.isPast {
    background: #f5f7f8;
    background: repeating-linear-gradient(
      -45deg,
      $gradientColor,
      $gradientColor 3px,
      $gradientColorB 3px,
      $gradientColorB 10px
    );
  }
  &.isActive {
    z-index: 50;
    outline: 1px solid #fff;
  }*/

  &.trayPunctual {
    color: var(--support-02);
  }

  &.traySlightlyUnpunctual {
    //background: var(--support-light-03);
    color: var(--support-03);
    &:after {
      background: var(--support-02);
    }
  }

  &.trayUnpunctual {
    color: var(--support-01);
  }
}

.punctualIcon {
  color: $support-success;
}

.slightlyUnpunctualIcon {
  color: $support-warning;
}

.unpunctualIcon {
  color: $support-error;
}

.intakePunctuality {
  font-size: 0.7em;
  position: absolute;
  width: 100%;
  text-align: right;
  bottom: 0;
  right: 0;
  padding: 1em;
  font-weight: 600;
  @include breakpoint-down(md) {
    border-radius: 0 0 10px 10px;
  }
  @include breakpoint-up(md) {
    font-size: 0.6em;
    padding: 0.3em;
  }
}
